/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ResendVerificationMailSuccessDto {
  /** @format email */
  email: string;
}

export interface ResendVerificationMailErrorUserHasNoEmailDto {
  errorCode: 'user_has_no_email';
}

export type VerifyEmailSuccessDto = object;

export interface VerifyEmailErrorCodeDoesNotMatchDto {
  errorCode: 'code_does_not_match';
}

export interface VerifyEmailErrorCodeExpiredDto {
  errorCode: 'code_expired';
}

export interface VerifyEmailErrorEmailChangedDto {
  errorCode: 'email_changed';
  /** @format email */
  emailOfSentCode: string;
}

export interface VerifyEmailDto {
  code: string;
}

export interface VerifyEmailErrorTooManyFailedAttemptsDto {
  errorCode: 'too_many_failed_attempts';
  /** @format date-time */
  waitUntil: string;
}

export interface ResendVerificationMailDto {
  forDisable: boolean;
}

export type TwoStepVerifySuccessDto = object;

export interface TwoStepVerifyErrorCodeDoesNotMatchDto {
  errorCode: 'code_does_not_match';
}

export interface TwoStepVerifyErrorCodeExpiredDto {
  errorCode: 'code_expired';
}

export interface TwoStepVerifyDto {
  code: string;
}

export interface TwoStepVerifyErrorTooManyFailedAttemptsDto {
  errorCode: 'too_many_failed_attempts';
  /** @format date-time */
  waitUntil: string;
}

export interface GetAvaliableProvidersSuccessDto {
  providerIds: ('auth0' | 'google-oauth2')[];
}

export interface GetProfilePictureUploadParamsSuccessDto {
  method: 'PUT';
  url: string;
  /** @example {} */
  fields: object;
  headers: {
    'content-type'?: string;
  };
}

export interface UpdatePersonalInfoDto {
  firstName?: string;
  lastName?: string | null;
  phone?: string | null;
  companyName?: string | null;
  timeZone?: string | null;
  /**
   * @min 1
   * @max 32767
   */
  notificationFrequency?: number;
  location?: string | null;
}

export interface PreferencesItemDto {
  campaigns: string[];
  email: (
    | 'workspace-role-changed'
    | 'new-comment-in-asset'
    | 'mention-in-comment-in-asset'
    | 'reply-to-comment-in-asset'
    | 'new-asset'
    | 'new-asset-version'
    | 'joined-to-campaign'
    | 'you-added-to-campaign'
    | 'you-added-to-group'
    | 'joined-to-folder'
    | 'you-added-to-folder'
    | 'mention-in-task'
    | 'new-comment-in-owned-task'
    | 'new-comment-in-assigned-task'
    | 'task-status-changed'
    | 'task-due-date-changed'
    | 'you-assigned-to-task'
    | 'joined-to-workspace'
    | 'you-added-to-workspace'
    | 'aw-stage-due-date-changed'
    | 'aw-decision-submitted'
    | 'aw-final-decision-submitted'
    | 'aw-created'
    | 'aw-new-stage-started'
    | 'aw-reminder'
    | 'aw-invitation'
    | 'asset-shared'
    | 'organization-invitation'
    | 'email-verification'
    | 'remove-profile'
    | 'two-step-verification'
    | 'trial-ending-soon'
    | 'trial-ending-tomorrow'
    | 'trial-ended'
  )[];
  inApp: (
    | 'workspace-role-changed'
    | 'new-comment-in-asset'
    | 'mention-in-comment-in-asset'
    | 'reply-to-comment-in-asset'
    | 'new-asset'
    | 'new-asset-version'
    | 'joined-to-campaign'
    | 'you-added-to-campaign'
    | 'you-added-to-group'
    | 'joined-to-folder'
    | 'you-added-to-folder'
    | 'mention-in-task'
    | 'new-comment-in-owned-task'
    | 'new-comment-in-assigned-task'
    | 'task-status-changed'
    | 'task-due-date-changed'
    | 'you-assigned-to-task'
    | 'joined-to-workspace'
    | 'you-added-to-workspace'
    | 'aw-stage-due-date-changed'
    | 'aw-decision-submitted'
    | 'aw-final-decision-submitted'
    | 'aw-created'
    | 'aw-new-stage-started'
    | 'aw-reminder'
    | 'aw-invitation'
    | 'asset-shared'
    | 'organization-invitation'
    | 'email-verification'
    | 'remove-profile'
    | 'two-step-verification'
    | 'trial-ending-soon'
    | 'trial-ending-tomorrow'
    | 'trial-ended'
  )[];
  chat: (
    | 'workspace-role-changed'
    | 'new-comment-in-asset'
    | 'mention-in-comment-in-asset'
    | 'reply-to-comment-in-asset'
    | 'new-asset'
    | 'new-asset-version'
    | 'joined-to-campaign'
    | 'you-added-to-campaign'
    | 'you-added-to-group'
    | 'joined-to-folder'
    | 'you-added-to-folder'
    | 'mention-in-task'
    | 'new-comment-in-owned-task'
    | 'new-comment-in-assigned-task'
    | 'task-status-changed'
    | 'task-due-date-changed'
    | 'you-assigned-to-task'
    | 'joined-to-workspace'
    | 'you-added-to-workspace'
    | 'aw-stage-due-date-changed'
    | 'aw-decision-submitted'
    | 'aw-final-decision-submitted'
    | 'aw-created'
    | 'aw-new-stage-started'
    | 'aw-reminder'
    | 'aw-invitation'
    | 'asset-shared'
    | 'organization-invitation'
    | 'email-verification'
    | 'remove-profile'
    | 'two-step-verification'
    | 'trial-ending-soon'
    | 'trial-ending-tomorrow'
    | 'trial-ended'
  )[];
}

export interface GetNotificationPreferencesSuccessDto {
  disabled: PreferencesItemDto;
}

export interface DisabledPreferencesDto {
  email?: (
    | 'workspace-role-changed'
    | 'new-comment-in-asset'
    | 'mention-in-comment-in-asset'
    | 'reply-to-comment-in-asset'
    | 'new-asset'
    | 'new-asset-version'
    | 'joined-to-campaign'
    | 'you-added-to-campaign'
    | 'you-added-to-group'
    | 'joined-to-folder'
    | 'you-added-to-folder'
    | 'mention-in-task'
    | 'new-comment-in-owned-task'
    | 'new-comment-in-assigned-task'
    | 'task-status-changed'
    | 'task-due-date-changed'
    | 'you-assigned-to-task'
    | 'joined-to-workspace'
    | 'you-added-to-workspace'
    | 'aw-stage-due-date-changed'
    | 'aw-decision-submitted'
    | 'aw-final-decision-submitted'
    | 'aw-created'
    | 'aw-new-stage-started'
    | 'aw-reminder'
    | 'aw-invitation'
    | 'asset-shared'
    | 'organization-invitation'
    | 'email-verification'
    | 'remove-profile'
    | 'two-step-verification'
    | 'trial-ending-soon'
    | 'trial-ending-tomorrow'
    | 'trial-ended'
  )[];
  inApp?: (
    | 'workspace-role-changed'
    | 'new-comment-in-asset'
    | 'mention-in-comment-in-asset'
    | 'reply-to-comment-in-asset'
    | 'new-asset'
    | 'new-asset-version'
    | 'joined-to-campaign'
    | 'you-added-to-campaign'
    | 'you-added-to-group'
    | 'joined-to-folder'
    | 'you-added-to-folder'
    | 'mention-in-task'
    | 'new-comment-in-owned-task'
    | 'new-comment-in-assigned-task'
    | 'task-status-changed'
    | 'task-due-date-changed'
    | 'you-assigned-to-task'
    | 'joined-to-workspace'
    | 'you-added-to-workspace'
    | 'aw-stage-due-date-changed'
    | 'aw-decision-submitted'
    | 'aw-final-decision-submitted'
    | 'aw-created'
    | 'aw-new-stage-started'
    | 'aw-reminder'
    | 'aw-invitation'
    | 'asset-shared'
    | 'organization-invitation'
    | 'email-verification'
    | 'remove-profile'
    | 'two-step-verification'
    | 'trial-ending-soon'
    | 'trial-ending-tomorrow'
    | 'trial-ended'
  )[];
  chat?: (
    | 'workspace-role-changed'
    | 'new-comment-in-asset'
    | 'mention-in-comment-in-asset'
    | 'reply-to-comment-in-asset'
    | 'new-asset'
    | 'new-asset-version'
    | 'joined-to-campaign'
    | 'you-added-to-campaign'
    | 'you-added-to-group'
    | 'joined-to-folder'
    | 'you-added-to-folder'
    | 'mention-in-task'
    | 'new-comment-in-owned-task'
    | 'new-comment-in-assigned-task'
    | 'task-status-changed'
    | 'task-due-date-changed'
    | 'you-assigned-to-task'
    | 'joined-to-workspace'
    | 'you-added-to-workspace'
    | 'aw-stage-due-date-changed'
    | 'aw-decision-submitted'
    | 'aw-final-decision-submitted'
    | 'aw-created'
    | 'aw-new-stage-started'
    | 'aw-reminder'
    | 'aw-invitation'
    | 'asset-shared'
    | 'organization-invitation'
    | 'email-verification'
    | 'remove-profile'
    | 'two-step-verification'
    | 'trial-ending-soon'
    | 'trial-ending-tomorrow'
    | 'trial-ended'
  )[];
  campaigns?: string[];
}

export interface UpdateNotificationPreferencesDto {
  /** @format uuid */
  workspaceId: string;
  disabled?: DisabledPreferencesDto;
}

export interface ChangeProfilePasswordDto {
  oldPassword: string;
  newPassword: string;
}

export interface ErrorPasswordDoesNotMatchDto {
  errorCode: 'password_does_not_match';
}

export interface ErrorWeakPasswordDto {
  errorCode: 'weak_password';
}

export type ChangeProfilePasswordSuccessDto = object;

export interface GetKnockUserTokenSuccessDto {
  userId: string;
  token: string;
  /** @format date-time */
  expiresAt: string;
}

export interface CompleteOnboardingStepDto {
  type:
    | 'explore-demo-campaign'
    | 'create-campaign'
    | 'upload-asset'
    | 'create-task'
    | 'create-task-from-comment'
    | 'route-for-approval';
}

export type CompleteOnboardingStepSuccessDto = object;

export interface OrganizationLockedErrorDto {
  errorCode: 'organization_locked';
  /** @format uuid */
  organizationId: string;
  /** @format date-time */
  lockDate?: string | null;
  lockReasons: (
    | 'members-limit-exceeded'
    | 'asset-summary-size-limit-exceeded'
    | 'subscription-unpaid'
    | 'no-members'
  )[];
}

export interface CreateOrganizationDto {
  name: string;
  subdomain: string;
  paidSubscriptionType?: 'starter' | 'team' | null;
  paidSubscriptionPeriod?: 'monthly' | 'annually';
  trialToken?: string | null;
}

export interface PictureDto {
  /** @example https://example.com/image/wVmqNJ9LELgW2keE?type=webp&width=80&height=80&v=1634911570 */
  url?: string | null;
}

export interface MemberUserDto {
  picture: PictureDto;
  /** @format email */
  email: string;
  /** @example User Example */
  name: string;
  me?: boolean | null;
}

export interface OrganizationPermissionsDto {
  delete: boolean;
  changeDetails: boolean;
  listMembers: boolean;
  inviteAdmin: boolean;
  inviteWorkspaceMember: boolean;
  createWorkspaces: boolean;
  listWorkspaces: boolean;
  billing: boolean;
}

export interface OrganizationFeaturesDto {
  downloadComments: boolean;
  privateCampaigns: boolean;
  privateFolders: boolean;
  passwordProtectedShareLinks: boolean;
  approvalRoutingDueDates: boolean;
  approvalRouting: boolean;
  externalContributors: boolean;
  priorityComments: boolean;
  contributorsCanSetTaskDeadline: boolean;
  commentMentions: boolean;
  shareMultipleAssets: boolean;
  universalTaskView: boolean;
  sameReviewerOnMultipleAwStages: boolean;
  enableTwoFactorAuth: boolean;
  slackIntegration: boolean;
  userGroups: boolean;
}

export interface OrganizationLimitsDto {
  orgMembers?: number | null;
  minOrgMembers?: number | null;
  admins?: number | null;
  diskSpaceGB?: number | null;
  orgWorkspaces?: number | null;
  workspaceCampaigns?: number | null;
  campaignTaskBoards?: number | null;
  taskAssignees?: number | null;
  approvalWorkflowStages?: number | null;
  approvalWorkflowStageMembers?: number | null;
}

export interface OrganizationItemDto {
  /** @format uuid */
  id: string;
  owner: MemberUserDto;
  name: string;
  subdomain: string;
  /** @format date-time */
  lockDate?: string | null;
  lockReasons: (
    | 'members-limit-exceeded'
    | 'asset-summary-size-limit-exceeded'
    | 'subscription-unpaid'
    | 'no-members'
  )[];
  role: 'organization://admin' | 'organization://workspace-member';
  permissions: OrganizationPermissionsDto;
  /** @example free */
  planId:
    | string
    | 'starter_20240711'
    | 'team_20240711'
    | 'free'
    | 'starter'
    | 'team';
  planType:
    | 'starter_20240711'
    | 'team_20240711'
    | 'free'
    | 'starter'
    | 'team'
    | 'enterprise';
  planName: string;
  planStatus: 'trialing' | 'active' | 'past_due' | 'unpaid';
  planPeriod: 'monthly' | 'annually';
  planWillBeCanceled: boolean;
  features: OrganizationFeaturesDto;
  limits: OrganizationLimitsDto;
  isTrialUsed: boolean;
  /** @format date-time */
  trialEndDate?: string | null;
  /** @format date-time */
  createdAt: string;
}

export interface CreateOrganizationSuccessDto {
  organization: OrganizationItemDto;
  subscription: FreeSubscriptionDTO | PaidSubscriptionDTO;
}

export interface CreateOrganizationErrorAlreadyOwnsOrganizationDto {
  errorCode: 'already_owns_organization';
}

export interface CheckOrganizationSubdomainSuccessDto {
  available: boolean;
  suggested?: string | null;
}

export interface SuggestedOrganizationSubdomainSuccessDto {
  subdomain?: string | null;
}

export interface OrganizationListDto {
  list: OrganizationItemDto[];
}

export interface GetOrganizationSuccessDto {
  organization: OrganizationItemDto;
}

export type JoinOrganizationSuccessDto = object;

export interface JoinOrganizationErrorSecretDoesNotMatchDto {
  errorCode: 'secret_does_not_match';
}

export interface JoinOrganizationErrorSecretExpiredDto {
  errorCode: 'secret_expired';
}

export interface JoinOrganizationErrorEmailDoesNotMatchDto {
  errorCode: 'email_does_not_match';
  /** @format email */
  invitationEmail: string;
  /** @format email */
  actualEmail: string;
}

export interface JoinOrganizationErrorEmailDomainNotAllowedDto {
  errorCode: 'email_domain_not_allowed';
  /** @format email */
  email: string;
  /** @example ["example.com"] */
  allowedDomains: string[];
}

export interface JoinOrganizationDto {
  invitationSecret: string;
}

export interface LeaveOrganizationDto {
  /** @format uuid */
  organizationId: string;
}

export interface RenameOrganizationDto {
  /** @format uuid */
  organizationId: string;
  name: string;
}

export type RenameOrganizationSuccessDto = object;

export type DeleteOrganizationSuccessDto = object;

export interface CountMembersSuccessDto {
  count: number;
}

export interface PageDto {
  hasNext: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface OrganizationMemberWorkspaceDto {
  /** @format uuid */
  id: string;
  name: string;
  picture: PictureDto;
  role?:
    | 'workspace://team-lead'
    | 'workspace://member'
    | 'workspace://contributor'
    | null;
}

export interface OrganizationMemberPermissionsDto {
  changeRole: boolean;
  delete: boolean;
}

export interface OrganizationMemberDto {
  /** @format uuid */
  id: string;
  /** @format email */
  invitationEmail: string | null;
  /** @format date-time */
  invitationExpires: string | null;
  invitationExpired: boolean;
  organizationRole: 'organization://admin' | 'organization://workspace-member';
  isActive: boolean;
  user: MemberUserDto | null;
  workspaces: OrganizationMemberWorkspaceDto[];
  /** @format date-time */
  createdAt: string;
  permissions: OrganizationMemberPermissionsDto;
}

export type DeleteOrganizationMembersSuccessDto = object;

export interface ChangeOrganizationMembersRoleMemberDto {
  /** @format uuid */
  memberId: string;
  role: 'organization://admin' | 'organization://workspace-member';
}

export interface ChangeOrganizationMembersRoleDto {
  /** @format uuid */
  organizationId: string;
  members: ChangeOrganizationMembersRoleMemberDto[];
}

export type ChangeOrganizationMembersRoleSuccessDto = object;

export interface WorkspacePermissionsDto {
  delete: boolean;
  changeDetails: boolean;
  inviteTeamLead: boolean;
  listMembers: boolean;
  inviteMember: boolean;
  inviteContributor: boolean;
  publicInvitation: boolean;
  createCampaigns: boolean;
  createPrivateCampaigns: boolean;
  changeCampaignsOrder: boolean;
  manageMemberGroups: boolean;
}

export interface MemberDto {
  /** @format uuid */
  id: string;
  /** @format email */
  invitationEmail: string | null;
  /** @format date-time */
  invitationExpires: string | null;
  invitationExpired: boolean;
  organizationRole: 'organization://admin' | 'organization://workspace-member';
  isActive: boolean;
  user: MemberUserDto | null;
  workspaceRole?:
    | 'workspace://team-lead'
    | 'workspace://member'
    | 'workspace://contributor'
    | null;
}

export interface OrganizationWorkspaceItemDto {
  /** @format uuid */
  id: string;
  /** @format uuid */
  organizationId: string;
  name: string;
  picture: PictureDto;
  /** @example https://example.com/7zy2fkPL */
  publicInvitationUrl?: string | null;
  organizationRole: 'organization://admin' | 'organization://workspace-member';
  role?:
    | 'workspace://team-lead'
    | 'workspace://member'
    | 'workspace://contributor'
    | null;
  permissions: WorkspacePermissionsDto;
  totalMembersCount: number;
  topMembers: MemberDto[];
}

export interface ListOrganizationWorkspacesSuccessDto {
  workspaces: OrganizationWorkspaceItemDto[];
}

export interface GetOrganizationWorkspaceSuccessDto {
  workspace: OrganizationWorkspaceItemDto;
}

export interface InviteOrganizationMembersMemberDto {
  /** @format email */
  email: string;
  role: 'organization://admin';
  customMessage?: string;
}

export interface InviteOrganizationMembersDto {
  /** @format uuid */
  organizationId: string;
  users: InviteOrganizationMembersMemberDto[];
}

export type InviteOrganizationMembersSuccessDto = object;

export interface SubscriptionUsersLimitErrorDto {
  /** Organization members size greater than subscription plan limit */
  errorCode: 'subscription_users_limit_error';
}

export interface ResendOrganizationInvitationMemberDto {
  /** @format email */
  email: string;
}

export interface ResendOrganizationInvitationDto {
  /** @format uuid */
  organizationId: string;
  users: ResendOrganizationInvitationMemberDto[];
}

export type ResendOrganizationInvitationSuccessDto = object;

export interface CreateWorkspaceDto {
  /** @format uuid */
  organizationId: string;
  name: string;
}

export interface WorkspaceItemDto {
  /** @format uuid */
  id: string;
  /** @format uuid */
  organizationId: string;
  name: string;
  picture: PictureDto;
  /** @example https://example.com/7zy2fkPL */
  publicInvitationUrl?: string | null;
  organizationRole: 'organization://admin' | 'organization://workspace-member';
  role?:
    | 'workspace://team-lead'
    | 'workspace://member'
    | 'workspace://contributor'
    | null;
  permissions: WorkspacePermissionsDto;
}

export interface CreateWorkspaceSuccessDto {
  workspace: WorkspaceItemDto;
  /** @format uuid */
  demoCampaignId: string;
}

export interface RenameWorkspaceDto {
  /** @format uuid */
  workspaceId: string;
  name: string;
}

export type RenameWorkspaceSuccessDto = object;

export interface GetWorkspacePictureUploadParamsSuccessDto {
  method: 'PUT';
  url: string;
  /** @example {} */
  fields: object;
  headers: {
    'content-type'?: string;
  };
}

export type DeleteWorkspacePictureSucessDto = object;

export type DeleteWorkspaceSuccessDto = object;

export interface WorkspaceListDto {
  list: WorkspaceItemDto[];
}

export interface GetWorkspaceSuccessDto {
  workspace: WorkspaceItemDto;
}

export interface InviteWorkspaceMembersMemberDto {
  /** @example 3fa85f64-5717-4562-b3fc-2c963f66afa6 or user@example.com */
  memberIdOrEmail: string;
  role:
    | 'workspace://team-lead'
    | 'workspace://member'
    | 'workspace://contributor';
  customMessage?: string | null;
  /** @format uuid */
  groupId?: string | null;
}

export interface InviteWorkspaceMembersDto {
  /** @format uuid */
  workspaceId: string;
  users: InviteWorkspaceMembersMemberDto[];
}

export type InviteWorkspaceMembersSuccessDto = object;

export interface GeneratePublicInvitationUrlDto {
  /** @format uuid */
  workspaceId: string;
}

export interface GeneratePublicInvitationUrlSuccessDto {
  /** @example https://example.com/7zy2fkPL */
  publicInvitationUrl: string;
}

export type DeletePublicInvitationUrlSuccessDto = object;

export interface WorkspaceMemberPermissionsDto {
  changeRole: boolean;
  delete: boolean;
}

export interface FolderDto {
  /** @format uuid */
  id: string;
  name: string;
  isPrivate: boolean;
}

export interface CampaignDto {
  /** @format uuid */
  id: string;
  name: string;
  folders: FolderDto[];
}

export interface WorkspaceMemberGroupDto {
  /** @format uuid */
  id: string;
  name: string;
}

export interface WorkspaceMemberDto {
  /** @format uuid */
  id: string;
  /** @format email */
  invitationEmail: string | null;
  /** @format date-time */
  invitationExpires: string | null;
  invitationExpired: boolean;
  organizationRole: 'organization://admin' | 'organization://workspace-member';
  isActive: boolean;
  user: MemberUserDto | null;
  workspaceRole?:
    | 'workspace://team-lead'
    | 'workspace://member'
    | 'workspace://contributor'
    | null;
  permissions: WorkspaceMemberPermissionsDto;
  campaigns: CampaignDto[];
  groups: WorkspaceMemberGroupDto[];
}

export type DeleteWorkspaceMembersSuccessDto = object;

export interface LeaveWorkspaceDto {
  /** @format uuid */
  workspaceId: string;
}

export interface ChangeWorkspaceMembersRoleMemberDto {
  /** @format uuid */
  memberId: string;
  role:
    | 'workspace://team-lead'
    | 'workspace://member'
    | 'workspace://contributor';
}

export interface ChangeWorkspaceMembersRoleDto {
  /** @format uuid */
  workspaceId: string;
  users: ChangeWorkspaceMembersRoleMemberDto[];
}

export type ChangeWorkspaceMembersRoleSuccessDto = object;

export interface ResendWorkspaceInvitationMemberDto {
  /** @format email */
  invitationEmail: string;
}

export interface ResendWorkspaceInvitationDto {
  /** @format uuid */
  workspaceId: string;
  users: ResendWorkspaceInvitationMemberDto[];
}

export type ResendWorkspaceInvitationSuccessDto = object;

export interface WorkspaceTaskCountersDto {
  count: number;
  campaignGroupsCount: number;
  taskBoardGroupsCount: number;
}

export interface WorkspaceCountersDto {
  allCampaignsCount: number;
  myCampaignsCount: number;
  favoriteCampaignsCount: number;
  deletedCampaignsCount: number;
  allAssetsCount: number;
  myAssetsCount: number;
  favoriteAssetsCount: number;
  deletedAssetsCount: number;
  deletedFoldersCount: number;
  allTasks: WorkspaceTaskCountersDto;
  myTasks: WorkspaceTaskCountersDto;
  assignedToMeTasks: WorkspaceTaskCountersDto;
  deletedTasks: WorkspaceTaskCountersDto;
  recentlyOpenedAssetsCount: number;
}

export interface GetWorkspaceObjectivesSuccessDto {
  objectives: string[];
}

export interface AssetPathItemDto {
  /** @format uuid */
  id: string;
  name: string;
}

export interface AssetPreviewDto {
  /** @format uuid */
  id: string;
  /** @format uuid */
  versionId: string;
  type:
    | 'video'
    | 'audio'
    | 'raster_image'
    | 'vector_image'
    | 'camera_raw_image'
    | 'document'
    | 'spreadsheet'
    | 'presentation'
    | 'archive'
    | 'unknown';
  status:
    | 'analyze_in_progress'
    | 'analyze_error'
    | 'conversion_in_progress'
    | 'conversion_error'
    | 'ready';
  name: string;
  extension: string;
  /** @example https://example.com/zwX5RUfYuqYCvz7w */
  previewUrl?: string | null;
  /** @format date-time */
  previewUrlExpiresAt?: string | null;
  path: AssetPathItemDto[];
}

export interface FolderPermissionsDto {
  delete: boolean;
  rename: boolean;
  setVisibility: boolean;
  inviteFromList: boolean;
  inviteContributors: boolean;
  updateMetadata: boolean;
}

export interface FolderDetailsDto {
  name: string;
  totalAssetsCount: number;
  assetsCount: number;
  topAssets: AssetPreviewDto[];
  permissions: FolderPermissionsDto;
  metadata: object;
}

export interface VideoInfoDto {
  /** @format date-time */
  created?: string | null;
  /** @format date-time */
  modified?: string | null;
  layer?: number | null;
  timeScale?: number | null;
  durationSeconds?: number | null;
  bitRate?: number | null;
  codecRfc6381?: string | null;
  codecName?: string | null;
  codecLongName?: string | null;
  width?: number | null;
  height?: number | null;
  frameRate?: number | null;
  framesCount?: number | null;
}

export interface AudioInfoDto {
  /** @format date-time */
  created?: string | null;
  /** @format date-time */
  modified?: string | null;
  layer?: number | null;
  timeScale?: number | null;
  durationSeconds?: number | null;
  bitRate?: number | null;
  codecRfc6381?: string | null;
  codecName?: string | null;
  codecLongName?: string | null;
  volume?: number | null;
  sampleRate?: number | null;
  channelsCount?: number | null;
  bitsPerSample?: number | null;
}

export interface AVInfoDto {
  isISOBMFF: boolean;
  containerName?: string | null;
  containerLongName?: string | null;
  isFragmented?: boolean | null;
  isProgressive?: boolean | null;
  /** @format date-time */
  created?: string | null;
  /** @format date-time */
  modified?: string | null;
  video?: VideoInfoDto | null;
  audio?: AudioInfoDto | null;
}

export interface AVMetadataInfoDto {
  status: 'pending' | 'ready' | 'error';
  avInfo?: AVInfoDto | null;
}

export interface AssetProxyInfoDto {
  status: 'skipped' | 'pending' | 'ready' | 'error';
  error?: string | null;
  /** @example https://example.com/zwX5RUfYuqYCvz7w */
  url?: string | null;
  /** @format date-time */
  urlExpiresAt?: string | null;
}

export interface VideoProxiesDto {
  '360': AssetProxyInfoDto;
  '540': AssetProxyInfoDto;
  '720': AssetProxyInfoDto;
  '1080': AssetProxyInfoDto;
}

export interface DimensionsDto {
  width: number;
  height: number;
}

export interface ScrubFramesDto {
  count: number;
  dimensions: DimensionsDto;
}

export interface ScrubDto {
  status: 'skipped' | 'pending' | 'ready' | 'error';
  error?: string | null;
  /** @example https://example.com/zwX5RUfYuqYCvz7w */
  url?: string | null;
  /** @format date-time */
  urlExpiresAt?: string | null;
  frames?: ScrubFramesDto | null;
}

export interface AudioVideoMetadataDto {
  type: 'video' | 'audio';
  info: AVMetadataInfoDto;
  videoProxies: VideoProxiesDto;
  audioProxy: AssetProxyInfoDto;
  preview: AssetProxyInfoDto;
  scrub: ScrubDto;
}

export interface ImageInfoDto {
  width?: number | null;
  height?: number | null;
}

export interface ImageMetadataInfoDto {
  status: 'pending' | 'ready' | 'error';
  imageInfo?: ImageInfoDto | null;
}

export interface ImageMetadataDto {
  type: 'raster_image' | 'camera_raw_image' | 'vector_image';
  info: ImageMetadataInfoDto;
  proxy: AssetProxyInfoDto;
  preview: AssetProxyInfoDto;
}

export interface DocumentInfoDto {
  pageCount?: number | null;
}

export interface DocumentMetadataInfoDto {
  status: 'pending' | 'ready' | 'error';
  documentInfo?: DocumentInfoDto | null;
}

export interface DocumentMetadataDto {
  type: 'document' | 'presentation' | 'spreadsheet';
  info: DocumentMetadataInfoDto;
  proxy: AssetProxyInfoDto;
  preview: AssetProxyInfoDto;
}

export interface AWStageMemberDto {
  /** @format email */
  email: string;
  /** @example User Example */
  name?: string | null;
  picture: PictureDto;
  me?: boolean | null;
  organizationRole?:
    | 'organization://admin'
    | 'organization://workspace-member'
    | null;
  workspaceRole?:
    | 'workspace://team-lead'
    | 'workspace://member'
    | 'workspace://contributor'
    | null;
  canComment: boolean;
  canSubmitDecision: boolean;
  canUploadNewVersion: boolean;
  canManageAW: boolean;
  canDownload: boolean;
  hasCommentPriority: boolean;
  hasDominatingDecision: boolean;
  decision?: 'approved' | 'approved_with_feedback' | 'changes_required' | null;
  decisionSkipped: boolean;
  hasAccessToAsset: boolean;
  commentsCount: number;
  /** @format date-time */
  lastViewDate?: string | null;
}

export interface AWStageDto {
  /** @format uuid */
  id: string;
  name: string;
  completionCondition: 'all_decisions_submitted' | 'one_decision_submitted';
  /** @format date-time */
  deadline?: string | null;
  isFinished: boolean;
  status:
    | 'pending'
    | 'in_progress'
    | 'approved'
    | 'approved_with_feedback'
    | 'changes_required'
    | 'skipped';
  members: AWStageMemberDto[];
}

export interface ApprovalWorkflowItemDto {
  owner?: MemberDto | null;
  lockDecisions: boolean;
  disableNonApprovedDownloads: boolean;
  newVersionBehavior: 'pause' | 'restart' | 'restart_stage' | 'continue';
  password?: string | null;
  customMessage?: string | null;
  isPaused: boolean;
  isFinished: boolean;
  status:
    | 'pending'
    | 'in_progress'
    | 'approved'
    | 'approved_with_feedback'
    | 'changes_required'
    | 'skipped';
  /** @format uuid */
  currentStageId?: string | null;
  stages: AWStageDto[];
  /** @example https://example.com/zwX5RUfYuqYCvz7w */
  url: string;
  /** @format date-time */
  createdAt: string;
  demo: boolean;
}

export interface AssetVersionPermissionsDto {
  download: boolean;
  comment: boolean;
  readComments: boolean;
  submitDecision: boolean;
  changeDecision: boolean;
  manageApprovalWorkflow: boolean;
}

export interface AssetVersionItemDto {
  /** @format uuid */
  id: string;
  versionNumber: number;
  name: string;
  extension: string;
  mimeType: string;
  size: number;
  status:
    | 'analyze_in_progress'
    | 'analyze_error'
    | 'conversion_in_progress'
    | 'conversion_error'
    | 'ready';
  user?: MemberUserDto | null;
  commentsCount: number;
  /** @format date-time */
  createdAt: string;
  description?: string | null;
  /** @example https://example.com/zwX5RUfYuqYCvz7w */
  sourceUrl?: string | null;
  /** @format date-time */
  sourceUrlExpiresAt?: string | null;
  metadata: AudioVideoMetadataDto | ImageMetadataDto | DocumentMetadataDto;
  approvalWorkflow?: ApprovalWorkflowItemDto | null;
  permissions: AssetVersionPermissionsDto;
  demo: boolean;
}

export interface AssetPermissionsDto {
  moveToCampaign: boolean;
  moveToPublicFolder: boolean;
  moveToPrivateFolder: boolean;
  delete: boolean;
  rename: boolean;
  createReviews: boolean;
  convertCommentToTask: boolean;
  listTasks: boolean;
  createApprovalWorkflows: boolean;
  uploadNewVersion: boolean;
}

export interface AssetDetailsDto {
  versionsCount: number;
  versions: AssetVersionItemDto[];
  permissions: AssetPermissionsDto;
}

export interface AssetItemDto {
  /** @format uuid */
  id: string;
  isFolder: boolean;
  /** @format uuid */
  organizationId: string;
  /** @format uuid */
  workspaceId: string;
  /** @format uuid */
  campaignId: string;
  campaignName: string;
  owner?: MemberDto | null;
  isPrivate: boolean;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  modifiedAt: string;
  /** @format date-time */
  deletedAt?: string | null;
  isFavorite: boolean;
  /** @format date-time */
  openedAt?: string | null;
  path: AssetPathItemDto[];
  folder?: FolderDetailsDto | null;
  asset?: AssetDetailsDto | null;
  demo: boolean;
}

export interface CampaignAdditionalPermissionsDto {
  canDownloadAssets: boolean;
  canInviteContributors: boolean;
  canShareExternalLinks: boolean;
  canEditTasks: boolean;
  canUploadToRoot: boolean;
}

export interface CampaignLinkDto {
  name: string;
  /** @example https://example.com */
  url: string;
}

export interface CampaignAgencyContactDto {
  name: string;
  /** @format email */
  email: string;
}

export interface CampaignAgencyDto {
  name: string;
  contacts: CampaignAgencyContactDto;
}

export interface CampaignPermissionsDto {
  delete: boolean;
  archive: boolean;
  changeDetails: boolean;
  setVisibility: boolean;
  changeAdditionalPermissions: boolean;
  inviteFromList: boolean;
  inviteContributors: boolean;
  createPrivateFolders: boolean;
  createTaskFields: boolean;
  renameTaskFields: boolean;
  deleteTaskFields: boolean;
  createTaskBoards: boolean;
  renameTaskBoards: boolean;
  moveTaskBoards: boolean;
  deleteTaskBoards: boolean;
  deleteAllTasks: boolean;
  createTasks: boolean;
  updateMetadata: boolean;
  createAssets: boolean;
  changeAWDefaultSettings: boolean;
}

export interface CampaignItemDto {
  /** @format uuid */
  id: string;
  /** @format uuid */
  organizationId: string;
  /** @format uuid */
  workspaceId: string;
  owner?: MemberDto | null;
  name: string;
  isPrivate: boolean;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  lastEventTime: string;
  isFavorite: boolean;
  /** @format date-time */
  archivedAt?: string | null;
  /** @format date-time */
  deletedAt?: string | null;
  isRestoringFromArchive: boolean;
  additionalPermissions: CampaignAdditionalPermissionsDto;
  totalAssetsCount: number;
  assetsCount: number;
  foldersCount: number;
  taskBoardsCount: number;
  topAssets: AssetPreviewDto[];
  kpis?: string | null;
  finalDeliverables?: string | null;
  description?: string | null;
  targetAudience?: string | null;
  otherNotes?: string | null;
  budget?: string | null;
  /** @format date-time */
  deadline?: string | null;
  links: CampaignLinkDto[];
  objectives: string[];
  agencies: CampaignAgencyDto[];
  permissions: CampaignPermissionsDto;
  demo: boolean;
  metadata: object;
}

export interface TaskItemMemberDto {
  /** @format uuid */
  id: string;
  /** @format email */
  invitationEmail: string | null;
  /** @format date-time */
  invitationExpires: string | null;
  invitationExpired: boolean;
  organizationRole: 'organization://admin' | 'organization://workspace-member';
  isActive: boolean;
  user: MemberUserDto | null;
  workspaceRole?:
    | 'workspace://team-lead'
    | 'workspace://member'
    | 'workspace://contributor'
    | null;
  hasAccessToTask: boolean;
}

export interface TaskItemCampaignDto {
  /** @format uuid */
  id: string;
  name: string;
}

export interface TaskItemBoardDto {
  /** @example no_assigned */
  id: string | 'no_assigned' | 'archived';
  /** @example no_assigned */
  name: string;
}

export interface TaskItemAssetVersionDto {
  /** @format uuid */
  id: string;
  type:
    | 'video'
    | 'audio'
    | 'raster_image'
    | 'vector_image'
    | 'camera_raw_image'
    | 'document'
    | 'spreadsheet'
    | 'presentation'
    | 'archive'
    | 'unknown';
  status:
    | 'analyze_in_progress'
    | 'analyze_error'
    | 'conversion_in_progress'
    | 'conversion_error'
    | 'ready';
  name: string;
  extension: string;
}

export interface TaskItemAssetDto {
  /** @format uuid */
  id: string;
  isDeleted: boolean;
  selectedVersion: TaskItemAssetVersionDto;
  lastVersion: TaskItemAssetVersionDto;
  path: AssetPathItemDto[];
}

export interface AssetVersionCommentTimecodeDto {
  fromSeconds: number;
  toSeconds?: number | null;
}

export interface TaskAssetVersionCommentDto {
  /** @format uuid */
  id: string;
  timeCode?: AssetVersionCommentTimecodeDto | null;
  documentPage?: number | null;
  hasAnnotations: boolean;
}

export interface TaskCustomFieldDto {
  /** @format uuid */
  id: string;
  /** @example tags */
  type: 'tags' | 'url' | 'text';
  name: string;
  /** @example ["Tag 1","Tag 2"] */
  value: string | string[];
}

export interface AttachmentDto {
  /** @format uuid */
  id: string;
  name: string;
  mimeType: string;
  size: number;
  /** @example https://example.com/zwX5RUfYuqYCvz7w */
  url: string;
  /** @format date-time */
  urlExpiresAt: string;
}

export interface TaskPermissionsDto {
  update: boolean;
  delete: boolean;
  comment: boolean;
}

export interface TaskItemDto {
  /** @format uuid */
  id: string;
  owner?: TaskItemMemberDto | null;
  /** @format uuid */
  organizationId: string;
  /** @format uuid */
  workspaceId: string;
  campaign: TaskItemCampaignDto;
  /** @example {"id":"archived","name":"archived"} */
  board: TaskItemBoardDto;
  beforeArchivedBoard?: TaskItemBoardDto;
  asset?: TaskItemAssetDto | null;
  assetVersionComment?: TaskAssetVersionCommentDto | null;
  name: string;
  description: string;
  descriptionMentions: TaskItemMemberDto[];
  status: 'todo' | 'in_progress' | 'stuck' | 'done';
  assignees: TaskItemMemberDto[];
  watchers: TaskItemMemberDto[];
  priority?: 'low' | 'medium' | 'high' | 'critical' | null;
  /** @format date-time */
  dueDate?: string | null;
  /** @example [{"id":"3fa85f64-5717-4562-b3fc-2c963f66afa6","type":"tags","name":"Custom Tags","value":["Tag 1","Tag 2"]},{"id":"3fa85f64-5717-4562-b3fc-2c963f66afa6","type":"url","name":"Custom Url","value":"{\"url\": \"https://example.org/\", \"label\": \"string\"}"},{"id":"3fa85f64-5717-4562-b3fc-2c963f66afa6","type":"text","name":"Custom Text","value":"string"}] */
  customFields: TaskCustomFieldDto[];
  attachments: AttachmentDto[];
  archived: boolean;
  commentsCount: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  firstCompletedAt?: string | null;
  /** @format date-time */
  lastEventTime: string;
  /** @format date-time */
  deletedAt?: string | null;
  permissions: TaskPermissionsDto;
  demo: boolean;
}

export interface CommentTaskDto {
  /** @format uuid */
  id: string;
}

export interface AssetVersionCommentUserDto {
  type: 'user' | 'external-reviewer';
  /** @format uuid */
  id: string;
  picture: PictureDto;
  /** @format email */
  email: string;
  firstName: string;
  lastName?: string | null;
  me?: boolean | null;
}

export interface AssetVersionCommentMentionDto {
  /** @format uuid */
  id: string;
  picture: PictureDto;
  /** @format email */
  email: string;
  firstName: string;
  lastName?: string | null;
  me?: boolean | null;
}

export interface AssetVersionCommentGroupMentionDto {
  /** @format uuid */
  id: string;
  name: string;
}

export interface AssetVersionCommentAWDto {
  /** @format uuid */
  id: string;
  /** @format uuid */
  stageId?: string | null;
}

export interface AssetVersionCommentPermissionsDto {
  update: boolean;
  delete: boolean;
}

export interface AssetVersionCampaignSearchItemDto {
  id: string;
  name: string;
}

export interface AssetVersionSearchItemDto {
  /** @format uuid */
  id: string;
  /** @format uuid */
  assetId: string;
  name: string;
  campaign: AssetVersionCampaignSearchItemDto;
  status:
    | 'analyze_in_progress'
    | 'analyze_error'
    | 'conversion_in_progress'
    | 'conversion_error'
    | 'ready';
  /** @example https://example.com/zwX5RUfYuqYCvz7w */
  previewUrl?: string | null;
  /** @format date-time */
  previewUrlExpiresAt?: string | null;
}

export interface AssetVersionCommentSearchItemDto {
  /** @format uuid */
  id: string;
  /** @format uuid */
  repliedToId?: string | null;
  /** @format uuid */
  topParentId?: string | null;
  task?: CommentTaskDto | null;
  user?: AssetVersionCommentUserDto | null;
  isExternal: boolean;
  priority: boolean;
  text: string;
  timeCode?: AssetVersionCommentTimecodeDto | null;
  documentPage?: number | null;
  annotations?: string | null;
  mentions: AssetVersionCommentMentionDto[];
  groupMentions: AssetVersionCommentGroupMentionDto[];
  likes: AssetVersionCommentUserDto[];
  approvalWorkflow?: AssetVersionCommentAWDto | null;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt?: string | null;
  permissions: AssetVersionCommentPermissionsDto;
  assetVersion: AssetVersionSearchItemDto;
}

export interface SearchWorkspaceItemsSuccessDto {
  assets: AssetItemDto[];
  assetsCount: number;
  campaigns: CampaignItemDto[];
  campaignsCount: number;
  tasks: TaskItemDto[];
  tasksCount: number;
  comments: AssetVersionCommentSearchItemDto[];
  commentsCount: number;
}

export interface CountSearchedAssetCommentsSuccessDto {
  count: number;
}

export interface WorkspaceMemberGroupListItemDto {
  /** @format uuid */
  id: string;
  name: string;
  membersCount: number;
  topMembers: MemberDto[];
}

export interface WorkspaceMemberGroupItemDto {
  /** @format uuid */
  id: string;
  name: string;
}

export interface GetWorkspaceMemberGroupSuccessDto {
  group: WorkspaceMemberGroupItemDto;
}

export interface CreateWorkspaceMemberGroupMemberDto {
  /** @example 3fa85f64-5717-4562-b3fc-2c963f66afa6 or user@example.com */
  memberIdOrEmail: string;
  role:
    | 'workspace://team-lead'
    | 'workspace://member'
    | 'workspace://contributor';
  customMessage?: string | null;
}

export interface CreateWorkspaceMemberGroupDto {
  /** @format uuid */
  workspaceId: string;
  name: string;
  users: CreateWorkspaceMemberGroupMemberDto[];
}

export interface CreateWorkspaceMemberGroupSuccessDto {
  group: WorkspaceMemberGroupItemDto;
}

export interface RenameWorkspaceMemberGroupDto {
  /** @format uuid */
  workspaceId: string;
  /** @format uuid */
  groupId: string;
  name: string;
}

export type RenameWorkspaceMemberGroupSuccessDto = object;

export type DeleteWorkspaceMemberGroupSuccessDto = object;

export type DeleteWorkspaceMemberGroupMembersSuccessDto = object;

export interface SubscriptionFeatureNotAllowedErrorDto {
  errorCode: 'feature_not_allowed';
  /** @format uuid */
  organizationId: string;
  subscriptionType:
    | 'starter_20240711'
    | 'team_20240711'
    | 'free'
    | 'starter'
    | 'team'
    | 'enterprise';
  feature:
    | 'private_campaigns'
    | 'private_folders'
    | 'password_protected_share_links'
    | 'external_contributors'
    | 'download_comments'
    | 'approval_routing_due_dates'
    | 'priority_comments'
    | 'approval_routing'
    | 'contributors_can_create_approval_routing'
    | 'contributors_can_set_task_deadline'
    | 'comment_mentions'
    | 'share_multiple_assets'
    | 'universal_task_view'
    | 'same_reviewer_on_multiple_aw_stages'
    | 'enable_two_factor_auth'
    | 'slack_integration'
    | 'user_groups';
}

export interface CreateCampaignDto {
  /** @format uuid */
  workspaceId: string;
  name: string;
  isPrivate: boolean;
  forOnboarding?: boolean | null;
  defaultTaskBoardNames?: string[];
}

export interface CreateCampaignSuccessDto {
  campaign: CampaignItemDto;
  defaultTaskBoardIds: string[];
}

export interface GetCampaignSuccessDto {
  campaign: CampaignItemDto;
}

export interface UpdateCampaignLinkDto {
  name: string;
  /** @example https://example.com */
  url: string;
}

export interface UpdateCampaignAgencyContactDto {
  name: string;
  /** @format email */
  email: string;
}

export interface UpdateCampaignAgencyDto {
  name: string;
  contacts?: UpdateCampaignAgencyContactDto[];
}

export interface UpdateCampaignDto {
  /** @format uuid */
  id: string;
  name?: string;
  kpis?: string | null;
  finalDeliverables?: string | null;
  description?: string | null;
  targetAudience?: string | null;
  otherNotes?: string | null;
  budget?: string | null;
  /** @format date-time */
  deadline?: string | null;
  links?: UpdateCampaignLinkDto[];
  objectives?: string[];
  agencies?: UpdateCampaignAgencyDto[];
}

export interface CountCampaignsSuccessDto {
  count: number;
}

export interface SimpleCampaignItemDto {
  /** @format uuid */
  id: string;
  name: string;
  isPrivate: boolean;
  demo: boolean;
  canUploadToRoot: boolean;
}

export interface GetSimpleCampaignsListSuccessDto {
  list: SimpleCampaignItemDto[];
}

export interface SimpleFolderItemDto {
  /** @format uuid */
  id: string;
  /** @format uuid */
  parentId?: string | null;
  name: string;
  isPrivate: boolean;
}

export interface SimpleCampaignWithFoldersItemDto {
  /** @format uuid */
  id: string;
  name: string;
  isPrivate: boolean;
  demo: boolean;
  canUploadToRoot: boolean;
  folders: SimpleFolderItemDto[];
}

export interface GetSimpleCampaignsListWithFoldersSuccessDto {
  list: SimpleCampaignWithFoldersItemDto[];
}

export type DeleteCampaignSuccessDto = object;

export interface RestoreCampaignDto {
  /** @format uuid */
  campaignId: string;
}

export type RestoreCampaignSuccessDto = object;

export interface SetCampaignVisibilityDto {
  /** @format uuid */
  campaignId: string;
  isPrivate: boolean;
}

export type SetCampaignVisibilitySuccessDto = object;

export interface UpdateCampaignAdditionalPermissionsDto {
  /** @format uuid */
  campaignId: string;
  canDownloadAssets: boolean;
  canInviteContributors: boolean;
  canShareExternalLinks: boolean;
  canUploadToRoot?: boolean;
  canEditTasks: boolean;
}

export type UpdateCampaignAdditionalPermissionsSuccessDto = object;

export interface SetCampaignFavoriteDto {
  /** @format uuid */
  campaignId: string;
  isFavorite: boolean;
}

export type SetCampaignFavoriteSuccessDto = object;

export interface ArchiveCampaignDto {
  /** @format uuid */
  campaignId: string;
}

export type ArchiveCampaignSuccessDto = object;

export interface RestoreCampaignFromArchiveDto {
  /** @format uuid */
  campaignId: string;
}

export type RestoreCampaignFromArchiveSuccessDto = object;

export interface MemberGroupDto {
  /** @format uuid */
  id: string;
  name: string;
  membersCount: number;
}

export interface InviteCampaignMembersMemberDto {
  /** @example 3fa85f64-5717-4562-b3fc-2c963f66afa6 or user@example.com */
  memberIdOrEmail: string;
  customMessage?: string;
}

export interface InviteCampaignMembersMemberGroupDto {
  /** @format uuid */
  id: string;
  customMessage?: string;
}

export interface InviteCampaignMembersDto {
  /** @format uuid */
  campaignId: string;
  users?: InviteCampaignMembersMemberDto[] | null;
  groups?: InviteCampaignMembersMemberGroupDto[] | null;
}

export type InviteCampaignMembersSuccessDto = object;

export interface CampaignMemberGroupSimpleDto {
  /** @format uuid */
  id: string;
  name: string;
}

export interface CampaignMemberPermissionsDto {
  shareAccess: boolean;
  revokeAccess: boolean;
}

export interface CampaignMemberDto {
  /** @format uuid */
  id: string;
  /** @format email */
  invitationEmail: string | null;
  /** @format date-time */
  invitationExpires: string | null;
  invitationExpired: boolean;
  organizationRole: 'organization://admin' | 'organization://workspace-member';
  isActive: boolean;
  user: MemberUserDto | null;
  workspaceRole?:
    | 'workspace://team-lead'
    | 'workspace://member'
    | 'workspace://contributor'
    | null;
  hasAccessToPrivate: boolean;
  groupAccess: CampaignMemberGroupSimpleDto[];
  groups: CampaignMemberGroupSimpleDto[];
  accessControl: 'enable_disable' | 'add_delete';
  permissions: CampaignMemberPermissionsDto;
}

export interface CampaignMemberGroupPermissionsDto {
  shareAccess: boolean;
  revokeAccess: boolean;
}

export interface CampaignMemberGroupDto {
  /** @format uuid */
  id: string;
  name: string;
  hasAccessToPrivate: boolean;
  membersCount: number;
  permissions: CampaignMemberGroupPermissionsDto;
}

export type DeleteCampaignMembersSuccessDto = object;

export interface CampaignCreatedPayloadDto {
  type: 'campaign-created';
  v: '1';
}

export interface CampaignUpdatedPayloadDto {
  type: 'campaign-updated';
  v: '1';
}

export interface CampaignVisibilityChangedPayloadDto {
  type: 'campaign-visibility-changed';
  v: '1';
  isPrivate: boolean;
}

export interface TaskCreatedPayloadDto {
  type: 'created';
  v: '1';
  name: string;
}

export interface TaskCompletedPayloadDto {
  type: 'task-completed';
  v: '1';
  /** @format uuid */
  taskId: string;
  taskName: string;
}

export interface AssetUploadedPayloadDto {
  type: 'asset-uploaded';
  v: '1';
  /** @format uuid */
  assetId: string;
  assetName: string;
}

export interface AssetVersionUploadedPayloadDto {
  type: 'asset-version-uploaded';
  v: '1';
  /** @format uuid */
  assetId: string;
  /** @format uuid */
  assetVersionId: string;
  assetName: string;
}

export interface AWCreatedPayloadDto {
  type: 'aw-created';
  v: '1';
  /** @format uuid */
  assetId: string;
  /** @format uuid */
  assetVersionId: string;
  assetName: string;
}

export interface AWUpdatedPayloadDto {
  type: 'aw-updated';
  v: '1';
  /** @format uuid */
  assetId: string;
  /** @format uuid */
  assetVersionId: string;
  assetName: string;
}

export interface CampaignEventDto {
  /** @format uuid */
  id: string;
  user?: MemberUserDto | null;
  /** @format date-time */
  eventTime: string;
  payload:
    | CampaignCreatedPayloadDto
    | CampaignUpdatedPayloadDto
    | CampaignVisibilityChangedPayloadDto
    | TaskCreatedPayloadDto
    | TaskCompletedPayloadDto
    | AssetUploadedPayloadDto
    | AssetVersionUploadedPayloadDto
    | AWCreatedPayloadDto
    | AWUpdatedPayloadDto;
}

export interface CreateTaskFieldDto {
  /** @format uuid */
  campaignId: string;
  type: 'tags' | 'url' | 'text';
  name: string;
}

export interface TaskFieldItemDto {
  /** @format uuid */
  id: string;
  /** @format uuid */
  campaignId: string;
  type: 'tags' | 'url' | 'text';
  name: string;
  tags?: string[] | null;
}

export interface CreateTaskFieldSuccessDto {
  field: TaskFieldItemDto;
}

export interface GetTaskFieldSuccessDto {
  field: TaskFieldItemDto[];
}

export interface ListTaskFieldsSuccessDto {
  fields: TaskFieldItemDto[];
}

export type DeleteTaskFieldSuccessDto = object;

export interface RenameTaskFieldDto {
  /** @format uuid */
  taskFieldId: string;
  /** @format uuid */
  campaignId: string;
  name: string;
}

export type RenameTaskFieldSuccessDto = object;

export interface ChangeCampaignOrderDto {
  /** @format uuid */
  id: string;
  /** @format uuid */
  beforeCampaignId?: string | null;
}

export type ChangeCampaignOrderSuccessDto = object;

export interface UpdateCampaignMetadataDto {
  /** @format uuid */
  campaignId: string;
  metadata: object;
}

export type UpdateCampaignMetadataSuccessDto = object;

export interface AWDefaultSettingsDto {
  lockDecisions: boolean | null;
  disableNonApprovedDownloads: boolean | null;
  newVersionBehavior: 'pause' | 'restart' | 'restart_stage' | 'continue' | null;
  password: string | null;
  customMessage: string | null;
}

export interface GetAWDefaultSettingsSuccessDto {
  settings: AWDefaultSettingsDto;
}

export interface UpdateAWDefaultSettingsDto {
  /** @format uuid */
  campaignId: string;
  lockDecisions?: boolean | null;
  disableNonApprovedDownloads?: boolean | null;
  newVersionBehavior?:
    | 'pause'
    | 'restart'
    | 'restart_stage'
    | 'continue'
    | null;
  password?: string | null;
  customMessage?: string | null;
}

export type UpdateAWDefaultSettingsSuccessDto = object;

export interface CreateFolderDto {
  /** @format uuid */
  campaignId: string;
  name: string;
  isPrivate: boolean;
}

export interface CreateFolderSuccessDto {
  asset: AssetItemDto;
}

export type DeleteFolderSuccessDto = object;

export interface RestoreFolderDto {
  /** @format uuid */
  folderId: string;
}

export type RestoreFolderSuccessDto = object;

export interface SetFolderVisibilityDto {
  /** @format uuid */
  folderId: string;
  isPrivate: boolean;
}

export type SetFolderVisibilitySuccessDto = object;

export interface RenameFolderDto {
  /** @format uuid */
  folderId: string;
  name: string;
}

export type RenameFolderSuccessDto = object;

export interface GetSimpleFoldersListSuccessDto {
  list: SimpleFolderItemDto[];
}

export interface InviteFolderMembersMemberDto {
  /** @example 3fa85f64-5717-4562-b3fc-2c963f66afa6 or user@example.com */
  memberIdOrEmail: string;
  customMessage?: string;
}

export interface InviteFolderMembersMemberGroupDto {
  /** @format uuid */
  id: string;
  customMessage?: string;
}

export interface InviteFolderMembersDto {
  /** @format uuid */
  folderId: string;
  users?: InviteFolderMembersMemberDto[] | null;
  groups?: InviteFolderMembersMemberGroupDto[] | null;
}

export type InviteFolderMembersSuccessDto = object;

export interface FolderMemberGroupSimpleDto {
  /** @format uuid */
  id: string;
  name: string;
}

export interface FolderMemberPermissionsDto {
  shareAccess: boolean;
  revokeAccess: boolean;
}

export interface FolderMemberDto {
  /** @format uuid */
  id: string;
  /** @format email */
  invitationEmail: string | null;
  /** @format date-time */
  invitationExpires: string | null;
  invitationExpired: boolean;
  organizationRole: 'organization://admin' | 'organization://workspace-member';
  isActive: boolean;
  user: MemberUserDto | null;
  workspaceRole?:
    | 'workspace://team-lead'
    | 'workspace://member'
    | 'workspace://contributor'
    | null;
  hasAccessToPrivate: boolean;
  groupAccess: FolderMemberGroupSimpleDto[];
  groups: FolderMemberGroupSimpleDto[];
  accessControl: 'enable_disable' | 'add_delete';
  permissions: FolderMemberPermissionsDto;
}

export interface FolderMemberGroupPermissionsDto {
  shareAccess: boolean;
  revokeAccess: boolean;
}

export interface FolderMemberGroupDto {
  /** @format uuid */
  id: string;
  name: string;
  hasAccessToPrivate: boolean;
  membersCount: number;
  permissions: FolderMemberGroupPermissionsDto;
}

export type DeleteFolderMembersSuccessDto = object;

export interface UpdateFolderMetadataDto {
  /** @format uuid */
  folderId: string;
  metadata: object;
}

export type UpdateFolderMetadataSuccessDto = object;

export interface GetAssetSuccessDto {
  asset: AssetItemDto;
}

export interface CountAssetsSuccessDto {
  count: number;
}

export interface AssetDownloadItemDto {
  /** @format uuid */
  id: string;
  fileName: string;
  /** @example https://example.com/zwX5RUfYuqYCvz7w */
  sourceUrl?: string | null;
}

export interface GetAssetsDownloadListSuccessDto {
  list: AssetDownloadItemDto[];
}

export type MoveAssetToFolderSuccessDto = object;

export interface UploadTargetDto {
  type: 'folder' | 'asset';
  /** @format uuid */
  id: string;
}

export interface InitAssetVersionUploadDto {
  target?: UploadTargetDto | null;
  /** @format uuid */
  campaignId: string;
  fileName: string;
  fileSize: number;
}

export interface AssetsSizeLimitExceededErrorDto {
  errorCode: 'assets_size_limit_exceeded';
}

export interface AssetsSizeLimitWillExceededErrorDto {
  errorCode: 'assets_size_limit_will_exceeded';
  remainingSpace: number;
}

export interface InitAssetVersionUploadSuccessDto {
  /** @format uuid */
  assetVersionId: string;
  uploadId: string;
  key: string;
}

export interface SetAssetFavoriteDto {
  /** @format uuid */
  assetId: string;
  isFavorite: boolean;
}

export type SetAssetFavoriteSuccessDto = object;

export type DeleteAssetSuccessDto = object;

export interface RestoreAssetDto {
  /** @format uuid */
  assetId: string;
}

export type RestoreAssetSuccessDto = object;

export interface RenameAssetDto {
  assetVersionId: string;
  name: string;
}

export type RenameAssetSuccessDto = object;

export interface SetAssetOpenedDto {
  /** @format uuid */
  assetId: string;
  /** @format uuid */
  assetVersionId: string;
}

export type SetAssetOpenedSuccessDto = object;

export interface ChangeAssetOrderDto {
  /** @format uuid */
  id: string;
  /** @format uuid */
  beforeAssetId?: string | null;
}

export type ChangeAssetOrderSuccessDto = object;

export interface ChangeFolderOrderDto {
  /** @format uuid */
  id: string;
  /** @format uuid */
  beforeFolderId?: string | null;
}

export type ChangeFolderOrderSuccessDto = object;

export interface UpdateAssetVersionDescriptionDto {
  /** @format uuid */
  assetVersionId: string;
  description?: string;
}

export type UpdateAssetVersionDescriptionSuccessDto = object;

export interface NotCurrentAssetVersionErrorDto {
  errorCode: 'not_current_asset_version';
}

export interface RemoveAssetVersionFromStackSuccessDto {
  currenAsset: AssetItemDto;
  newAsset: AssetItemDto;
}

export interface AddAssetToStackSuccessDto {
  asset: AssetItemDto;
}

export interface CopyAssetVersionSuccessDto {
  /** @format uuid */
  newVersionId: string;
}

export interface MultipartUploadCompletedPartDto {
  PartNumber: number;
  ETag: string;
}

export interface CompleteMultipartUploadDto {
  uploadId: string;
  key: string;
  parts: MultipartUploadCompletedPartDto[];
}

export type CompleteMultipartUploadSuccessDto = object;

export type AbortMultipartUploadSuccessDto = object;

export interface MultipartUploadPartDto {
  PartNumber: number;
  ETag: string;
  /** @format date-time */
  LastModified: string;
  Size: number;
}

export interface ListMultipartUploadPartsSuccessDto {
  parts: MultipartUploadPartDto[];
}

export interface GetMultipartUploadPartsUploadParamsSuccessDto {
  /** @example {"1":"string","2":"string"} */
  presignedUrls: Record<string, string>;
  /** @example {"some-header":"string"} */
  headers: Record<string, string>;
}

export interface CreateTaskBoardDto {
  /** @format uuid */
  campaignId: string;
  /**
   * @format uuid
   * @example no_assigned
   */
  beforeBoardId?: string | null;
  name: string;
}

export interface TaskBoardItemDto {
  id: string | 'no_assigned' | 'archived';
  name: string;
  tasksCount: number;
  overdueTaskIds: string[];
  dueSoonTaskIds: string[];
  demo: boolean;
}

export interface CreateTaskBoardSuccessDto {
  board: TaskBoardItemDto;
}

export interface RenameTaskBoardDto {
  /** @format uuid */
  campaignId: string;
  /** @format uuid */
  id: string;
  name: string;
}

export type RenameTaskBoardSuccessDto = object;

export interface MoveTaskBoardDto {
  /** @format uuid */
  campaignId: string;
  /** @format uuid */
  id: string;
  /**
   * @format uuid
   * @example no_assigned
   */
  beforeBoardId?: string | null;
}

export type MoveTaskBoardSuccessDto = object;

export interface DeleteTaskBoardSuccessDto {
  /** @format uuid */
  deletedTasks: string;
}

export interface GetTaskBoardSuccessDto {
  board: TaskBoardItemDto;
}

export interface ListTaskBoardsSuccessDto {
  list: TaskBoardItemDto[];
}

export interface TaskBoardPositionDto {
  /** @example no_assigned */
  id: string | 'no_assigned';
  /** @format uuid */
  beforeTaskId?: string | null;
}

export interface TaskCustomFieldValueDto {
  /** @format uuid */
  id: string;
  value: string | string[];
}

export interface CreateTaskDto {
  /** @format uuid */
  campaignId: string;
  board?: TaskBoardPositionDto;
  /** @format uuid */
  assetVersionId?: string | null;
  /** @format uuid */
  assetVersionCommentId?: string;
  name: string;
  description?: string;
  /** Members' ids */
  descriptionMentions?: string[];
  status?: 'todo' | 'in_progress' | 'stuck' | 'done';
  /** Members' ids */
  assignees?: string[];
  /** Members' ids */
  watchers?: string[];
  priority?: 'low' | 'medium' | 'high' | 'critical' | null;
  /** @format date-time */
  dueDate?: string | null;
  customFields?: TaskCustomFieldValueDto[];
}

export interface TaskFromCommentAlreadyExistsErrorDto {
  errorCode: 'task_from_comment_already_exists';
  /** @format uuid */
  taskId: string;
  /** @format uuid */
  assetVersionCommentId: string;
}

export interface CreateTaskSuccessDto {
  task: TaskItemDto;
}

export interface UpdateTaskMembersDto {
  add?: string[];
  remove?: string[];
}

export interface AddTaskCustomFieldValueDto {
  /** @format uuid */
  id: string;
  value: string;
}

export interface RemoveTaskCustomFieldValueDto {
  /** @format uuid */
  id: string;
  /** For tags removes only specific tag if value is present and all tags if value is omitted. For other fields this property simply ignored. */
  value?: string;
}

export interface UpdateTaskCustomFieldValuesDto {
  /** For tags, if you need to add multiple of them, add multiple entries with the same id and different values. */
  add?: AddTaskCustomFieldValueDto[];
  /** For tags, if you need to remove multiple of them, add multiple entries with the same id and different values. If you need to remove all tags just omit value prop. */
  remove?: RemoveTaskCustomFieldValueDto[];
}

export interface UpdateTaskDto {
  /** @format uuid */
  id: string;
  board?: TaskBoardPositionDto;
  /** @format uuid */
  assetVersionId?: string | null;
  name?: string;
  description?: string;
  descriptionMentions?: UpdateTaskMembersDto;
  status?: 'todo' | 'in_progress' | 'stuck' | 'done';
  assignees?: UpdateTaskMembersDto;
  watchers?: UpdateTaskMembersDto;
  priority?: 'low' | 'medium' | 'high' | 'critical' | null;
  /** @format date-time */
  dueDate?: string | null;
  customFields?: UpdateTaskCustomFieldValuesDto;
}

export type UpdateTaskSuccessDto = object;

export type DeleteTaskSuccessDto = object;

export interface RestoreTaskDto {
  /** @format uuid */
  id: string;
}

export type RestoreTaskSuccessDto = object;

export interface GetTaskSuccessDto {
  task: TaskItemDto;
}

export interface CountTasksSuccessDto {
  count: number;
}

export interface CreateTaskCommentDto {
  /** @format uuid */
  taskId: string;
  text: string;
  mentions?: string[] | null;
}

export interface TaskCommentPermissionsDto {
  update: boolean;
  delete: boolean;
}

export interface TaskCommentPayloadDto {
  type: 'comment';
  v: '1';
  text: string;
  mentions: TaskItemMemberDto[];
  permissions: TaskCommentPermissionsDto;
}

export interface TaskRenamedPayloadDto {
  type: 'renamed';
  v: '1';
  prevName: string;
  newName: string;
}

export interface TaskStatusChangedPayloadDto {
  type: 'status_changed';
  v: '1';
  prevStatus: 'todo' | 'in_progress' | 'stuck' | 'done';
  newStatus: 'todo' | 'in_progress' | 'stuck' | 'done';
}

export interface TaskPriorityChangedPayloadDto {
  type: 'priority_changed';
  v: '1';
  prevPriority?: 'low' | 'medium' | 'high' | 'critical' | null;
  newPriority?: 'low' | 'medium' | 'high' | 'critical' | null;
}

export interface TaskDueDateChangedPayloadDto {
  type: 'due_date_changed';
  v: '1';
  /** @format date-time */
  prevDueDate?: string | null;
  /** @format date-time */
  newDueDate?: string | null;
}

export interface TaskDescriptionChangedPayloadDto {
  type: 'description_changed';
  v: '1';
  prevDescription: string;
  newDescription: string;
}

export interface TaskCustomFiledChangedFieldDto {
  /** @format uuid */
  id: string;
  name: string;
  type: 'tags' | 'url' | 'text';
  addedValues: string[];
  removedValues: string[];
}

export interface TaskCustomFiledChangedPayloadDto {
  type: 'custom_field_changed';
  v: '1';
  field: TaskCustomFiledChangedFieldDto;
}

export interface TaskBoardChangedBoardDto {
  /** @example no_assigned */
  id: string | 'no_assigned' | 'archived';
  /** @example no_assigned */
  name: string;
}

export interface TaskBoardChangedPayloadDto {
  type: 'board_changed';
  v: '1';
  prevBoard: TaskBoardChangedBoardDto;
  newBoard: TaskBoardChangedBoardDto;
}

export interface TaskAssetVersionChangedAssetDto {
  /** @format uuid */
  id: string;
  name: string;
  extension: string;
}

export interface TaskAssetVersionChangedPayloadDto {
  type: 'asset_version_changed';
  v: '1';
  prevAssetVersion?: TaskAssetVersionChangedAssetDto | null;
  newAssetVersion?: TaskAssetVersionChangedAssetDto | null;
}

export interface TaskAttachmentChangedAttachmentDto {
  /** @format uuid */
  id: string;
  name: string;
}

export interface TaskAttachmentCreatedPayloadDto {
  type: 'attachment_created';
  v: '1';
  attachment: TaskAttachmentChangedAttachmentDto;
}

export interface TaskAttachmentDeletedPayloadDto {
  type: 'attachment_deleted';
  v: '1';
  attachment: TaskAttachmentChangedAttachmentDto;
}

export interface TaskDeletedPayloadDto {
  type: 'deleted';
  v: '1';
  name: string;
}

export interface TaskEventDto {
  id: string;
  user?: MemberUserDto | null;
  /** @format date-time */
  eventTime: string;
  payload:
    | TaskCommentPayloadDto
    | TaskCreatedPayloadDto
    | TaskRenamedPayloadDto
    | TaskStatusChangedPayloadDto
    | TaskPriorityChangedPayloadDto
    | TaskDueDateChangedPayloadDto
    | TaskDescriptionChangedPayloadDto
    | TaskCustomFiledChangedPayloadDto
    | TaskBoardChangedPayloadDto
    | TaskAssetVersionChangedPayloadDto
    | TaskAttachmentCreatedPayloadDto
    | TaskAttachmentDeletedPayloadDto
    | TaskDeletedPayloadDto;
}

export interface CreateTaskCommentSuccessDto {
  node: TaskEventDto;
  cursor: string;
}

export interface UpdateTaskCommentMentionsDto {
  add?: string[];
  remove?: string[];
}

export interface UpdateTaskCommentDto {
  /** @format uuid */
  id: string;
  text: string;
  mentions?: UpdateTaskCommentMentionsDto;
}

export type UpdateTaskCommentSuccessDto = object;

export type DeleteTaskCommentSuccessDto = object;

export interface GetTaskCommentSuccessDto {
  comment: TaskEventDto;
}

export interface GetTaskEventSuccessDto {
  node: TaskEventDto;
  cursor: string;
}

export interface TaskBoardGroupDto {
  ids: string[];
  name: string;
  tasksCount: number;
  overdueTaskIds: string[];
  dueSoonTaskIds: string[];
}

export interface ListTaskBoardGroupsSuccessDto {
  list: TaskBoardGroupDto[];
}

export interface CampaignGroupDto {
  /** @format uuid */
  id: string;
  name: string;
  tasksCount: number;
  overdueTaskIds: string[];
  dueSoonTaskIds: string[];
}

export interface ListCampaignGroupsSuccessDto {
  list: CampaignGroupDto[];
}

export interface CreateAssetReviewOldDto {
  /** @format uuid */
  assetId: string;
  active?: boolean | null;
  allowComments?: boolean | null;
  allowDownloads?: boolean | null;
  allowAllVersions?: boolean | null;
  password?: string | null;
}

export interface ReviewAssetPreviewDto {
  /** @format uuid */
  id: string;
  /** @format uuid */
  versionId: string;
  type:
    | 'video'
    | 'audio'
    | 'raster_image'
    | 'vector_image'
    | 'camera_raw_image'
    | 'document'
    | 'spreadsheet'
    | 'presentation'
    | 'archive'
    | 'unknown';
  status:
    | 'analyze_in_progress'
    | 'analyze_error'
    | 'conversion_in_progress'
    | 'conversion_error'
    | 'ready';
  name: string;
  extension: string;
  /** @example https://example.com/zwX5RUfYuqYCvz7w */
  previewUrl?: string | null;
  /** @format date-time */
  previewUrlExpiresAt?: string | null;
  path: AssetPathItemDto[];
  hasActiveAW: boolean;
}

export interface AssetReviewPermissionsDto {
  update: boolean;
  delete: boolean;
}

export interface AssetReviewItemDto {
  /** @format uuid */
  id: string;
  /** @deprecated */
  asset: ReviewAssetPreviewDto;
  assets: ReviewAssetPreviewDto[];
  active: boolean;
  allowComments: boolean;
  allowDownloads: boolean;
  allowAllVersions: boolean;
  password: string | null;
  visitsCounter: number;
  /** @format date-time */
  createdAt: string;
  /** @example https://example.com/7zy2fkPL */
  url: string;
  permissions: AssetReviewPermissionsDto;
}

export interface CreateAssetReviewSuccessDto {
  review: AssetReviewItemDto;
}

export interface CreateAssetReviewAssetDto {
  /** @format uuid */
  id: string;
  /** @format uuid */
  versionId?: string | null;
}

export interface CreateAssetReviewDto {
  assets: CreateAssetReviewAssetDto[];
  name?: string | null;
  active?: boolean | null;
  allowComments?: boolean | null;
  allowDownloads?: boolean | null;
  allowAllVersions?: boolean | null;
  password?: string | null;
}

export interface GetAssetReviewSuccessDto {
  review: AssetReviewItemDto;
}

export interface CountAssetReviewsSuccessDto {
  count: number;
}

export interface AddAssetReviewVisitDto {
  /** @format uuid */
  reviewId: string;
  password?: string | null;
}

export interface AssetReviewErrorReviewDisabledDto {
  errorCode: 'review_disabled';
}

export type AddAssetReviewVisitSuccessDto = object;

export interface UpdateAssetReviewDto {
  /** @format uuid */
  reviewId: string;
  name?: string | null;
  active?: boolean;
  allowComments?: boolean;
  allowDownloads?: boolean;
  allowAllVersions?: boolean;
  password?: string | null;
}

export type UpdateAssetReviewSuccessDto = object;

export type DeleteAssetReviewSuccessDto = object;

export interface AssetReviewAssetItemDto {
  /** @format uuid */
  id: string;
  isFolder: boolean;
  /** @format uuid */
  organizationId: string;
  /** @format uuid */
  workspaceId: string;
  /** @format uuid */
  campaignId: string;
  campaignName: string;
  owner?: MemberDto | null;
  isPrivate: boolean;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  modifiedAt: string;
  /** @format date-time */
  deletedAt?: string | null;
  isFavorite: boolean;
  /** @format date-time */
  openedAt?: string | null;
  path: AssetPathItemDto[];
  folder?: FolderDetailsDto | null;
  asset?: AssetDetailsDto | null;
  demo: boolean;
  /** @format uuid */
  selectedVersionId?: string | null;
}

export interface GetAssetReviewContentSuccessDto {
  /** @format uuid */
  id: string;
  name?: string | null;
  campaignName: string;
  owner?: MemberUserDto | null;
  assets: AssetReviewAssetItemDto[];
  /** @format date-time */
  createdAt: string;
  commentMentions: boolean;
}

export interface GetAssetByReviewSuccessDto {
  asset: AssetItemDto;
  commentMentions: boolean;
}

export interface ShareAssetReviewLinkDTO {
  /** @format uuid */
  assetReviewId: string;
  userEmails: string[];
  groupIds?: string[] | null;
  customMessage?: string;
}

export type ShareAssetReviewSuccessDTO = object;

export interface AddAssetReviewNotificationSubscriptionDto {
  /** @format uuid */
  reviewId: string;
  password?: string | null;
}

export type AddAssetReviewNotificationSubscriptionSuccessDto = object;

export interface SignInAsExternalReviewerDto {
  /** @format email */
  email: string;
  /** @format uuid */
  assetReviewId: string;
}

export interface SignInAsExternalReviewerErrorSignUpRequiredDto {
  errorCode: 'signup_required';
}

export interface NotAWReviewerErrorDto {
  errorCode: 'not_aw_reviewer';
}

export interface ExternalReviewerDto {
  picture: PictureDto;
  /** @format email */
  email: string;
  firstName: string;
  lastName?: string | null;
}

export interface SignInAsExternalReviewerSuccessDto {
  reviewer: ExternalReviewerDto;
  accessToken: string;
  /** @format date-time */
  expiresAt: string;
}

export interface SignUpAsExternalReviewerDto {
  /** @format email */
  email: string;
  firstName: string;
  lastName?: string | null;
  /** @format uuid */
  assetReviewId: string;
}

export interface SignUpAsExternalReviewerSuccessDto {
  reviewer: ExternalReviewerDto;
  accessToken: string;
  /** @format date-time */
  expiresAt: string;
}

export interface SignInAsExternalAWReviewerDto {
  /** @format email */
  email: string;
  /** @format uuid */
  assetVersionId: string;
}

export interface SignUpAsExternalAWReviewerDto {
  /** @format email */
  email: string;
  firstName: string;
  lastName?: string | null;
  /** @format uuid */
  assetVersionId: string;
}

export interface GetExternalReviewerSuccessDto {
  reviewer: ExternalReviewerDto;
}

export interface UpdateExternalReviewerDto {
  firstName: string;
  lastName?: string | null;
}

export type UpdateExternalReviewerSuccessDto = object;

export interface GetExternalReviewerPictureUploadParamsSuccessDto {
  method: 'PUT';
  url: string;
  /** @example {} */
  fields: object;
  headers: {
    'content-type'?: string;
  };
}

export type DeleteExternalReviewerPictureSuccessDto = object;

export interface AWPotentialReviewerDto {
  /** @format email */
  email: string;
  /** @example User Example */
  name?: string | null;
  picture: PictureDto;
  me?: boolean | null;
  hasAccessToAsset: boolean;
  organizationRole: 'organization://admin' | 'organization://workspace-member';
  workspaceRole?:
    | 'workspace://team-lead'
    | 'workspace://member'
    | 'workspace://contributor'
    | null;
}

export interface CreateAWStageMemberDto {
  /** @format email */
  email: string;
  canComment: boolean;
  canSubmitDecision: boolean;
  canUploadNewVersion: boolean;
  canManageAW: boolean;
  canDownload: boolean;
  hasCommentPriority: boolean;
  hasDominatingDecision: boolean;
}

export interface CreateAWStageDto {
  name: string;
  completionCondition: 'all_decisions_submitted' | 'one_decision_submitted';
  /** @format date-time */
  deadline?: string | null;
  members?: CreateAWStageMemberDto[] | null;
}

export interface CreateAWDto {
  /** @format uuid */
  assetId: string;
  /** @format uuid */
  assetVersionId: string;
  lockDecisions: boolean;
  disableNonApprovedDownloads?: boolean;
  newVersionBehavior: 'pause' | 'restart' | 'restart_stage' | 'continue';
  password?: string | null;
  customMessage?: string | null;
  stages?: CreateAWStageDto[] | null;
}

export interface CreateAWAlreadyExistsErrorDto {
  errorCode: 'aw_already_exists';
}

export interface SubscriptionFeatureLimitExceededErrorDto {
  errorCode: 'feature_limit_exceeded';
  /** @format uuid */
  organizationId: string;
  subscriptionType:
    | 'starter_20240711'
    | 'team_20240711'
    | 'free'
    | 'starter'
    | 'team'
    | 'enterprise';
  feature:
    | 'users'
    | 'min_users'
    | 'admins'
    | 'file_size'
    | 'workspaces'
    | 'campaigns'
    | 'task_assignees'
    | 'task_groups'
    | 'aw_stages'
    | 'aw_stage_members';
}

export interface CreateAWSuccessDto {
  asset: AssetItemDto;
}

export interface UpdateAWStageMemberDto {
  /** @format email */
  email: string;
  canComment?: boolean;
  canSubmitDecision?: boolean;
  canUploadNewVersion?: boolean;
  canManageAW?: boolean;
  canDownload?: boolean;
  hasCommentPriority?: boolean;
  hasDominatingDecision?: boolean;
  decisionSkipped?: boolean;
  decision?: 'approved' | 'approved_with_feedback' | 'changes_required' | null;
}

export interface UpdateAWStageMembersDto {
  add?: CreateAWStageMemberDto[];
  update?: UpdateAWStageMemberDto[];
  remove?: string[];
}

export interface UpdateAWStageDto {
  /** @format uuid */
  id: string;
  name?: string;
  completionCondition?: 'all_decisions_submitted' | 'one_decision_submitted';
  /** @format date-time */
  deadline?: string | null;
  members?: UpdateAWStageMembersDto;
}

export interface UpdateAWStagesDto {
  add?: CreateAWStageDto[];
  update?: UpdateAWStageDto[];
  remove?: string[];
}

export interface UpdateAWDto {
  /** @format uuid */
  assetId: string;
  /** @format uuid */
  assetVersionId: string;
  lockDecisions?: boolean;
  disableNonApprovedDownloads?: boolean;
  newVersionBehavior?: 'pause' | 'restart' | 'restart_stage' | 'continue';
  password?: string | null;
  customMessage?: string | null;
  isPaused?: boolean;
  stages?: UpdateAWStagesDto;
}

export interface AWNotExistsErrorDto {
  errorCode: 'aw_not_exists';
}

export interface UpdateAWSuccessDto {
  asset: AssetItemDto;
}

export interface DeleteAWSuccessDto {
  asset: AssetItemDto;
}

export interface RestartAWDto {
  /** @format uuid */
  assetId: string;
  /** @format uuid */
  assetVersionId: string;
  /** @format uuid */
  stageId?: string | null;
}

export interface RestartAWSuccessDto {
  asset: AssetItemDto;
}

export interface SendAWInvitationsDto {
  /** @format uuid */
  assetId: string;
  /** @format uuid */
  assetVersionId: string;
  /** @format uuid */
  stageId: string;
  emails?: string[] | null;
}

export interface ApprovalWorkflowPausedErrorDto {
  errorCode: 'aw_paused';
}

export interface ApprovalWorkflowFinishedErrorDto {
  errorCode: 'aw_finished';
}

export interface NotCurrentAWStageErrorDto {
  errorCode: 'not_current_aw_stage';
}

export type SendAWInvitationsSuccessDto = object;

export interface GetAssetByAWSuccessDto {
  asset: AssetItemDto;
  commentMentions: boolean;
}

export interface CountAssetsWithApprovalSuccessDto {
  count: number;
}

export interface AssetForApprovalDto {
  /** @format uuid */
  id: string;
  /** @format uuid */
  organizationId: string;
  /** @format uuid */
  workspaceId: string;
  /** @format uuid */
  campaignId: string;
  campaignName: string;
  owner?: MemberDto | null;
  isPrivate: boolean;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  modifiedAt: string;
  /** @format date-time */
  deletedAt?: string | null;
  isFavorite: boolean;
  /** @format date-time */
  openedAt?: string | null;
  path: AssetPathItemDto[];
  asset: AssetDetailsDto;
  demo: boolean;
  hasAccess: boolean;
}

export interface SubmitDecisionDto {
  /** @format uuid */
  assetId: string;
  /** @format uuid */
  assetVersionId: string;
  /** @format uuid */
  stageId: string;
  password?: string | null;
  decision: 'approved' | 'approved_with_feedback' | 'changes_required' | null;
}

export interface SubmitDecisionSuccessDto {
  asset: AssetItemDto;
}

export interface AddAWNotificationSubscriptionDto {
  /** @format uuid */
  assetId: string;
  /** @format uuid */
  assetVersionId: string;
  password?: string | null;
}

export type AddAWNotificationSubscriptionSuccessDto = object;

export interface CreateAssetVersionCommentDto {
  /** @format uuid */
  assetVersionId: string;
  text?: string | null;
  rawText?: string | null;
  annotations?: string | null;
  timeCode?: AssetVersionCommentTimecodeDto | null;
  documentPage?: number | null;
  mentions?: string[] | null;
  groupMentions?: string[] | null;
}

export interface AssetVersionCommentDto {
  /** @format uuid */
  id: string;
  /** @format uuid */
  repliedToId?: string | null;
  /** @format uuid */
  topParentId?: string | null;
  task?: CommentTaskDto | null;
  user?: AssetVersionCommentUserDto | null;
  isExternal: boolean;
  priority: boolean;
  text: string;
  timeCode?: AssetVersionCommentTimecodeDto | null;
  documentPage?: number | null;
  annotations?: string | null;
  mentions: AssetVersionCommentMentionDto[];
  groupMentions: AssetVersionCommentGroupMentionDto[];
  likes: AssetVersionCommentUserDto[];
  approvalWorkflow?: AssetVersionCommentAWDto | null;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt?: string | null;
  permissions: AssetVersionCommentPermissionsDto;
}

export interface CreateAssetVersionCommentSuccessDto {
  comment: AssetVersionCommentDto;
}

export interface UpdateAssetVersionCommentMentionsDto {
  add?: string[];
  remove?: string[];
}

export interface UpdateAssetVersionCommentDto {
  /** @format uuid */
  id: string;
  text?: string | null;
  rawText?: string | null;
  annotations?: string | null;
  timeCode?: AssetVersionCommentTimecodeDto | null;
  documentPage?: number | null;
  mentions?: UpdateAssetVersionCommentMentionsDto;
  groupMentions?: UpdateAssetVersionCommentMentionsDto;
}

export interface UpdateAssetVersionCommentSuccessDto {
  comment: AssetVersionCommentDto;
}

export type DeleteAssetVersionCommentSuccessDto = object;

export interface SetAssetVersionCommentLikeDto {
  /** @format uuid */
  id: string;
  value: boolean;
}

export type SetAssetVersionCommentLikeSuccessDto = object;

export interface ReplyAssetVersionCommentDto {
  /** @format uuid */
  replyToId: string;
  replyToRawText?: string | null;
  text?: string | null;
  rawText?: string | null;
  annotations?: string | null;
  timeCode?: AssetVersionCommentTimecodeDto | null;
  documentPage?: number | null;
  mentions?: string[] | null;
  groupMentions?: string[] | null;
}

export interface ReplyAssetVersionCommentSuccessDto {
  comment: AssetVersionCommentDto;
}

export interface GetAssetVersionCommentSuccesDto {
  comment: AssetVersionCommentDto;
}

export interface GetAssetVersionCommentsSuccesDto {
  comments: AssetVersionCommentDto[];
}

export interface CreateAssetReviewCommentDto {
  /** @format uuid */
  assetVersionId: string;
  text?: string | null;
  rawText?: string | null;
  annotations?: string | null;
  timeCode?: AssetVersionCommentTimecodeDto | null;
  documentPage?: number | null;
  mentions?: string[] | null;
  groupMentions?: string[] | null;
  /** @format uuid */
  assetReviewId: string;
  assetReviewPassword?: string | null;
}

export interface UpdateAssetReviewCommentDto {
  /** @format uuid */
  id: string;
  text?: string | null;
  rawText?: string | null;
  annotations?: string | null;
  timeCode?: AssetVersionCommentTimecodeDto | null;
  documentPage?: number | null;
  mentions?: UpdateAssetVersionCommentMentionsDto;
  groupMentions?: UpdateAssetVersionCommentMentionsDto;
  /** @format uuid */
  assetReviewId: string;
  assetReviewPassword?: string | null;
}

export interface SetAssetReviewCommentLikeDto {
  /** @format uuid */
  id: string;
  value: boolean;
  /** @format uuid */
  assetReviewId: string;
  assetReviewPassword?: string | null;
}

export interface ReplyAssetReviewCommentDto {
  /** @format uuid */
  replyToId: string;
  replyToRawText?: string | null;
  text?: string | null;
  rawText?: string | null;
  annotations?: string | null;
  timeCode?: AssetVersionCommentTimecodeDto | null;
  documentPage?: number | null;
  mentions?: string[] | null;
  groupMentions?: string[] | null;
  /** @format uuid */
  assetReviewId: string;
  assetReviewPassword?: string | null;
}

export interface CreateAWCommentDto {
  /** @format uuid */
  assetVersionId: string;
  text?: string | null;
  rawText?: string | null;
  annotations?: string | null;
  timeCode?: AssetVersionCommentTimecodeDto | null;
  documentPage?: number | null;
  mentions?: string[] | null;
  groupMentions?: string[] | null;
  approvalWorkflowPassword?: string | null;
}

export interface UpdateAWCommentDto {
  /** @format uuid */
  id: string;
  text?: string | null;
  rawText?: string | null;
  annotations?: string | null;
  timeCode?: AssetVersionCommentTimecodeDto | null;
  documentPage?: number | null;
  mentions?: UpdateAssetVersionCommentMentionsDto;
  groupMentions?: UpdateAssetVersionCommentMentionsDto;
  /** @format uuid */
  assetVersionId: string;
  approvalWorkflowPassword?: string | null;
}

export interface SetAWCommentLikeDto {
  /** @format uuid */
  id: string;
  value: boolean;
  /** @format uuid */
  assetVersionId: string;
  approvalWorkflowPassword?: string | null;
}

export interface ReplyAWCommentDto {
  /** @format uuid */
  replyToId: string;
  replyToRawText?: string | null;
  text?: string | null;
  rawText?: string | null;
  annotations?: string | null;
  timeCode?: AssetVersionCommentTimecodeDto | null;
  documentPage?: number | null;
  mentions?: string[] | null;
  groupMentions?: string[] | null;
  /** @format uuid */
  assetVersionId: string;
  approvalWorkflowPassword?: string | null;
}

export interface UpdateSubscriptionDto {
  /** @format uuid */
  organizationId: string;
  type: 'starter' | 'team';
  period: 'monthly' | 'annually';
  state?: string;
  trialToken?: string | null;
}

export interface FreeSubscriptionDTO {
  planId: 'free';
  type: 'free';
  name: string;
}

export interface PaidSubscriptionDTO {
  /** @example starter */
  planId: string | 'starter_20240711' | 'team_20240711' | 'starter' | 'team';
  type:
    | 'starter_20240711'
    | 'team_20240711'
    | 'starter'
    | 'team'
    | 'enterprise';
  name: string;
  period: 'monthly' | 'annually';
  status: 'trialing' | 'active' | 'past_due' | 'unpaid';
  /** @format date-time */
  currentPeriodStart: string;
  /** @format date-time */
  currentPeriodEnd: string;
  /** @format date-time */
  cancelAt?: string | null;
}

export interface UpdateSubscriptionSuccessDTO {
  subscription: FreeSubscriptionDTO | PaidSubscriptionDTO;
  paymentUrl?: string | null;
}

export interface GetSubscriptionSuccessDTO {
  subscription: FreeSubscriptionDTO | PaidSubscriptionDTO;
}

export interface ReplaceCardDto {
  /** @format uuid */
  organizationId: string;
  state?: string;
}

export interface ReplaceCardSuccessDto {
  url?: string | null;
}

export interface CardDto {
  last4Digits: string;
  type: string;
  expirationMonth: number;
  expirationYear: number;
}

export interface GetCardSuccessDto {
  card: CardDto | null;
}

export type DeleteCardSuccessDto = object;

export interface CardCreatedDto {
  /** @format uuid */
  organizationId: string;
}

export interface CardCreatedSuccessDto {
  card: CardDto | null;
}

export interface SubscriptionCreatedDto {
  /** @format uuid */
  organizationId: string;
}

export interface SubscriptionCreatedSuccessDto {
  subscription: FreeSubscriptionDTO | PaidSubscriptionDTO;
}

export interface InvoiceItemDto {
  id: string;
  number: string;
  /** @format date-time */
  date: string;
  amount: number;
  status: 'open' | 'paid' | 'void' | 'uncollectible';
}

export interface GetInvoiceDownloadUrlSuccessDto {
  url?: string | null;
}

export interface GetSeatsUsageSuccessDto {
  seatsCount: number;
}

export interface GetAssetsUsageSuccessDto {
  /** Summary assets size in bytes */
  assetsSummarySize: number;
}

export interface InitAttachmentUploadDto {
  type: 'campaign-attachment' | 'task-attachment';
  /** @format uuid */
  targetId: string;
  fileName: string;
  fileSize: number;
}

export interface InitAttachmentUploadSuccessDto {
  /** @format uuid */
  attachmentId: string;
  uploadId: string;
  key: string;
}

export interface GetAttachmentSuccessDto {
  attachment: AttachmentDto;
}

export interface ListAttachmentsSuccessDto {
  attachments: AttachmentDto[];
}

export type DeleteAttachmentSuccessDto = object;

export interface GetSlackConnectUrlSuccessDto {
  /** @example https://example.com */
  url: string;
}

export interface GetAsanaConnectUrlSuccessDto {
  /** @example https://example.com */
  url: string;
}

export interface AsanaOauthVerifyDto {
  code: string;
  state: string;
}

export interface AsanaOauthVerifySuccessDto {
  state: string;
}

export interface GetMondayConnectUrlSuccessDto {
  /** @example https://example.com */
  url: string;
}

export interface MondayOauthVerifyDto {
  code: string;
  state: string;
}

export interface MondayOauthVerifySuccessDto {
  state: string;
  /** @example https://example.com */
  backToUrl: string;
}

export interface ProfileControllerGetAvailableProvidersParams {
  /** @format email */
  email: string;
}

export interface ProfileControllerGetPictureUploadParamsParams {
  mimeType: 'image/jpeg' | 'image/png' | 'image/webp';
  /**
   * @min 1
   * @max 10485760
   */
  size: number;
}

export interface ProfileControllerGetNotificationPreferencesParams {
  /** @format uuid */
  workspaceId: string;
}

export interface OrganizationControllerCheckSubdomainParams {
  subdomain: string;
}

export interface OrganizationControllerSuggestedSubdomainParams {
  organizationName: string;
}

export interface OrganizationControllerGetOrganizationParams {
  /** @format uuid */
  organizationId: string;
}

export interface OrganizationControllerDeleteOrganizationParams {
  /** @format uuid */
  organizationId: string;
}

export interface OrganizationControllerCountMembersParams {
  /** @format uuid */
  organizationId: string;
  searchQuery?: string;
}

export interface OrganizationControllerListMembersParams {
  /** @format uuid */
  organizationId: string;
  searchQuery?: string;
  orderBy?: (
    | 'createdAt:ASC'
    | 'createdAt:DESC'
    | 'role:ASC'
    | 'role:DESC'
    | 'isActive:ASC'
    | 'isActive:DESC'
    | 'workspacesCount:ASC'
    | 'workspacesCount:DESC'
  )[];
  /**
   * @min 1
   * @max 100
   */
  limit?: number;
  after?: string | null;
  before?: string | null;
  inclusive?: boolean | null;
}

export interface OrganizationControllerDeleteOrganizationMembersParams {
  /** @format uuid */
  organizationId: string;
  /** @format uuid */
  memberId: string[];
}

export interface OrganizationControllerListOrganizationWorkpsacesParams {
  /** @format uuid */
  organizationId: string;
}

export interface OrganizationControllerGetOrganizationWorkpsacesParams {
  /** @format uuid */
  organizationId: string;
  /** @format uuid */
  workspaceId: string;
}

export interface WorkspaceControllerGetPictureUploadParamsParams {
  /** @format uuid */
  workspaceId: string;
  mimeType: 'image/jpeg' | 'image/png' | 'image/webp';
  /**
   * @min 1
   * @max 10485760
   */
  size: number;
}

export interface WorkspaceControllerDeltePictureParams {
  /** @format uuid */
  workspaceId: string;
}

export interface WorkspaceControllerDeleteWorkspaceParams {
  /** @format uuid */
  workspaceId: string;
}

export interface WorkspaceControllerGetWorkspaceParams {
  /** @format uuid */
  workspaceId: string;
}

export interface WorkspaceControllerFindMembersToInviteParams {
  /** @format uuid */
  targetId: string;
  searchQuery?: string;
  /**
   * @min 1
   * @max 100
   */
  limit?: number;
  after?: string | null;
  before?: string | null;
  inclusive?: boolean | null;
}

export interface WorkspaceControllerFindMembersToInviteInGroupParams {
  /** @format uuid */
  workspaceId: string;
  /** @format uuid */
  groupId?: string | null;
  searchQuery?: string;
  /**
   * @min 1
   * @max 100
   */
  limit?: number;
  after?: string | null;
  before?: string | null;
  inclusive?: boolean | null;
}

export interface WorkspaceControllerDeletePublicInvitationUrlParams {
  /** @format uuid */
  workspaceId: string;
}

export interface WorkspaceControllerCountMembersParams {
  /** @format uuid */
  workspaceId: string;
  searchQuery?: string;
  /** @format uuid */
  groupId?: string;
}

export interface WorkspaceControllerListMembersParams {
  /** @format uuid */
  workspaceId: string;
  searchQuery?: string;
  /** @format uuid */
  groupId?: string;
  orderBy?: (
    | 'createdAt:ASC'
    | 'createdAt:DESC'
    | 'role:ASC'
    | 'role:DESC'
    | 'isActive:ASC'
    | 'isActive:DESC'
  )[];
  /**
   * @min 1
   * @max 100
   */
  limit?: number;
  after?: string | null;
  before?: string | null;
  inclusive?: boolean | null;
}

export interface WorkspaceControllerDeleteWorkspaceMembersParams {
  /** @format uuid */
  workspaceId: string;
  /** @format uuid */
  memberId: string[];
}

export interface WorkspaceControllerGetWorkspaceCountersParams {
  /** @format uuid */
  workspaceId: string;
}

export interface WorkspaceControllerGetWorkspaceObjectivesParams {
  /** @format uuid */
  workspaceId: string;
}

export interface WorkspaceControllerSearchWorkspaceItemsParams {
  /** @format uuid */
  workspaceId: string;
  searchQuery: string;
  /**
   * @min 1
   * @max 100
   */
  limit?: number;
}

export interface WorkspaceControllerSearchAssetCommentsParams {
  /** @format uuid */
  workspaceId: string;
  /** @format uuid */
  campaignId?: string | null;
  /** @format uuid */
  folderId?: string | null;
  /** @format uuid */
  assetId?: string | null;
  searchQuery?: string | null;
  /**
   * @min 1
   * @max 100
   */
  limit?: number;
  after?: string | null;
  before?: string | null;
  inclusive?: boolean | null;
}

export interface WorkspaceControllerSearchAssetCommentsCountParams {
  /** @format uuid */
  workspaceId: string;
  searchQuery: string;
}

export interface WorkspaceControllerCountMemberGroupsParams {
  /** @format uuid */
  workspaceId: string;
  searchQuery?: string;
}

export interface WorkspaceControllerListMemberGroupsParams {
  /** @format uuid */
  workspaceId: string;
  searchQuery?: string;
  /**
   * @min 1
   * @max 100
   */
  limit?: number;
  after?: string | null;
  before?: string | null;
  inclusive?: boolean | null;
}

export interface WorkspaceControllerGetWorkspaceMemberGroupParams {
  /** @format uuid */
  workspaceId: string;
  /** @format uuid */
  groupId: string;
}

export interface WorkspaceControllerDeleteWorkspaceMemberGroupParams {
  /** @format uuid */
  workspaceId: string;
  /** @format uuid */
  groupId: string;
}

export interface WorkspaceControllerDeleteWorkspaceMemberGroupMembersParams {
  /** @format uuid */
  workspaceId: string;
  /** @format uuid */
  groupId: string;
  /** @format uuid */
  memberId: string[];
}

export interface CampaignControllerGetCampaignParams {
  /** @format uuid */
  campaignId: string;
  isDeleted?: boolean;
}

export interface CampaignControllerCountCampaignsParams {
  /** @format uuid */
  workspaceId: string;
  searchQuery?: string;
  isPrivate?: boolean | null;
  isFavorite?: boolean | null;
  own?: boolean | null;
  isArchived?: boolean;
  isDeleted?: boolean;
  isDemo?: boolean | null;
}

export interface CampaignControllerListCampaingsParams {
  /** @format uuid */
  workspaceId: string;
  searchQuery?: string;
  isPrivate?: boolean | null;
  isFavorite?: boolean | null;
  own?: boolean | null;
  isArchived?: boolean;
  isDeleted?: boolean;
  isDemo?: boolean | null;
  orderBy?: (
    | 'createdAt:ASC'
    | 'createdAt:DESC'
    | 'lastEventTime:ASC'
    | 'lastEventTime:DESC'
    | 'archivedAt:ASC'
    | 'archivedAt:DESC'
    | 'deletedAt:ASC'
    | 'deletedAt:DESC'
    | 'order:ASC'
    | 'order:DESC'
  )[];
  /**
   * @min 1
   * @max 100
   */
  limit?: number;
  after?: string | null;
  before?: string | null;
  inclusive?: boolean | null;
}

export interface CampaignControllerGetSimpleCampaignsListParams {
  /** @format uuid */
  workspaceId: string;
}

export interface CampaignControllerGetSimpleCampaignsListWithFoldersParams {
  /** @format uuid */
  workspaceId: string;
}

export interface CampaignControllerDeleteCampaignParams {
  /** @format uuid */
  campaignId: string;
  permanent?: boolean | null;
}

export interface CampaignControllerFindMembersToInviteParams {
  /** @format uuid */
  targetId: string;
  searchQuery?: string;
  /**
   * @min 1
   * @max 100
   */
  limit?: number;
  after?: string | null;
  before?: string | null;
  inclusive?: boolean | null;
}

export interface CampaignControllerFindMemberGroupsToInviteParams {
  /** @format uuid */
  targetId: string;
  searchQuery?: string;
  /**
   * @min 1
   * @max 100
   */
  limit?: number;
  after?: string | null;
  before?: string | null;
  inclusive?: boolean | null;
}

export interface CampaignControllerCountMembersParams {
  /** @format uuid */
  campaignId: string;
  searchQuery?: string;
  onlyThatHaveAccessToPrivate?: boolean;
}

export interface CampaignControllerListMembersParams {
  /** @format uuid */
  campaignId: string;
  searchQuery?: string;
  onlyThatHaveAccessToPrivate?: boolean;
  orderBy?: ('default:ASC' | 'default:DESC' | 'role:ASC' | 'role:DESC')[];
  /**
   * @min 1
   * @max 100
   */
  limit?: number;
  after?: string | null;
  before?: string | null;
  inclusive?: boolean | null;
}

export interface CampaignControllerCountMemberGroupsParams {
  /** @format uuid */
  campaignId: string;
  searchQuery?: string;
  onlyThatHaveAccessToPrivate?: boolean;
}

export interface CampaignControllerListMemberGroupsParams {
  /** @format uuid */
  campaignId: string;
  searchQuery?: string;
  onlyThatHaveAccessToPrivate?: boolean;
  /**
   * @min 1
   * @max 100
   */
  limit?: number;
  after?: string | null;
  before?: string | null;
  inclusive?: boolean | null;
}

export interface CampaignControllerDeleteMembersParams {
  /** @format uuid */
  campaignId: string;
  /** @format uuid */
  memberId?: string[];
  /** @format uuid */
  groupId?: string[];
}

export interface CampaignControllerGetTimelineParams {
  /** @format uuid */
  campaignId: string;
  /**
   * @min 1
   * @max 100
   */
  limit?: number;
  after?: string | null;
  before?: string | null;
  inclusive?: boolean | null;
}

export interface CampaignControllerGetTaskFieldParams {
  /** @format uuid */
  campaignId: string;
  /** @format uuid */
  taskFieldId: string;
}

export interface CampaignControllerListTaskFieldsParams {
  /** @format uuid */
  campaignId: string;
}

export interface CampaignControllerDeleteTaskFieldParams {
  /** @format uuid */
  taskFieldId: string;
  /** @format uuid */
  campaignId: string;
}

export interface CampaignControllerGetAwDefaultSettingsParams {
  /** @format uuid */
  campaignId: string;
}

export interface ImageControllerDownloadImageParams {
  alpha?: boolean;
  type?: 'jpeg' | 'png' | 'webp';
  width?: number;
  height?: number;
  token: string;
}

export interface AssetControllerDeleteFolderParams {
  /** @format uuid */
  folderId: string;
  permanent?: boolean | null;
}

export interface AssetControllerGetSimpleFoldersListParams {
  /** @format uuid */
  workspaceId: string;
  /** @format uuid */
  campaignId: string;
  /** @format uuid */
  parentId?: string | null;
  flatten?: boolean;
}

export interface AssetControllerFindMembersToInviteInFolderParams {
  /** @format uuid */
  targetId: string;
  searchQuery?: string;
  /**
   * @min 1
   * @max 100
   */
  limit?: number;
  after?: string | null;
  before?: string | null;
  inclusive?: boolean | null;
}

export interface AssetControllerFindMemberGroupsToInviteInFolderParams {
  /** @format uuid */
  targetId: string;
  searchQuery?: string;
  /**
   * @min 1
   * @max 100
   */
  limit?: number;
  after?: string | null;
  before?: string | null;
  inclusive?: boolean | null;
}

export interface AssetControllerCountFolderMembersParams {
  /** @format uuid */
  folderId: string;
  searchQuery?: string;
}

export interface AssetControllerListFolderMembersParams {
  /** @format uuid */
  folderId: string;
  searchQuery?: string;
  /**
   * @min 1
   * @max 100
   */
  limit?: number;
  after?: string | null;
  before?: string | null;
  inclusive?: boolean | null;
}

export interface AssetControllerCountFolderMemberGroupsParams {
  /** @format uuid */
  folderId: string;
  searchQuery?: string;
}

export interface AssetControllerListFolderMemberGroupsParams {
  /** @format uuid */
  folderId: string;
  searchQuery?: string;
  /**
   * @min 1
   * @max 100
   */
  limit?: number;
  after?: string | null;
  before?: string | null;
  inclusive?: boolean | null;
}

export interface AssetControllerDeleteFolderMembersParams {
  /** @format uuid */
  folderId: string;
  /** @format uuid */
  memberId?: string[];
  /** @format uuid */
  groupId?: string[];
}

export interface AssetControllerGetAssetParams {
  /** @format uuid */
  assetId: string;
  isFolder?: boolean;
  isDeleted?: boolean;
}

export interface AssetControllerCountAssetsParams {
  /** @format uuid */
  workspaceId: string;
  /** @format uuid */
  campaignId?: string | null;
  /** @format uuid */
  parentId?: string | null;
  flatten?: boolean;
  isFolder?: boolean;
  searchQuery?: string;
  isPrivate?: boolean | null;
  isFavorite?: boolean | null;
  isOpened?: boolean | null;
  own?: boolean | null;
  isDeleted?: boolean;
  isDemo?: boolean | null;
  hasApproval?: boolean | null;
}

export interface AssetControllerListAssetsParams {
  /** @format uuid */
  workspaceId: string;
  /** @format uuid */
  campaignId?: string | null;
  /** @format uuid */
  parentId?: string | null;
  flatten?: boolean;
  isFolder?: boolean;
  searchQuery?: string;
  isPrivate?: boolean | null;
  isFavorite?: boolean | null;
  isOpened?: boolean | null;
  own?: boolean | null;
  isDeleted?: boolean;
  isDemo?: boolean | null;
  hasApproval?: boolean | null;
  orderBy?: (
    | 'createdAt:ASC'
    | 'createdAt:DESC'
    | 'modifiedAt:ASC'
    | 'modifiedAt:DESC'
    | 'deletedAt:ASC'
    | 'deletedAt:DESC'
    | 'openedAt:ASC'
    | 'openedAt:DESC'
    | 'order:ASC'
    | 'order:DESC'
  )[];
  /**
   * @min 1
   * @max 100
   */
  limit?: number;
  after?: string | null;
  before?: string | null;
  inclusive?: boolean | null;
}

export interface AssetControllerGetAssetsDownloadListParams {
  /** @format uuid */
  workspaceId: string;
  /** @format uuid */
  campaignId: string;
  /** @format uuid */
  parentId?: string | null;
}

export interface AssetControllerMoveAssetToFolderParams {
  /** @format uuid */
  assetId: string;
  /** @format uuid */
  folderId?: string | null;
}

export interface AssetControllerDeleteAssetParams {
  /** @format uuid */
  assetId: string;
  permanent?: boolean | null;
}

export interface AssetControllerRemoveAssetVersionFromStackParams {
  /** @format uuid */
  assetId: string;
  /** @format uuid */
  assetVersionId: string;
}

export interface AssetControllerAddAssetToStackParams {
  /** @format uuid */
  assetId: string;
  /** @format uuid */
  toAssetId: string;
}

export interface AssetControllerCopyAssetVersionParams {
  /** @format uuid */
  assetId: string;
  /** @format uuid */
  versionId: string;
}

export interface MultipartUploadControllerAbortUploadParams {
  uploadId: string;
  key: string;
}

export interface MultipartUploadControllerListPartsParams {
  uploadId: string;
  key: string;
}

export interface MultipartUploadControllerGetPartsUploadPAramsParams {
  uploadId: string;
  key: string;
  partNumber: number[];
}

export interface TaskBoardControllerDeleteTaskBoardParams {
  /** @format uuid */
  campaignId: string;
  /** @example no_assigned */
  id: string;
  deleteAllTasks: boolean;
}

export interface TaskBoardControllerGetTaskBoardParams {
  /**
   * Board id or one of ["no_assigned","archived"]
   * @example no_assigned
   */
  id: string;
  /** @format uuid */
  campaignId: string;
  searchQuery?: string;
  assigneeIds?: string[];
  statuses?: ('todo' | 'in_progress' | 'stuck' | 'done')[];
  /** @format date-time */
  dueDateFrom?: string;
  /** @format date-time */
  dueDateTo?: string;
  own?: boolean;
  assignedToMe?: boolean;
  isDeleted?: boolean;
}

export interface TaskBoardControllerListTaskBoardsParams {
  /** @format uuid */
  campaignId: string;
  searchQuery?: string;
  assigneeIds?: string[];
  statuses?: ('todo' | 'in_progress' | 'stuck' | 'done')[];
  /** @format date-time */
  dueDateFrom?: string;
  /** @format date-time */
  dueDateTo?: string;
  own?: boolean;
  assignedToMe?: boolean;
  isDeleted?: boolean;
}

export interface TaskControllerDeleteTaskParams {
  /** @format uuid */
  id: string;
  permanent?: boolean | null;
}

export interface TaskControllerGetTaskParams {
  /** @format uuid */
  id: string;
}

export interface TaskControllerCountTasksParams {
  /** @format uuid */
  workspaceId: string;
  /** @format uuid */
  assetVersionId?: string;
  searchQuery?: string;
  campaignIds?: string[];
  /** @example ["no_assigned","archived","3fa85f64-5717-4562-b3fc-2c963f66afa6"] */
  boardIds?: string[];
  assigneeIds?: string[];
  ownerIds?: string[];
  statuses?: ('todo' | 'in_progress' | 'stuck' | 'done')[];
  /** @format date-time */
  dueDateFrom?: string;
  /** @format date-time */
  dueDateTo?: string;
  own?: boolean;
  assignedToMe?: boolean;
  /** Own or assigned to me */
  my?: boolean;
  isDeleted?: boolean;
  allowArchived?: boolean;
}

export interface TaskControllerListTasksParams {
  /** @format uuid */
  workspaceId: string;
  /** @format uuid */
  assetVersionId?: string;
  searchQuery?: string;
  campaignIds?: string[];
  /** @example ["no_assigned","archived","3fa85f64-5717-4562-b3fc-2c963f66afa6"] */
  boardIds?: string[];
  assigneeIds?: string[];
  ownerIds?: string[];
  statuses?: ('todo' | 'in_progress' | 'stuck' | 'done')[];
  /** @format date-time */
  dueDateFrom?: string;
  /** @format date-time */
  dueDateTo?: string;
  own?: boolean;
  assignedToMe?: boolean;
  /** Own or assigned to me */
  my?: boolean;
  isDeleted?: boolean;
  allowArchived?: boolean;
  orderBy?: (
    | 'createdAt:ASC'
    | 'createdAt:DESC'
    | 'lastEventTime:ASC'
    | 'lastEventTime:DESC'
    | 'order:ASC'
    | 'order:DESC'
    | 'deletedAt:ASC'
    | 'deletedAt:DESC'
    | 'status:ASC'
    | 'status:DESC'
    | 'dueDate:ASC'
    | 'dueDate:DESC'
    | 'dashboard:ASC'
    | 'dashboard:DESC'
  )[];
  /**
   * @min 1
   * @max 100
   */
  limit?: number;
  after?: string | null;
  before?: string | null;
  inclusive?: boolean | null;
}

export interface TaskControllerDeleteTaskCommentParams {
  /** @format uuid */
  id: string;
}

export interface TaskControllerGetTaskCommentParams {
  /** @format uuid */
  commentId: string;
  /** @format uuid */
  taskId: string;
}

export interface TaskControllerGetTaskEventParams {
  /** @format uuid */
  id: string;
  /** @format uuid */
  taskId: string;
}

export interface TaskControllerGetTaskEventsParams {
  /** @format uuid */
  taskId: string;
  /**
   * @min 1
   * @max 100
   */
  limit?: number;
  after?: string | null;
  before?: string | null;
  inclusive?: boolean | null;
}

export interface TaskControllerListPotentialAssigneesParams {
  /** @format uuid */
  workspaceId: string;
  /** @format uuid */
  campaignId?: string | null;
  /** @format uuid */
  assetId?: string | null;
  searchQuery?: string;
  /**
   * @min 1
   * @max 100
   */
  limit?: number;
  after?: string | null;
  before?: string | null;
  inclusive?: boolean | null;
}

export interface TaskControllerListTaskBoardGroupsParams {
  /** @format uuid */
  workspaceId: string;
  /** @format uuid */
  assetVersionId?: string;
  searchQuery?: string;
  campaignIds?: string[];
  assigneeIds?: string[];
  ownerIds?: string[];
  statuses?: ('todo' | 'in_progress' | 'stuck' | 'done')[];
  /** @format date-time */
  dueDateFrom?: string;
  /** @format date-time */
  dueDateTo?: string;
  own?: boolean;
  assignedToMe?: boolean;
  /** Own or assigned to me */
  my?: boolean;
  isDeleted?: boolean;
}

export interface TaskControllerListCampaignGroupsParams {
  /** @format uuid */
  workspaceId: string;
  /** @format uuid */
  assetVersionId?: string;
  searchQuery?: string;
  campaignIds?: string[];
  assigneeIds?: string[];
  ownerIds?: string[];
  statuses?: ('todo' | 'in_progress' | 'stuck' | 'done')[];
  /** @format date-time */
  dueDateFrom?: string;
  /** @format date-time */
  dueDateTo?: string;
  own?: boolean;
  assignedToMe?: boolean;
  /** Own or assigned to me */
  my?: boolean;
  isDeleted?: boolean;
}

export interface AssetReviewControllerGetAssetReviewParams {
  /** @format uuid */
  reviewId: string;
}

export interface AssetReviewControllerCountAssetReviewsParams {
  /** @format uuid */
  campaignId: string;
}

export interface AssetReviewControllerListAssetReviewsParams {
  /** @format uuid */
  campaignId: string;
  orderBy?: (
    | 'createdAt:ASC'
    | 'createdAt:DESC'
    | 'visitsCounter:ASC'
    | 'visitsCounter:DESC'
    | 'active:ASC'
    | 'active:DESC'
    | 'assetName:ASC'
    | 'assetName:DESC'
  )[];
  /**
   * @min 1
   * @max 100
   */
  limit?: number;
  after?: string | null;
  before?: string | null;
  inclusive?: boolean | null;
}

export interface AssetReviewControllerDeleteAssetReviewParams {
  /** @format uuid */
  reviewId: string;
}

export interface AssetReviewControllerGetReviewContentParams {
  /** @format uuid */
  reviewId: string;
  password?: string | null;
}

export interface AssetReviewControllerGetAssetByReviewParams {
  /** @format uuid */
  reviewId: string;
  /** @format uuid */
  assetId: string;
  password?: string | null;
}

export interface ExternalReviewerControllerGetReviewerPictureUploadParamsParams {
  mimeType: 'image/jpeg' | 'image/png' | 'image/webp';
  /**
   * @min 1
   * @max 10485760
   */
  size: number;
}

export interface ApprovalWorkflowControllerListPotentialReviewersParams {
  /** @format uuid */
  assetId: string;
  exclude?: string[];
  searchQuery?: string;
  /**
   * @min 1
   * @max 100
   */
  limit?: number;
  after?: string | null;
  before?: string | null;
  inclusive?: boolean | null;
}

export interface ApprovalWorkflowControllerDeleteParams {
  /** @format uuid */
  assetId: string;
  /** @format uuid */
  assetVersionId: string;
}

export interface ApprovalWorkflowControllerGetAssetByAwParams {
  /** @format uuid */
  assetVersionId: string;
  password?: string | null;
}

export interface ApprovalWorkflowControllerCountAssetsWithApprovalParams {
  /** @format uuid */
  workspaceId: string;
  /** @format uuid */
  campaignId?: string | null;
  searchQuery?: string;
  needMyDecision?: boolean;
  own?: boolean;
  /** Own or needs my decision */
  my?: boolean;
}

export interface ApprovalWorkflowControllerListAssetsWithApprovalParams {
  /** @format uuid */
  workspaceId: string;
  /** @format uuid */
  campaignId?: string | null;
  searchQuery?: string;
  needMyDecision?: boolean;
  own?: boolean;
  /** Own or needs my decision */
  my?: boolean;
  /**
   * @min 1
   * @max 100
   */
  limit?: number;
  after?: string | null;
  before?: string | null;
  inclusive?: boolean | null;
}

export interface AssetInternalCommentsControllerDeleteAssetCommentParams {
  /** @format uuid */
  id: string;
}

export interface AssetInternalCommentsControllerGetAssetCommentParams {
  /** @format uuid */
  id: string;
}

export interface AssetInternalCommentsControllerGetAssetCommentsParams {
  /** @format uuid */
  assetVersionId: string;
}

export interface AssetInternalCommentsControllerListCommentPotentialMentionsParams {
  /** @format uuid */
  assetVersionId: string;
  searchQuery?: string;
  /**
   * @min 1
   * @max 100
   */
  limit?: number;
  after?: string | null;
  before?: string | null;
  inclusive?: boolean | null;
}

export interface AssetInternalCommentsControllerListCommentPotentialGroupMentionsParams {
  /** @format uuid */
  assetVersionId: string;
  searchQuery?: string;
  /**
   * @min 1
   * @max 100
   */
  limit?: number;
  after?: string | null;
  before?: string | null;
  inclusive?: boolean | null;
}

export interface AssetReviewCommentsControllerDeleteAssetCommentParams {
  /** @format uuid */
  id: string;
  /** @format uuid */
  assetReviewId: string;
  assetReviewPassword?: string | null;
}

export interface AssetReviewCommentsControllerGetAssetCommentParams {
  /** @format uuid */
  id: string;
  /** @format uuid */
  assetReviewId: string;
  assetReviewPassword?: string | null;
}

export interface AssetReviewCommentsControllerGetAssetCommentsParams {
  /** @format uuid */
  assetVersionId: string;
  /** @format uuid */
  assetReviewId: string;
  assetReviewPassword?: string | null;
}

export interface AssetReviewCommentsControllerListCommentPotentialMentionsParams {
  /** @format uuid */
  assetVersionId: string;
  searchQuery?: string;
  /**
   * @min 1
   * @max 100
   */
  limit?: number;
  after?: string | null;
  before?: string | null;
  inclusive?: boolean | null;
  /** @format uuid */
  assetReviewId: string;
  assetReviewPassword?: string | null;
}

export interface AssetReviewCommentsControllerListCommentPotentialGroupMentionsParams {
  /** @format uuid */
  assetVersionId: string;
  searchQuery?: string;
  /**
   * @min 1
   * @max 100
   */
  limit?: number;
  after?: string | null;
  before?: string | null;
  inclusive?: boolean | null;
  /** @format uuid */
  assetReviewId: string;
  assetReviewPassword?: string | null;
}

export interface AssetAwCommentsControllerDeleteAssetCommentParams {
  /** @format uuid */
  id: string;
  /** @format uuid */
  assetVersionId: string;
  approvalWorkflowPassword?: string | null;
}

export interface AssetAwCommentsControllerGetAssetCommentParams {
  /** @format uuid */
  id: string;
  /** @format uuid */
  assetVersionId: string;
  approvalWorkflowPassword?: string | null;
}

export interface AssetAwCommentsControllerGetAssetCommentsParams {
  /** @format uuid */
  assetVersionId: string;
  approvalWorkflowPassword?: string | null;
}

export interface AssetAwCommentsControllerListCommentPotentialMentionsParams {
  /** @format uuid */
  assetVersionId: string;
  searchQuery?: string;
  /**
   * @min 1
   * @max 100
   */
  limit?: number;
  after?: string | null;
  before?: string | null;
  inclusive?: boolean | null;
  approvalWorkflowPassword?: string | null;
}

export interface AssetAwCommentsControllerListCommentPotentialGroupMentionsParams {
  /** @format uuid */
  assetVersionId: string;
  searchQuery?: string;
  /**
   * @min 1
   * @max 100
   */
  limit?: number;
  after?: string | null;
  before?: string | null;
  inclusive?: boolean | null;
  approvalWorkflowPassword?: string | null;
}

export interface SubscriptionControllerGetSubscriptionParams {
  /** @format uuid */
  organizationId: string;
}

export interface SubscriptionControllerCancelSubscriptionParams {
  /** @format uuid */
  organizationId: string;
}

export interface SubscriptionControllerGetCardParams {
  /** @format uuid */
  organizationId: string;
}

export interface SubscriptionControllerDeleteCardParams {
  /** @format uuid */
  organizationId: string;
}

export interface SubscriptionControllerGetInvoicesListParams {
  /** @format uuid */
  organizationId: string;
  /**
   * @min 1
   * @max 100
   */
  limit?: number;
  after?: string | null;
  before?: string | null;
  inclusive?: boolean | null;
}

export interface SubscriptionControllerGetInvoiceDownloadUrlParams {
  /** @format uuid */
  invoiceId: string;
}

export interface SubscriptionControllerGetSeatsUsageParams {
  /** @format uuid */
  organizationId: string;
}

export interface SubscriptionControllerGetAssetsUsageParams {
  /** @format uuid */
  organizationId: string;
}

export interface AttachmentControllerGetAttachmentParams {
  /** @format uuid */
  id: string;
}

export interface AttachmentControllerDeleteAttachmentParams {
  /** @format uuid */
  id: string;
}

export interface AttachmentControllerGetAttachmentsParams {
  type: 'campaign-attachment' | 'task-attachment';
  /** @format uuid */
  targetId: string;
}

export interface SlackControllerInstallSlackParams {
  state?: string;
}

export interface AsanaControllerInstallParams {
  asanaWorkspaceId?: string;
  state?: string;
}

export interface MondayControllerInstallParams {
  token: string;
  state?: string;
}

import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults,
  ResponseType
} from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded'
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || ''
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[
            method.toLowerCase() as keyof HeadersDefaults
          ]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {})
      }
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: Iterable<any> =
        property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(
          key,
          isFileType ? formItem : this.stringifyFormItem(formItem)
        );
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (
      type === ContentType.FormData &&
      body &&
      body !== null &&
      typeof body === 'object'
    ) {
      body = this.createFormData(body as Record<string, unknown>);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData
          ? { 'Content-Type': type }
          : {})
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path
    });
  };
}

/**
 * @title Application API
 * @version 1.0
 * @contact
 *
 * The application API description
 */
export class Api<
  SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
  profile = {
    /**
     * No description
     *
     * @tags profile
     * @name ProfileControllerResendVerificationMail
     * @request POST:/profile/email/resend-verification
     * @secure
     */
    profileControllerResendVerificationMail: (params: RequestParams = {}) =>
      this.request<
        ResendVerificationMailSuccessDto,
        ResendVerificationMailErrorUserHasNoEmailDto
      >({
        path: `/profile/email/resend-verification`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags profile
     * @name ProfileControllerVerifyEmail
     * @request POST:/profile/email/verify
     * @secure
     */
    profileControllerVerifyEmail: (
      data: VerifyEmailDto,
      params: RequestParams = {}
    ) =>
      this.request<
        VerifyEmailSuccessDto,
        | (
            | VerifyEmailErrorCodeDoesNotMatchDto
            | VerifyEmailErrorCodeExpiredDto
            | VerifyEmailErrorEmailChangedDto
          )[]
        | VerifyEmailErrorTooManyFailedAttemptsDto
      >({
        path: `/profile/email/verify`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags profile
     * @name ProfileControllerResendTwoStepVerificationMail
     * @request POST:/profile/two-step/resend-verification
     * @secure
     */
    profileControllerResendTwoStepVerificationMail: (
      data: ResendVerificationMailDto,
      params: RequestParams = {}
    ) =>
      this.request<
        ResendVerificationMailSuccessDto,
        ResendVerificationMailErrorUserHasNoEmailDto
      >({
        path: `/profile/two-step/resend-verification`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags profile
     * @name ProfileControllerTwoStepVerify
     * @request POST:/profile/two-step/verify
     * @secure
     */
    profileControllerTwoStepVerify: (
      data: TwoStepVerifyDto,
      params: RequestParams = {}
    ) =>
      this.request<
        TwoStepVerifySuccessDto,
        | (
            | TwoStepVerifyErrorCodeDoesNotMatchDto
            | TwoStepVerifyErrorCodeExpiredDto
          )[]
        | TwoStepVerifyErrorTooManyFailedAttemptsDto
      >({
        path: `/profile/two-step/verify`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags profile
     * @name ProfileControllerEnableTwoStepVerification
     * @request POST:/profile/two-step/enable
     * @secure
     */
    profileControllerEnableTwoStepVerification: (
      data: TwoStepVerifyDto,
      params: RequestParams = {}
    ) =>
      this.request<
        TwoStepVerifySuccessDto,
        | (
            | TwoStepVerifyErrorCodeDoesNotMatchDto
            | TwoStepVerifyErrorCodeExpiredDto
          )[]
        | TwoStepVerifyErrorTooManyFailedAttemptsDto
      >({
        path: `/profile/two-step/enable`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags profile
     * @name ProfileControllerDisableTwoStepVerification
     * @request POST:/profile/two-step/disable
     * @secure
     */
    profileControllerDisableTwoStepVerification: (
      data: TwoStepVerifyDto,
      params: RequestParams = {}
    ) =>
      this.request<
        TwoStepVerifySuccessDto,
        | (
            | TwoStepVerifyErrorCodeDoesNotMatchDto
            | TwoStepVerifyErrorCodeExpiredDto
          )[]
        | TwoStepVerifyErrorTooManyFailedAttemptsDto
      >({
        path: `/profile/two-step/disable`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags profile
     * @name ProfileControllerGetAvailableProviders
     * @request GET:/profile/avaliable-providers
     */
    profileControllerGetAvailableProviders: (
      query: ProfileControllerGetAvailableProvidersParams,
      params: RequestParams = {}
    ) =>
      this.request<GetAvaliableProvidersSuccessDto, any>({
        path: `/profile/avaliable-providers`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags profile
     * @name ProfileControllerGetPictureUploadParams
     * @request GET:/profile/picture/upload-params
     * @secure
     */
    profileControllerGetPictureUploadParams: (
      query: ProfileControllerGetPictureUploadParamsParams,
      params: RequestParams = {}
    ) =>
      this.request<GetProfilePictureUploadParamsSuccessDto, any>({
        path: `/profile/picture/upload-params`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags profile
     * @name ProfileControllerDeletePicture
     * @request DELETE:/profile/picture/delete
     * @secure
     */
    profileControllerDeletePicture: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/profile/picture/delete`,
        method: 'DELETE',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags profile
     * @name ProfileControllerUpdateProfile
     * @request PUT:/profile/personal-data
     * @secure
     */
    profileControllerUpdateProfile: (
      data: UpdatePersonalInfoDto,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/profile/personal-data`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags profile
     * @name ProfileControllerGetNotificationPreferences
     * @request GET:/profile/notification-preferences
     * @secure
     */
    profileControllerGetNotificationPreferences: (
      query: ProfileControllerGetNotificationPreferencesParams,
      params: RequestParams = {}
    ) =>
      this.request<GetNotificationPreferencesSuccessDto, any>({
        path: `/profile/notification-preferences`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags profile
     * @name ProfileControllerUpdateNotificationPreferences
     * @request PUT:/profile/notification-preferences
     * @secure
     */
    profileControllerUpdateNotificationPreferences: (
      data: UpdateNotificationPreferencesDto,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/profile/notification-preferences`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags profile
     * @name ProfileControllerChangePassword
     * @request PUT:/profile/password
     * @secure
     */
    profileControllerChangePassword: (
      data: ChangeProfilePasswordDto,
      params: RequestParams = {}
    ) =>
      this.request<
        ChangeProfilePasswordSuccessDto,
        (ErrorPasswordDoesNotMatchDto | ErrorWeakPasswordDto)[]
      >({
        path: `/profile/password`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags profile
     * @name ProfileControllerDelete
     * @request DELETE:/profile
     * @secure
     */
    profileControllerDelete: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/profile`,
        method: 'DELETE',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags profile
     * @name ProfileControllerGetKnokUserToken
     * @request GET:/profile/knock/token
     * @secure
     */
    profileControllerGetKnokUserToken: (params: RequestParams = {}) =>
      this.request<GetKnockUserTokenSuccessDto, any>({
        path: `/profile/knock/token`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags profile
     * @name ProfileControllerCompleteOnboardingStep
     * @request POST:/profile/onboarding/step/complete
     * @secure
     */
    profileControllerCompleteOnboardingStep: (
      data: CompleteOnboardingStepDto,
      params: RequestParams = {}
    ) =>
      this.request<CompleteOnboardingStepSuccessDto, any>({
        path: `/profile/onboarding/step/complete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  };
  organization = {
    /**
     * No description
     *
     * @tags organization
     * @name OrganizationControllerCreateOrganization
     * @request POST:/organization/create
     * @secure
     */
    organizationControllerCreateOrganization: (
      data: CreateOrganizationDto,
      params: RequestParams = {}
    ) =>
      this.request<
        CreateOrganizationSuccessDto,
        | CreateOrganizationErrorAlreadyOwnsOrganizationDto
        | OrganizationLockedErrorDto
      >({
        path: `/organization/create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationControllerCheckSubdomain
     * @request GET:/organization/check-subdomain
     * @secure
     */
    organizationControllerCheckSubdomain: (
      query: OrganizationControllerCheckSubdomainParams,
      params: RequestParams = {}
    ) =>
      this.request<
        CheckOrganizationSubdomainSuccessDto,
        OrganizationLockedErrorDto
      >({
        path: `/organization/check-subdomain`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationControllerSuggestedSubdomain
     * @request GET:/organization/suggested-subdomain
     * @secure
     */
    organizationControllerSuggestedSubdomain: (
      query: OrganizationControllerSuggestedSubdomainParams,
      params: RequestParams = {}
    ) =>
      this.request<
        SuggestedOrganizationSubdomainSuccessDto,
        OrganizationLockedErrorDto
      >({
        path: `/organization/suggested-subdomain`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationControllerListUserOrganizations
     * @request GET:/organization/list
     * @secure
     */
    organizationControllerListUserOrganizations: (params: RequestParams = {}) =>
      this.request<OrganizationListDto, OrganizationLockedErrorDto>({
        path: `/organization/list`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationControllerGetOrganization
     * @request GET:/organization
     * @secure
     */
    organizationControllerGetOrganization: (
      query: OrganizationControllerGetOrganizationParams,
      params: RequestParams = {}
    ) =>
      this.request<GetOrganizationSuccessDto, OrganizationLockedErrorDto>({
        path: `/organization`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * @description Accept invitation
     *
     * @tags organization
     * @name OrganizationControllerJoinOrganization
     * @request POST:/organization/join
     * @secure
     */
    organizationControllerJoinOrganization: (
      data: JoinOrganizationDto,
      params: RequestParams = {}
    ) =>
      this.request<
        JoinOrganizationSuccessDto,
        | (
            | JoinOrganizationErrorSecretDoesNotMatchDto
            | JoinOrganizationErrorSecretExpiredDto
            | JoinOrganizationErrorEmailDoesNotMatchDto
            | JoinOrganizationErrorEmailDomainNotAllowedDto
          )[]
        | OrganizationLockedErrorDto
      >({
        path: `/organization/join`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationControllerLeaveOrganization
     * @request POST:/organization/leave
     * @secure
     */
    organizationControllerLeaveOrganization: (
      data: LeaveOrganizationDto,
      params: RequestParams = {}
    ) =>
      this.request<void, OrganizationLockedErrorDto>({
        path: `/organization/leave`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationControllerRenameOrganization
     * @request PUT:/organization/rename
     * @secure
     */
    organizationControllerRenameOrganization: (
      data: RenameOrganizationDto,
      params: RequestParams = {}
    ) =>
      this.request<RenameOrganizationSuccessDto, OrganizationLockedErrorDto>({
        path: `/organization/rename`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationControllerDeleteOrganization
     * @request DELETE:/organization/delete
     * @secure
     */
    organizationControllerDeleteOrganization: (
      query: OrganizationControllerDeleteOrganizationParams,
      params: RequestParams = {}
    ) =>
      this.request<DeleteOrganizationSuccessDto, OrganizationLockedErrorDto>({
        path: `/organization/delete`,
        method: 'DELETE',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationControllerCountMembers
     * @request GET:/organization/members/count
     * @secure
     */
    organizationControllerCountMembers: (
      query: OrganizationControllerCountMembersParams,
      params: RequestParams = {}
    ) =>
      this.request<CountMembersSuccessDto, OrganizationLockedErrorDto>({
        path: `/organization/members/count`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationControllerListMembers
     * @request GET:/organization/members/list
     * @secure
     */
    organizationControllerListMembers: (
      query: OrganizationControllerListMembersParams,
      params: RequestParams = {}
    ) =>
      this.request<
        PageDto & {
          edges: {
            node: OrganizationMemberDto;
            cursor: string;
          }[];
        },
        OrganizationLockedErrorDto
      >({
        path: `/organization/members/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationControllerDeleteOrganizationMembers
     * @request DELETE:/organization/members/delete
     * @secure
     */
    organizationControllerDeleteOrganizationMembers: (
      query: OrganizationControllerDeleteOrganizationMembersParams,
      params: RequestParams = {}
    ) =>
      this.request<
        DeleteOrganizationMembersSuccessDto,
        OrganizationLockedErrorDto
      >({
        path: `/organization/members/delete`,
        method: 'DELETE',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationControllerChangeOrganizationMembersRole
     * @request PUT:/organization/members/change-role
     * @secure
     */
    organizationControllerChangeOrganizationMembersRole: (
      data: ChangeOrganizationMembersRoleDto,
      params: RequestParams = {}
    ) =>
      this.request<
        ChangeOrganizationMembersRoleSuccessDto,
        OrganizationLockedErrorDto
      >({
        path: `/organization/members/change-role`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationControllerListOrganizationWorkpsaces
     * @request GET:/organization/workspaces/list
     * @secure
     */
    organizationControllerListOrganizationWorkpsaces: (
      query: OrganizationControllerListOrganizationWorkpsacesParams,
      params: RequestParams = {}
    ) =>
      this.request<
        ListOrganizationWorkspacesSuccessDto,
        OrganizationLockedErrorDto
      >({
        path: `/organization/workspaces/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationControllerGetOrganizationWorkpsaces
     * @request GET:/organization/workspace
     * @secure
     */
    organizationControllerGetOrganizationWorkpsaces: (
      query: OrganizationControllerGetOrganizationWorkpsacesParams,
      params: RequestParams = {}
    ) =>
      this.request<
        GetOrganizationWorkspaceSuccessDto,
        OrganizationLockedErrorDto
      >({
        path: `/organization/workspace`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationControllerInviteOrganizationMember
     * @request POST:/organization/members/invite
     * @secure
     */
    organizationControllerInviteOrganizationMember: (
      data: InviteOrganizationMembersDto,
      params: RequestParams = {}
    ) =>
      this.request<
        InviteOrganizationMembersSuccessDto,
        SubscriptionUsersLimitErrorDto | OrganizationLockedErrorDto
      >({
        path: `/organization/members/invite`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags organization
     * @name OrganizationControllerResendOrganizationInvitation
     * @request PUT:/organization/members/resend-invitation
     * @secure
     */
    organizationControllerResendOrganizationInvitation: (
      data: ResendOrganizationInvitationDto,
      params: RequestParams = {}
    ) =>
      this.request<
        ResendOrganizationInvitationSuccessDto,
        OrganizationLockedErrorDto
      >({
        path: `/organization/members/resend-invitation`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  };
  workspace = {
    /**
     * No description
     *
     * @tags workspace
     * @name WorkspaceControllerCreateWorkspace
     * @request POST:/workspace/create
     * @secure
     */
    workspaceControllerCreateWorkspace: (
      data: CreateWorkspaceDto,
      params: RequestParams = {}
    ) =>
      this.request<
        CreateWorkspaceSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/workspace/create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags workspace
     * @name WorkspaceControllerRenameWorkspace
     * @request PUT:/workspace/rename
     * @secure
     */
    workspaceControllerRenameWorkspace: (
      data: RenameWorkspaceDto,
      params: RequestParams = {}
    ) =>
      this.request<
        RenameWorkspaceSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/workspace/rename`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags workspace
     * @name WorkspaceControllerGetPictureUploadParams
     * @request GET:/workspace/picture/upload-params
     * @secure
     */
    workspaceControllerGetPictureUploadParams: (
      query: WorkspaceControllerGetPictureUploadParamsParams,
      params: RequestParams = {}
    ) =>
      this.request<
        GetWorkspacePictureUploadParamsSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/workspace/picture/upload-params`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags workspace
     * @name WorkspaceControllerDeltePicture
     * @request DELETE:/workspace/picture/delete
     * @secure
     */
    workspaceControllerDeltePicture: (
      query: WorkspaceControllerDeltePictureParams,
      params: RequestParams = {}
    ) =>
      this.request<
        DeleteWorkspacePictureSucessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/workspace/picture/delete`,
        method: 'DELETE',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags workspace
     * @name WorkspaceControllerDeleteWorkspace
     * @request DELETE:/workspace/delete
     * @secure
     */
    workspaceControllerDeleteWorkspace: (
      query: WorkspaceControllerDeleteWorkspaceParams,
      params: RequestParams = {}
    ) =>
      this.request<
        DeleteWorkspaceSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/workspace/delete`,
        method: 'DELETE',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags workspace
     * @name WorkspaceControllerListUserWorkspaces
     * @request GET:/workspace/list
     * @secure
     */
    workspaceControllerListUserWorkspaces: (params: RequestParams = {}) =>
      this.request<
        WorkspaceListDto,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/workspace/list`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags workspace
     * @name WorkspaceControllerGetWorkspace
     * @request GET:/workspace
     * @secure
     */
    workspaceControllerGetWorkspace: (
      query: WorkspaceControllerGetWorkspaceParams,
      params: RequestParams = {}
    ) =>
      this.request<
        GetWorkspaceSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/workspace`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags workspace
     * @name WorkspaceControllerFindMembersToInvite
     * @request GET:/workspace/members/find-to-invite
     * @secure
     */
    workspaceControllerFindMembersToInvite: (
      query: WorkspaceControllerFindMembersToInviteParams,
      params: RequestParams = {}
    ) =>
      this.request<
        PageDto & {
          edges: {
            node: MemberDto;
            cursor: string;
          }[];
        },
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/workspace/members/find-to-invite`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags workspace
     * @name WorkspaceControllerFindMembersToInviteInGroup
     * @request GET:/workspace/members/find-to-invite-in-group
     * @secure
     */
    workspaceControllerFindMembersToInviteInGroup: (
      query: WorkspaceControllerFindMembersToInviteInGroupParams,
      params: RequestParams = {}
    ) =>
      this.request<
        PageDto & {
          edges: {
            node: MemberDto;
            cursor: string;
          }[];
        },
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/workspace/members/find-to-invite-in-group`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags workspace
     * @name WorkspaceControllerInviteWorkspaceMembers
     * @request POST:/workspace/members/invite
     * @secure
     */
    workspaceControllerInviteWorkspaceMembers: (
      data: InviteWorkspaceMembersDto,
      params: RequestParams = {}
    ) =>
      this.request<
        InviteWorkspaceMembersSuccessDto,
        | SubscriptionUsersLimitErrorDto
        | (
            | OrganizationLockedErrorDto
            | SubscriptionFeatureLimitExceededErrorDto
          )
      >({
        path: `/workspace/members/invite`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags workspace
     * @name WorkspaceControllerGeneratePublicInvitationUrl
     * @request PUT:/workspace/public-invitation/generate
     * @secure
     */
    workspaceControllerGeneratePublicInvitationUrl: (
      data: GeneratePublicInvitationUrlDto,
      params: RequestParams = {}
    ) =>
      this.request<
        GeneratePublicInvitationUrlSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/workspace/public-invitation/generate`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags workspace
     * @name WorkspaceControllerDeletePublicInvitationUrl
     * @request DELETE:/workspace/public-invitation/delete
     * @secure
     */
    workspaceControllerDeletePublicInvitationUrl: (
      query: WorkspaceControllerDeletePublicInvitationUrlParams,
      params: RequestParams = {}
    ) =>
      this.request<
        DeletePublicInvitationUrlSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/workspace/public-invitation/delete`,
        method: 'DELETE',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags workspace
     * @name WorkspaceControllerCountMembers
     * @request GET:/workspace/members/count
     * @secure
     */
    workspaceControllerCountMembers: (
      query: WorkspaceControllerCountMembersParams,
      params: RequestParams = {}
    ) =>
      this.request<
        CountMembersSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/workspace/members/count`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags workspace
     * @name WorkspaceControllerListMembers
     * @request GET:/workspace/members/list
     * @secure
     */
    workspaceControllerListMembers: (
      query: WorkspaceControllerListMembersParams,
      params: RequestParams = {}
    ) =>
      this.request<
        PageDto & {
          edges: {
            node: WorkspaceMemberDto;
            cursor: string;
          }[];
        },
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/workspace/members/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags workspace
     * @name WorkspaceControllerDeleteWorkspaceMembers
     * @request DELETE:/workspace/members/delete
     * @secure
     */
    workspaceControllerDeleteWorkspaceMembers: (
      query: WorkspaceControllerDeleteWorkspaceMembersParams,
      params: RequestParams = {}
    ) =>
      this.request<
        DeleteWorkspaceMembersSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/workspace/members/delete`,
        method: 'DELETE',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags workspace
     * @name WorkspaceControllerLeaveWorkspace
     * @request POST:/workspace/leave
     * @secure
     */
    workspaceControllerLeaveWorkspace: (
      data: LeaveWorkspaceDto,
      params: RequestParams = {}
    ) =>
      this.request<
        void,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/workspace/leave`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags workspace
     * @name WorkspaceControllerChangeWorkspaceMembersRole
     * @request PUT:/workspace/members/change-role
     * @secure
     */
    workspaceControllerChangeWorkspaceMembersRole: (
      data: ChangeWorkspaceMembersRoleDto,
      params: RequestParams = {}
    ) =>
      this.request<
        ChangeWorkspaceMembersRoleSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/workspace/members/change-role`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags workspace
     * @name WorkspaceControllerResendWorkspaceInvitation
     * @request PUT:/workspace/members/resend-invitation
     * @secure
     */
    workspaceControllerResendWorkspaceInvitation: (
      data: ResendWorkspaceInvitationDto,
      params: RequestParams = {}
    ) =>
      this.request<
        ResendWorkspaceInvitationSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/workspace/members/resend-invitation`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags workspace
     * @name WorkspaceControllerGetWorkspaceCounters
     * @request GET:/workspace/counters
     * @secure
     */
    workspaceControllerGetWorkspaceCounters: (
      query: WorkspaceControllerGetWorkspaceCountersParams,
      params: RequestParams = {}
    ) =>
      this.request<
        WorkspaceCountersDto,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/workspace/counters`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags workspace
     * @name WorkspaceControllerGetWorkspaceObjectives
     * @request GET:/workspace/campaign-objectives
     * @secure
     */
    workspaceControllerGetWorkspaceObjectives: (
      query: WorkspaceControllerGetWorkspaceObjectivesParams,
      params: RequestParams = {}
    ) =>
      this.request<
        GetWorkspaceObjectivesSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/workspace/campaign-objectives`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags workspace
     * @name WorkspaceControllerSearchWorkspaceItems
     * @request GET:/workspace/search-items
     * @secure
     */
    workspaceControllerSearchWorkspaceItems: (
      query: WorkspaceControllerSearchWorkspaceItemsParams,
      params: RequestParams = {}
    ) =>
      this.request<
        SearchWorkspaceItemsSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/workspace/search-items`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags workspace
     * @name WorkspaceControllerSearchAssetComments
     * @request GET:/workspace/search-asset-comments
     * @secure
     */
    workspaceControllerSearchAssetComments: (
      query: WorkspaceControllerSearchAssetCommentsParams,
      params: RequestParams = {}
    ) =>
      this.request<
        PageDto & {
          edges: {
            node: AssetVersionCommentSearchItemDto;
            cursor: string;
          }[];
        },
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/workspace/search-asset-comments`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags workspace
     * @name WorkspaceControllerSearchAssetCommentsCount
     * @request GET:/workspace/search-asset-comments/count
     * @secure
     */
    workspaceControllerSearchAssetCommentsCount: (
      query: WorkspaceControllerSearchAssetCommentsCountParams,
      params: RequestParams = {}
    ) =>
      this.request<
        CountSearchedAssetCommentsSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/workspace/search-asset-comments/count`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags workspace
     * @name WorkspaceControllerCountMemberGroups
     * @request GET:/workspace/member-groups/count
     * @secure
     */
    workspaceControllerCountMemberGroups: (
      query: WorkspaceControllerCountMemberGroupsParams,
      params: RequestParams = {}
    ) =>
      this.request<
        CountMembersSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/workspace/member-groups/count`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags workspace
     * @name WorkspaceControllerListMemberGroups
     * @request GET:/workspace/member-groups/list
     * @secure
     */
    workspaceControllerListMemberGroups: (
      query: WorkspaceControllerListMemberGroupsParams,
      params: RequestParams = {}
    ) =>
      this.request<
        PageDto & {
          edges: {
            node: WorkspaceMemberGroupListItemDto;
            cursor: string;
          }[];
        },
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/workspace/member-groups/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags workspace
     * @name WorkspaceControllerGetWorkspaceMemberGroup
     * @request GET:/workspace/member-groups
     * @secure
     */
    workspaceControllerGetWorkspaceMemberGroup: (
      query: WorkspaceControllerGetWorkspaceMemberGroupParams,
      params: RequestParams = {}
    ) =>
      this.request<
        GetWorkspaceMemberGroupSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/workspace/member-groups`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags workspace
     * @name WorkspaceControllerCreateWorkspaceMemberGroup
     * @request POST:/workspace/member-groups/create
     * @secure
     */
    workspaceControllerCreateWorkspaceMemberGroup: (
      data: CreateWorkspaceMemberGroupDto,
      params: RequestParams = {}
    ) =>
      this.request<
        CreateWorkspaceMemberGroupSuccessDto,
        | SubscriptionUsersLimitErrorDto
        | (
            | OrganizationLockedErrorDto
            | SubscriptionFeatureLimitExceededErrorDto
          )
      >({
        path: `/workspace/member-groups/create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags workspace
     * @name WorkspaceControllerRenameWorkspaceMemberGroup
     * @request PUT:/workspace/member-groups/rename
     * @secure
     */
    workspaceControllerRenameWorkspaceMemberGroup: (
      data: RenameWorkspaceMemberGroupDto,
      params: RequestParams = {}
    ) =>
      this.request<
        RenameWorkspaceMemberGroupSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/workspace/member-groups/rename`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags workspace
     * @name WorkspaceControllerDeleteWorkspaceMemberGroup
     * @request DELETE:/workspace/member-groups/delete
     * @secure
     */
    workspaceControllerDeleteWorkspaceMemberGroup: (
      query: WorkspaceControllerDeleteWorkspaceMemberGroupParams,
      params: RequestParams = {}
    ) =>
      this.request<
        DeleteWorkspaceMemberGroupSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/workspace/member-groups/delete`,
        method: 'DELETE',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags workspace
     * @name WorkspaceControllerDeleteWorkspaceMemberGroupMembers
     * @request DELETE:/workspace/member-groups/members/delete
     * @secure
     */
    workspaceControllerDeleteWorkspaceMemberGroupMembers: (
      query: WorkspaceControllerDeleteWorkspaceMemberGroupMembersParams,
      params: RequestParams = {}
    ) =>
      this.request<
        DeleteWorkspaceMemberGroupMembersSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/workspace/member-groups/members/delete`,
        method: 'DELETE',
        query: query,
        secure: true,
        format: 'json',
        ...params
      })
  };
  campaign = {
    /**
     * No description
     *
     * @tags campaign
     * @name CampaignControllerCreateCampaign
     * @request POST:/campaign/create
     * @secure
     */
    campaignControllerCreateCampaign: (
      data: CreateCampaignDto,
      params: RequestParams = {}
    ) =>
      this.request<
        CreateCampaignSuccessDto,
        (
          | OrganizationLockedErrorDto
          | SubscriptionFeatureNotAllowedErrorDto
          | SubscriptionFeatureLimitExceededErrorDto
        )[]
      >({
        path: `/campaign/create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags campaign
     * @name CampaignControllerGetCampaign
     * @request GET:/campaign
     * @secure
     */
    campaignControllerGetCampaign: (
      query: CampaignControllerGetCampaignParams,
      params: RequestParams = {}
    ) =>
      this.request<
        GetCampaignSuccessDto,
        (
          | OrganizationLockedErrorDto
          | SubscriptionFeatureNotAllowedErrorDto
          | SubscriptionFeatureLimitExceededErrorDto
        )[]
      >({
        path: `/campaign`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags campaign
     * @name CampaignControllerUpdateCampaign
     * @request PUT:/campaign/update
     * @secure
     */
    campaignControllerUpdateCampaign: (
      data: UpdateCampaignDto,
      params: RequestParams = {}
    ) =>
      this.request<
        UpdateCampaignDto,
        (
          | OrganizationLockedErrorDto
          | SubscriptionFeatureNotAllowedErrorDto
          | SubscriptionFeatureLimitExceededErrorDto
        )[]
      >({
        path: `/campaign/update`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags campaign
     * @name CampaignControllerCountCampaigns
     * @request GET:/campaign/count
     * @secure
     */
    campaignControllerCountCampaigns: (
      query: CampaignControllerCountCampaignsParams,
      params: RequestParams = {}
    ) =>
      this.request<
        CountCampaignsSuccessDto,
        (
          | OrganizationLockedErrorDto
          | SubscriptionFeatureNotAllowedErrorDto
          | SubscriptionFeatureLimitExceededErrorDto
        )[]
      >({
        path: `/campaign/count`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags campaign
     * @name CampaignControllerListCampaings
     * @request GET:/campaign/list
     * @secure
     */
    campaignControllerListCampaings: (
      query: CampaignControllerListCampaingsParams,
      params: RequestParams = {}
    ) =>
      this.request<
        PageDto & {
          edges: {
            node: CampaignItemDto;
            cursor: string;
          }[];
        },
        (
          | OrganizationLockedErrorDto
          | SubscriptionFeatureNotAllowedErrorDto
          | SubscriptionFeatureLimitExceededErrorDto
        )[]
      >({
        path: `/campaign/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags campaign
     * @name CampaignControllerGetSimpleCampaignsList
     * @request GET:/campaign/list/simple
     * @secure
     */
    campaignControllerGetSimpleCampaignsList: (
      query: CampaignControllerGetSimpleCampaignsListParams,
      params: RequestParams = {}
    ) =>
      this.request<
        GetSimpleCampaignsListSuccessDto,
        (
          | OrganizationLockedErrorDto
          | SubscriptionFeatureNotAllowedErrorDto
          | SubscriptionFeatureLimitExceededErrorDto
        )[]
      >({
        path: `/campaign/list/simple`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags campaign
     * @name CampaignControllerGetSimpleCampaignsListWithFolders
     * @request GET:/campaign/list/simple/with-folders
     * @secure
     */
    campaignControllerGetSimpleCampaignsListWithFolders: (
      query: CampaignControllerGetSimpleCampaignsListWithFoldersParams,
      params: RequestParams = {}
    ) =>
      this.request<
        GetSimpleCampaignsListWithFoldersSuccessDto,
        (
          | OrganizationLockedErrorDto
          | SubscriptionFeatureNotAllowedErrorDto
          | SubscriptionFeatureLimitExceededErrorDto
        )[]
      >({
        path: `/campaign/list/simple/with-folders`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags campaign
     * @name CampaignControllerDeleteCampaign
     * @request DELETE:/campaign/delete
     * @secure
     */
    campaignControllerDeleteCampaign: (
      query: CampaignControllerDeleteCampaignParams,
      params: RequestParams = {}
    ) =>
      this.request<
        DeleteCampaignSuccessDto,
        (
          | OrganizationLockedErrorDto
          | SubscriptionFeatureNotAllowedErrorDto
          | SubscriptionFeatureLimitExceededErrorDto
        )[]
      >({
        path: `/campaign/delete`,
        method: 'DELETE',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags campaign
     * @name CampaignControllerRestoreDeletedCampaign
     * @request PUT:/campaign/deleted/restore
     * @secure
     */
    campaignControllerRestoreDeletedCampaign: (
      data: RestoreCampaignDto,
      params: RequestParams = {}
    ) =>
      this.request<
        RestoreCampaignSuccessDto,
        (
          | OrganizationLockedErrorDto
          | SubscriptionFeatureNotAllowedErrorDto
          | SubscriptionFeatureLimitExceededErrorDto
        )[]
      >({
        path: `/campaign/deleted/restore`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags campaign
     * @name CampaignControllerSetVisibility
     * @request PUT:/campaign/visibility
     * @secure
     */
    campaignControllerSetVisibility: (
      data: SetCampaignVisibilityDto,
      params: RequestParams = {}
    ) =>
      this.request<
        SetCampaignVisibilitySuccessDto,
        (
          | OrganizationLockedErrorDto
          | SubscriptionFeatureNotAllowedErrorDto
          | SubscriptionFeatureLimitExceededErrorDto
        )[]
      >({
        path: `/campaign/visibility`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags campaign
     * @name CampaignControllerUpdateAdditionalPermissions
     * @request PUT:/campaign/additional-permissions
     * @secure
     */
    campaignControllerUpdateAdditionalPermissions: (
      data: UpdateCampaignAdditionalPermissionsDto,
      params: RequestParams = {}
    ) =>
      this.request<
        UpdateCampaignAdditionalPermissionsSuccessDto,
        (
          | OrganizationLockedErrorDto
          | SubscriptionFeatureNotAllowedErrorDto
          | SubscriptionFeatureLimitExceededErrorDto
        )[]
      >({
        path: `/campaign/additional-permissions`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags campaign
     * @name CampaignControllerSetFavorite
     * @request PUT:/campaign/set-favorite
     * @secure
     */
    campaignControllerSetFavorite: (
      data: SetCampaignFavoriteDto,
      params: RequestParams = {}
    ) =>
      this.request<
        SetCampaignFavoriteSuccessDto,
        (
          | OrganizationLockedErrorDto
          | SubscriptionFeatureNotAllowedErrorDto
          | SubscriptionFeatureLimitExceededErrorDto
        )[]
      >({
        path: `/campaign/set-favorite`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags campaign
     * @name CampaignControllerArchiveCampaign
     * @request PUT:/campaign/archive
     * @secure
     */
    campaignControllerArchiveCampaign: (
      data: ArchiveCampaignDto,
      params: RequestParams = {}
    ) =>
      this.request<
        ArchiveCampaignSuccessDto,
        (
          | OrganizationLockedErrorDto
          | SubscriptionFeatureNotAllowedErrorDto
          | SubscriptionFeatureLimitExceededErrorDto
        )[]
      >({
        path: `/campaign/archive`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags campaign
     * @name CampaignControllerRestoreCampaignFromArchive
     * @request PUT:/campaign/archived/restore
     * @secure
     */
    campaignControllerRestoreCampaignFromArchive: (
      data: RestoreCampaignFromArchiveDto,
      params: RequestParams = {}
    ) =>
      this.request<
        RestoreCampaignFromArchiveSuccessDto,
        (
          | OrganizationLockedErrorDto
          | SubscriptionFeatureNotAllowedErrorDto
          | SubscriptionFeatureLimitExceededErrorDto
        )[]
      >({
        path: `/campaign/archived/restore`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags campaign
     * @name CampaignControllerFindMembersToInvite
     * @request GET:/campaign/members/find-to-invite
     * @secure
     */
    campaignControllerFindMembersToInvite: (
      query: CampaignControllerFindMembersToInviteParams,
      params: RequestParams = {}
    ) =>
      this.request<
        PageDto & {
          edges: {
            node: MemberDto;
            cursor: string;
          }[];
        },
        (
          | OrganizationLockedErrorDto
          | SubscriptionFeatureNotAllowedErrorDto
          | SubscriptionFeatureLimitExceededErrorDto
        )[]
      >({
        path: `/campaign/members/find-to-invite`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags campaign
     * @name CampaignControllerFindMemberGroupsToInvite
     * @request GET:/campaign/member-groups/find-to-invite
     * @secure
     */
    campaignControllerFindMemberGroupsToInvite: (
      query: CampaignControllerFindMemberGroupsToInviteParams,
      params: RequestParams = {}
    ) =>
      this.request<
        PageDto & {
          edges: {
            node: MemberGroupDto;
            cursor: string;
          }[];
        },
        (
          | OrganizationLockedErrorDto
          | SubscriptionFeatureNotAllowedErrorDto
          | SubscriptionFeatureLimitExceededErrorDto
        )[]
      >({
        path: `/campaign/member-groups/find-to-invite`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags campaign
     * @name CampaignControllerInviteCampaignMembers
     * @request POST:/campaign/members/invite
     * @secure
     */
    campaignControllerInviteCampaignMembers: (
      data: InviteCampaignMembersDto,
      params: RequestParams = {}
    ) =>
      this.request<
        InviteCampaignMembersSuccessDto,
        | SubscriptionUsersLimitErrorDto
        | (
            | OrganizationLockedErrorDto
            | SubscriptionFeatureNotAllowedErrorDto
            | SubscriptionFeatureLimitExceededErrorDto
          )[]
      >({
        path: `/campaign/members/invite`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags campaign
     * @name CampaignControllerCountMembers
     * @request GET:/campaign/members/count
     * @secure
     */
    campaignControllerCountMembers: (
      query: CampaignControllerCountMembersParams,
      params: RequestParams = {}
    ) =>
      this.request<
        CountMembersSuccessDto,
        (
          | OrganizationLockedErrorDto
          | SubscriptionFeatureNotAllowedErrorDto
          | SubscriptionFeatureLimitExceededErrorDto
        )[]
      >({
        path: `/campaign/members/count`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags campaign
     * @name CampaignControllerListMembers
     * @request GET:/campaign/members/list
     * @secure
     */
    campaignControllerListMembers: (
      query: CampaignControllerListMembersParams,
      params: RequestParams = {}
    ) =>
      this.request<
        PageDto & {
          edges: {
            node: CampaignMemberDto;
            cursor: string;
          }[];
        },
        (
          | OrganizationLockedErrorDto
          | SubscriptionFeatureNotAllowedErrorDto
          | SubscriptionFeatureLimitExceededErrorDto
        )[]
      >({
        path: `/campaign/members/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags campaign
     * @name CampaignControllerCountMemberGroups
     * @request GET:/campaign/member-groups/count
     * @secure
     */
    campaignControllerCountMemberGroups: (
      query: CampaignControllerCountMemberGroupsParams,
      params: RequestParams = {}
    ) =>
      this.request<
        CountMembersSuccessDto,
        (
          | OrganizationLockedErrorDto
          | SubscriptionFeatureNotAllowedErrorDto
          | SubscriptionFeatureLimitExceededErrorDto
        )[]
      >({
        path: `/campaign/member-groups/count`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags campaign
     * @name CampaignControllerListMemberGroups
     * @request GET:/campaign/member-groups/list
     * @secure
     */
    campaignControllerListMemberGroups: (
      query: CampaignControllerListMemberGroupsParams,
      params: RequestParams = {}
    ) =>
      this.request<
        PageDto & {
          edges: {
            node: CampaignMemberGroupDto;
            cursor: string;
          }[];
        },
        (
          | OrganizationLockedErrorDto
          | SubscriptionFeatureNotAllowedErrorDto
          | SubscriptionFeatureLimitExceededErrorDto
        )[]
      >({
        path: `/campaign/member-groups/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags campaign
     * @name CampaignControllerDeleteMembers
     * @request DELETE:/campaign/members/revoke-access
     * @secure
     */
    campaignControllerDeleteMembers: (
      query: CampaignControllerDeleteMembersParams,
      params: RequestParams = {}
    ) =>
      this.request<
        DeleteCampaignMembersSuccessDto,
        (
          | OrganizationLockedErrorDto
          | SubscriptionFeatureNotAllowedErrorDto
          | SubscriptionFeatureLimitExceededErrorDto
        )[]
      >({
        path: `/campaign/members/revoke-access`,
        method: 'DELETE',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags campaign
     * @name CampaignControllerGetTimeline
     * @request GET:/campaign/timeline
     * @secure
     */
    campaignControllerGetTimeline: (
      query: CampaignControllerGetTimelineParams,
      params: RequestParams = {}
    ) =>
      this.request<
        PageDto & {
          edges: {
            node: CampaignEventDto;
            cursor: string;
          }[];
        },
        (
          | OrganizationLockedErrorDto
          | SubscriptionFeatureNotAllowedErrorDto
          | SubscriptionFeatureLimitExceededErrorDto
        )[]
      >({
        path: `/campaign/timeline`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags campaign
     * @name CampaignControllerCreateTaskField
     * @request POST:/campaign/task-field/create
     * @secure
     */
    campaignControllerCreateTaskField: (
      data: CreateTaskFieldDto,
      params: RequestParams = {}
    ) =>
      this.request<
        CreateTaskFieldSuccessDto,
        (
          | OrganizationLockedErrorDto
          | SubscriptionFeatureNotAllowedErrorDto
          | SubscriptionFeatureLimitExceededErrorDto
        )[]
      >({
        path: `/campaign/task-field/create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags campaign
     * @name CampaignControllerGetTaskField
     * @request GET:/campaign/task-field
     * @secure
     */
    campaignControllerGetTaskField: (
      query: CampaignControllerGetTaskFieldParams,
      params: RequestParams = {}
    ) =>
      this.request<
        GetTaskFieldSuccessDto,
        (
          | OrganizationLockedErrorDto
          | SubscriptionFeatureNotAllowedErrorDto
          | SubscriptionFeatureLimitExceededErrorDto
        )[]
      >({
        path: `/campaign/task-field`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags campaign
     * @name CampaignControllerListTaskFields
     * @request GET:/campaign/task-field/list
     * @secure
     */
    campaignControllerListTaskFields: (
      query: CampaignControllerListTaskFieldsParams,
      params: RequestParams = {}
    ) =>
      this.request<
        ListTaskFieldsSuccessDto,
        (
          | OrganizationLockedErrorDto
          | SubscriptionFeatureNotAllowedErrorDto
          | SubscriptionFeatureLimitExceededErrorDto
        )[]
      >({
        path: `/campaign/task-field/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags campaign
     * @name CampaignControllerDeleteTaskField
     * @request DELETE:/campaign/task-field/delete
     * @secure
     */
    campaignControllerDeleteTaskField: (
      query: CampaignControllerDeleteTaskFieldParams,
      params: RequestParams = {}
    ) =>
      this.request<
        DeleteTaskFieldSuccessDto,
        (
          | OrganizationLockedErrorDto
          | SubscriptionFeatureNotAllowedErrorDto
          | SubscriptionFeatureLimitExceededErrorDto
        )[]
      >({
        path: `/campaign/task-field/delete`,
        method: 'DELETE',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags campaign
     * @name CampaignControllerRenameTaskField
     * @request PUT:/campaign/task-field/rename
     * @secure
     */
    campaignControllerRenameTaskField: (
      data: RenameTaskFieldDto,
      params: RequestParams = {}
    ) =>
      this.request<
        RenameTaskFieldSuccessDto,
        (
          | OrganizationLockedErrorDto
          | SubscriptionFeatureNotAllowedErrorDto
          | SubscriptionFeatureLimitExceededErrorDto
        )[]
      >({
        path: `/campaign/task-field/rename`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags campaign
     * @name CampaignControllerChangeCampaignOrder
     * @request PUT:/campaign/order/change
     * @secure
     */
    campaignControllerChangeCampaignOrder: (
      data: ChangeCampaignOrderDto,
      params: RequestParams = {}
    ) =>
      this.request<
        ChangeCampaignOrderSuccessDto,
        (
          | OrganizationLockedErrorDto
          | SubscriptionFeatureNotAllowedErrorDto
          | SubscriptionFeatureLimitExceededErrorDto
        )[]
      >({
        path: `/campaign/order/change`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags campaign
     * @name CampaignControllerUpdateMetadata
     * @request PUT:/campaign/metadata
     * @secure
     */
    campaignControllerUpdateMetadata: (
      data: UpdateCampaignMetadataDto,
      params: RequestParams = {}
    ) =>
      this.request<
        UpdateCampaignMetadataSuccessDto,
        (
          | OrganizationLockedErrorDto
          | SubscriptionFeatureNotAllowedErrorDto
          | SubscriptionFeatureLimitExceededErrorDto
        )[]
      >({
        path: `/campaign/metadata`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags campaign
     * @name CampaignControllerGetAwDefaultSettings
     * @request GET:/campaign/aw-default-settings
     * @secure
     */
    campaignControllerGetAwDefaultSettings: (
      query: CampaignControllerGetAwDefaultSettingsParams,
      params: RequestParams = {}
    ) =>
      this.request<
        GetAWDefaultSettingsSuccessDto,
        | (
            | OrganizationLockedErrorDto
            | SubscriptionFeatureNotAllowedErrorDto
            | SubscriptionFeatureLimitExceededErrorDto
          )[]
        | void
      >({
        path: `/campaign/aw-default-settings`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags campaign
     * @name CampaignControllerUpdateAwDefaultSettings
     * @request PUT:/campaign/aw-default-settings
     * @secure
     */
    campaignControllerUpdateAwDefaultSettings: (
      data: UpdateAWDefaultSettingsDto,
      params: RequestParams = {}
    ) =>
      this.request<
        UpdateAWDefaultSettingsSuccessDto,
        (
          | OrganizationLockedErrorDto
          | SubscriptionFeatureNotAllowedErrorDto
          | SubscriptionFeatureLimitExceededErrorDto
        )[]
      >({
        path: `/campaign/aw-default-settings`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  };
  image = {
    /**
     * No description
     *
     * @tags image
     * @name ImageControllerDownloadImage
     * @request GET:/image/{token}
     */
    imageControllerDownloadImage: (
      { token, ...query }: ImageControllerDownloadImageParams,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/image/${token}`,
        method: 'GET',
        query: query,
        ...params
      })
  };
  asset = {
    /**
     * No description
     *
     * @tags asset
     * @name AssetControllerCreateFolder
     * @request POST:/asset/folder/create
     * @secure
     */
    assetControllerCreateFolder: (
      data: CreateFolderDto,
      params: RequestParams = {}
    ) =>
      this.request<
        CreateFolderSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureNotAllowedErrorDto
      >({
        path: `/asset/folder/create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset
     * @name AssetControllerDeleteFolder
     * @request DELETE:/asset/folder/delete
     * @secure
     */
    assetControllerDeleteFolder: (
      query: AssetControllerDeleteFolderParams,
      params: RequestParams = {}
    ) =>
      this.request<
        DeleteFolderSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureNotAllowedErrorDto
      >({
        path: `/asset/folder/delete`,
        method: 'DELETE',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset
     * @name AssetControllerRestoreDeletedFolder
     * @request PUT:/asset/folder/deleted/restore
     * @secure
     */
    assetControllerRestoreDeletedFolder: (
      data: RestoreFolderDto,
      params: RequestParams = {}
    ) =>
      this.request<
        RestoreFolderSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureNotAllowedErrorDto
      >({
        path: `/asset/folder/deleted/restore`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset
     * @name AssetControllerSetFolderVisibility
     * @request PUT:/asset/folder/visibility
     * @secure
     */
    assetControllerSetFolderVisibility: (
      data: SetFolderVisibilityDto,
      params: RequestParams = {}
    ) =>
      this.request<
        SetFolderVisibilitySuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureNotAllowedErrorDto
      >({
        path: `/asset/folder/visibility`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset
     * @name AssetControllerRenameFolder
     * @request PUT:/asset/folder/rename
     * @secure
     */
    assetControllerRenameFolder: (
      data: RenameFolderDto,
      params: RequestParams = {}
    ) =>
      this.request<
        RenameFolderSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureNotAllowedErrorDto
      >({
        path: `/asset/folder/rename`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset
     * @name AssetControllerGetSimpleFoldersList
     * @request GET:/asset/folder/list/simple
     * @secure
     */
    assetControllerGetSimpleFoldersList: (
      query: AssetControllerGetSimpleFoldersListParams,
      params: RequestParams = {}
    ) =>
      this.request<
        GetSimpleFoldersListSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureNotAllowedErrorDto
      >({
        path: `/asset/folder/list/simple`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset
     * @name AssetControllerFindMembersToInviteInFolder
     * @request GET:/asset/folder/members/find-to-invite
     * @secure
     */
    assetControllerFindMembersToInviteInFolder: (
      query: AssetControllerFindMembersToInviteInFolderParams,
      params: RequestParams = {}
    ) =>
      this.request<
        PageDto & {
          edges: {
            node: MemberDto;
            cursor: string;
          }[];
        },
        OrganizationLockedErrorDto | SubscriptionFeatureNotAllowedErrorDto
      >({
        path: `/asset/folder/members/find-to-invite`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset
     * @name AssetControllerFindMemberGroupsToInviteInFolder
     * @request GET:/asset/folder/member-groups/find-to-invite
     * @secure
     */
    assetControllerFindMemberGroupsToInviteInFolder: (
      query: AssetControllerFindMemberGroupsToInviteInFolderParams,
      params: RequestParams = {}
    ) =>
      this.request<
        PageDto & {
          edges: {
            node: MemberGroupDto;
            cursor: string;
          }[];
        },
        OrganizationLockedErrorDto | SubscriptionFeatureNotAllowedErrorDto
      >({
        path: `/asset/folder/member-groups/find-to-invite`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset
     * @name AssetControllerInviteFolderMembers
     * @request POST:/asset/folder/members/invite
     * @secure
     */
    assetControllerInviteFolderMembers: (
      data: InviteFolderMembersDto,
      params: RequestParams = {}
    ) =>
      this.request<
        InviteFolderMembersSuccessDto,
        | SubscriptionUsersLimitErrorDto
        | (OrganizationLockedErrorDto | SubscriptionFeatureNotAllowedErrorDto)
      >({
        path: `/asset/folder/members/invite`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset
     * @name AssetControllerCountFolderMembers
     * @request GET:/asset/folder/members/count
     * @secure
     */
    assetControllerCountFolderMembers: (
      query: AssetControllerCountFolderMembersParams,
      params: RequestParams = {}
    ) =>
      this.request<
        CountMembersSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureNotAllowedErrorDto
      >({
        path: `/asset/folder/members/count`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset
     * @name AssetControllerListFolderMembers
     * @request GET:/asset/folder/members/list
     * @secure
     */
    assetControllerListFolderMembers: (
      query: AssetControllerListFolderMembersParams,
      params: RequestParams = {}
    ) =>
      this.request<
        PageDto & {
          edges: {
            node: FolderMemberDto;
            cursor: string;
          }[];
        },
        OrganizationLockedErrorDto | SubscriptionFeatureNotAllowedErrorDto
      >({
        path: `/asset/folder/members/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset
     * @name AssetControllerCountFolderMemberGroups
     * @request GET:/asset/folder/member-groups/count
     * @secure
     */
    assetControllerCountFolderMemberGroups: (
      query: AssetControllerCountFolderMemberGroupsParams,
      params: RequestParams = {}
    ) =>
      this.request<
        CountMembersSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureNotAllowedErrorDto
      >({
        path: `/asset/folder/member-groups/count`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset
     * @name AssetControllerListFolderMemberGroups
     * @request GET:/asset/folder/member-groups/list
     * @secure
     */
    assetControllerListFolderMemberGroups: (
      query: AssetControllerListFolderMemberGroupsParams,
      params: RequestParams = {}
    ) =>
      this.request<
        PageDto & {
          edges: {
            node: FolderMemberGroupDto;
            cursor: string;
          }[];
        },
        OrganizationLockedErrorDto | SubscriptionFeatureNotAllowedErrorDto
      >({
        path: `/asset/folder/member-groups/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset
     * @name AssetControllerDeleteFolderMembers
     * @request DELETE:/asset/folder/members/revoke-access
     * @secure
     */
    assetControllerDeleteFolderMembers: (
      query: AssetControllerDeleteFolderMembersParams,
      params: RequestParams = {}
    ) =>
      this.request<
        DeleteFolderMembersSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureNotAllowedErrorDto
      >({
        path: `/asset/folder/members/revoke-access`,
        method: 'DELETE',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset
     * @name AssetControllerUpdateFolderMetadata
     * @request PUT:/asset/folder/metadata
     * @secure
     */
    assetControllerUpdateFolderMetadata: (
      data: UpdateFolderMetadataDto,
      params: RequestParams = {}
    ) =>
      this.request<
        UpdateFolderMetadataSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureNotAllowedErrorDto
      >({
        path: `/asset/folder/metadata`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset
     * @name AssetControllerGetAsset
     * @request GET:/asset
     * @secure
     */
    assetControllerGetAsset: (
      query: AssetControllerGetAssetParams,
      params: RequestParams = {}
    ) =>
      this.request<
        GetAssetSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureNotAllowedErrorDto
      >({
        path: `/asset`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset
     * @name AssetControllerCountAssets
     * @request GET:/asset/count
     * @secure
     */
    assetControllerCountAssets: (
      query: AssetControllerCountAssetsParams,
      params: RequestParams = {}
    ) =>
      this.request<
        CountAssetsSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureNotAllowedErrorDto
      >({
        path: `/asset/count`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset
     * @name AssetControllerListAssets
     * @request GET:/asset/list
     * @secure
     */
    assetControllerListAssets: (
      query: AssetControllerListAssetsParams,
      params: RequestParams = {}
    ) =>
      this.request<
        PageDto & {
          edges: {
            node: AssetItemDto;
            cursor: string;
          }[];
        },
        OrganizationLockedErrorDto | SubscriptionFeatureNotAllowedErrorDto
      >({
        path: `/asset/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset
     * @name AssetControllerGetAssetsDownloadList
     * @request GET:/asset/list/download
     * @secure
     */
    assetControllerGetAssetsDownloadList: (
      query: AssetControllerGetAssetsDownloadListParams,
      params: RequestParams = {}
    ) =>
      this.request<
        GetAssetsDownloadListSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureNotAllowedErrorDto
      >({
        path: `/asset/list/download`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset
     * @name AssetControllerMoveAssetToFolder
     * @request PUT:/asset/move
     * @secure
     */
    assetControllerMoveAssetToFolder: (
      query: AssetControllerMoveAssetToFolderParams,
      params: RequestParams = {}
    ) =>
      this.request<
        MoveAssetToFolderSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureNotAllowedErrorDto
      >({
        path: `/asset/move`,
        method: 'PUT',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset
     * @name AssetControllerInitUpload
     * @request POST:/asset/version/init-upload
     * @secure
     */
    assetControllerInitUpload: (
      data: InitAssetVersionUploadDto,
      params: RequestParams = {}
    ) =>
      this.request<
        InitAssetVersionUploadSuccessDto,
        | (
            | AssetsSizeLimitExceededErrorDto
            | AssetsSizeLimitWillExceededErrorDto
          )[]
        | (OrganizationLockedErrorDto | SubscriptionFeatureNotAllowedErrorDto)
      >({
        path: `/asset/version/init-upload`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset
     * @name AssetControllerSetFavorite
     * @request PUT:/asset/set-favorite
     * @secure
     */
    assetControllerSetFavorite: (
      data: SetAssetFavoriteDto,
      params: RequestParams = {}
    ) =>
      this.request<
        SetAssetFavoriteSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureNotAllowedErrorDto
      >({
        path: `/asset/set-favorite`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset
     * @name AssetControllerDeleteAsset
     * @request DELETE:/asset/delete
     * @secure
     */
    assetControllerDeleteAsset: (
      query: AssetControllerDeleteAssetParams,
      params: RequestParams = {}
    ) =>
      this.request<
        DeleteAssetSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureNotAllowedErrorDto
      >({
        path: `/asset/delete`,
        method: 'DELETE',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset
     * @name AssetControllerRestoreDeletedAsset
     * @request PUT:/asset/deleted/restore
     * @secure
     */
    assetControllerRestoreDeletedAsset: (
      data: RestoreAssetDto,
      params: RequestParams = {}
    ) =>
      this.request<
        RestoreAssetSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureNotAllowedErrorDto
      >({
        path: `/asset/deleted/restore`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset
     * @name AssetControllerRename
     * @request PUT:/asset/rename
     * @secure
     */
    assetControllerRename: (data: RenameAssetDto, params: RequestParams = {}) =>
      this.request<
        RenameAssetSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureNotAllowedErrorDto
      >({
        path: `/asset/rename`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset
     * @name AssetControllerSetRecentlyOpened
     * @request POST:/asset/opened/add
     * @secure
     */
    assetControllerSetRecentlyOpened: (
      data: SetAssetOpenedDto,
      params: RequestParams = {}
    ) =>
      this.request<
        SetAssetOpenedSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureNotAllowedErrorDto
      >({
        path: `/asset/opened/add`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset
     * @name AssetControllerChangeAssetOrder
     * @request PUT:/asset/order/change
     * @secure
     */
    assetControllerChangeAssetOrder: (
      data: ChangeAssetOrderDto,
      params: RequestParams = {}
    ) =>
      this.request<
        ChangeAssetOrderSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureNotAllowedErrorDto
      >({
        path: `/asset/order/change`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset
     * @name AssetControllerChangeFolderOrder
     * @request PUT:/asset/folder/order/change
     * @secure
     */
    assetControllerChangeFolderOrder: (
      data: ChangeFolderOrderDto,
      params: RequestParams = {}
    ) =>
      this.request<
        ChangeFolderOrderSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureNotAllowedErrorDto
      >({
        path: `/asset/folder/order/change`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset
     * @name AssetControllerUpdateAssetVersionDescription
     * @request PUT:/asset/version/description/update
     * @secure
     */
    assetControllerUpdateAssetVersionDescription: (
      data: UpdateAssetVersionDescriptionDto,
      params: RequestParams = {}
    ) =>
      this.request<
        UpdateAssetVersionDescriptionSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureNotAllowedErrorDto
      >({
        path: `/asset/version/description/update`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset
     * @name AssetControllerRemoveAssetVersionFromStack
     * @request DELETE:/asset/version/remove-from-stack
     * @secure
     */
    assetControllerRemoveAssetVersionFromStack: (
      query: AssetControllerRemoveAssetVersionFromStackParams,
      params: RequestParams = {}
    ) =>
      this.request<
        RemoveAssetVersionFromStackSuccessDto,
        | NotCurrentAssetVersionErrorDto
        | (OrganizationLockedErrorDto | SubscriptionFeatureNotAllowedErrorDto)
      >({
        path: `/asset/version/remove-from-stack`,
        method: 'DELETE',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset
     * @name AssetControllerAddAssetToStack
     * @request PUT:/asset/add-to-stack
     * @secure
     */
    assetControllerAddAssetToStack: (
      query: AssetControllerAddAssetToStackParams,
      params: RequestParams = {}
    ) =>
      this.request<
        AddAssetToStackSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureNotAllowedErrorDto
      >({
        path: `/asset/add-to-stack`,
        method: 'PUT',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset
     * @name AssetControllerCopyAssetVersion
     * @request PUT:/asset/copy-version
     * @secure
     */
    assetControllerCopyAssetVersion: (
      query: AssetControllerCopyAssetVersionParams,
      params: RequestParams = {}
    ) =>
      this.request<
        CopyAssetVersionSuccessDto,
        | (
            | AssetsSizeLimitExceededErrorDto
            | AssetsSizeLimitWillExceededErrorDto
          )[]
        | (OrganizationLockedErrorDto | SubscriptionFeatureNotAllowedErrorDto)
      >({
        path: `/asset/copy-version`,
        method: 'PUT',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset
     * @name AssetInternalCommentsControllerCreateAssetComment
     * @request POST:/asset/comment
     * @secure
     */
    assetInternalCommentsControllerCreateAssetComment: (
      data: CreateAssetVersionCommentDto,
      params: RequestParams = {}
    ) =>
      this.request<
        CreateAssetVersionCommentSuccessDto,
        OrganizationLockedErrorDto
      >({
        path: `/asset/comment`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset
     * @name AssetInternalCommentsControllerUpdateAssetComment
     * @request PUT:/asset/comment
     * @secure
     */
    assetInternalCommentsControllerUpdateAssetComment: (
      data: UpdateAssetVersionCommentDto,
      params: RequestParams = {}
    ) =>
      this.request<
        UpdateAssetVersionCommentSuccessDto,
        OrganizationLockedErrorDto
      >({
        path: `/asset/comment`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset
     * @name AssetInternalCommentsControllerDeleteAssetComment
     * @request DELETE:/asset/comment
     * @secure
     */
    assetInternalCommentsControllerDeleteAssetComment: (
      query: AssetInternalCommentsControllerDeleteAssetCommentParams,
      params: RequestParams = {}
    ) =>
      this.request<
        DeleteAssetVersionCommentSuccessDto,
        OrganizationLockedErrorDto
      >({
        path: `/asset/comment`,
        method: 'DELETE',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset
     * @name AssetInternalCommentsControllerGetAssetComment
     * @request GET:/asset/comment
     * @secure
     */
    assetInternalCommentsControllerGetAssetComment: (
      query: AssetInternalCommentsControllerGetAssetCommentParams,
      params: RequestParams = {}
    ) =>
      this.request<GetAssetVersionCommentSuccesDto, OrganizationLockedErrorDto>(
        {
          path: `/asset/comment`,
          method: 'GET',
          query: query,
          secure: true,
          format: 'json',
          ...params
        }
      ),

    /**
     * No description
     *
     * @tags asset
     * @name AssetInternalCommentsControllerSetAssetCommentLike
     * @request PUT:/asset/comment/set-like
     * @secure
     */
    assetInternalCommentsControllerSetAssetCommentLike: (
      data: SetAssetVersionCommentLikeDto,
      params: RequestParams = {}
    ) =>
      this.request<
        SetAssetVersionCommentLikeSuccessDto,
        OrganizationLockedErrorDto
      >({
        path: `/asset/comment/set-like`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset
     * @name AssetInternalCommentsControllerReplyAssetComment
     * @request POST:/asset/comment/reply
     * @secure
     */
    assetInternalCommentsControllerReplyAssetComment: (
      data: ReplyAssetVersionCommentDto,
      params: RequestParams = {}
    ) =>
      this.request<
        ReplyAssetVersionCommentSuccessDto,
        OrganizationLockedErrorDto
      >({
        path: `/asset/comment/reply`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset
     * @name AssetInternalCommentsControllerGetAssetComments
     * @request GET:/asset/comment/list
     * @secure
     */
    assetInternalCommentsControllerGetAssetComments: (
      query: AssetInternalCommentsControllerGetAssetCommentsParams,
      params: RequestParams = {}
    ) =>
      this.request<
        GetAssetVersionCommentsSuccesDto,
        OrganizationLockedErrorDto
      >({
        path: `/asset/comment/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset
     * @name AssetInternalCommentsControllerListCommentPotentialMentions
     * @request GET:/asset/comment/potential-mentions
     * @secure
     */
    assetInternalCommentsControllerListCommentPotentialMentions: (
      query: AssetInternalCommentsControllerListCommentPotentialMentionsParams,
      params: RequestParams = {}
    ) =>
      this.request<
        PageDto & {
          edges: {
            node: AssetVersionCommentMentionDto;
            cursor: string;
          }[];
        },
        OrganizationLockedErrorDto
      >({
        path: `/asset/comment/potential-mentions`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset
     * @name AssetInternalCommentsControllerListCommentPotentialGroupMentions
     * @request GET:/asset/comment/potential-group-mentions
     * @secure
     */
    assetInternalCommentsControllerListCommentPotentialGroupMentions: (
      query: AssetInternalCommentsControllerListCommentPotentialGroupMentionsParams,
      params: RequestParams = {}
    ) =>
      this.request<
        PageDto & {
          edges: {
            node: AssetVersionCommentMentionDto;
            cursor: string;
          }[];
        },
        OrganizationLockedErrorDto
      >({
        path: `/asset/comment/potential-group-mentions`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      })
  };
  multipartUpload = {
    /**
     * No description
     *
     * @tags multipart-upload
     * @name MultipartUploadControllerCompleteUpload
     * @request POST:/multipart-upload/complete
     * @secure
     */
    multipartUploadControllerCompleteUpload: (
      data: CompleteMultipartUploadDto,
      params: RequestParams = {}
    ) =>
      this.request<CompleteMultipartUploadSuccessDto, any>({
        path: `/multipart-upload/complete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags multipart-upload
     * @name MultipartUploadControllerAbortUpload
     * @request DELETE:/multipart-upload/abort
     * @secure
     */
    multipartUploadControllerAbortUpload: (
      query: MultipartUploadControllerAbortUploadParams,
      params: RequestParams = {}
    ) =>
      this.request<AbortMultipartUploadSuccessDto, any>({
        path: `/multipart-upload/abort`,
        method: 'DELETE',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags multipart-upload
     * @name MultipartUploadControllerListParts
     * @request GET:/multipart-upload/parts
     * @secure
     */
    multipartUploadControllerListParts: (
      query: MultipartUploadControllerListPartsParams,
      params: RequestParams = {}
    ) =>
      this.request<ListMultipartUploadPartsSuccessDto, any>({
        path: `/multipart-upload/parts`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags multipart-upload
     * @name MultipartUploadControllerGetPartsUploadPArams
     * @request GET:/multipart-upload/parts/upload-params
     * @secure
     */
    multipartUploadControllerGetPartsUploadPArams: (
      query: MultipartUploadControllerGetPartsUploadPAramsParams,
      params: RequestParams = {}
    ) =>
      this.request<GetMultipartUploadPartsUploadParamsSuccessDto, any>({
        path: `/multipart-upload/parts/upload-params`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      })
  };
  taskBoard = {
    /**
     * No description
     *
     * @tags task-board
     * @name TaskBoardControllerCreateTaskBoard
     * @request POST:/task-board/create
     * @secure
     */
    taskBoardControllerCreateTaskBoard: (
      data: CreateTaskBoardDto,
      params: RequestParams = {}
    ) =>
      this.request<
        CreateTaskBoardSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/task-board/create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags task-board
     * @name TaskBoardControllerRenameTaskBoard
     * @request PUT:/task-board/rename
     * @secure
     */
    taskBoardControllerRenameTaskBoard: (
      data: RenameTaskBoardDto,
      params: RequestParams = {}
    ) =>
      this.request<
        RenameTaskBoardSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/task-board/rename`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags task-board
     * @name TaskBoardControllerMoveTaskBoard
     * @request PUT:/task-board/move
     * @secure
     */
    taskBoardControllerMoveTaskBoard: (
      data: MoveTaskBoardDto,
      params: RequestParams = {}
    ) =>
      this.request<
        MoveTaskBoardSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/task-board/move`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags task-board
     * @name TaskBoardControllerDeleteTaskBoard
     * @request DELETE:/task-board/delete
     * @secure
     */
    taskBoardControllerDeleteTaskBoard: (
      query: TaskBoardControllerDeleteTaskBoardParams,
      params: RequestParams = {}
    ) =>
      this.request<
        DeleteTaskBoardSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/task-board/delete`,
        method: 'DELETE',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags task-board
     * @name TaskBoardControllerGetTaskBoard
     * @request GET:/task-board
     * @secure
     */
    taskBoardControllerGetTaskBoard: (
      query: TaskBoardControllerGetTaskBoardParams,
      params: RequestParams = {}
    ) =>
      this.request<
        GetTaskBoardSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/task-board`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags task-board
     * @name TaskBoardControllerListTaskBoards
     * @request GET:/task-board/list
     * @secure
     */
    taskBoardControllerListTaskBoards: (
      query: TaskBoardControllerListTaskBoardsParams,
      params: RequestParams = {}
    ) =>
      this.request<
        ListTaskBoardsSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/task-board/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      })
  };
  task = {
    /**
     * No description
     *
     * @tags task
     * @name TaskControllerCreateTask
     * @request POST:/task/create
     * @secure
     */
    taskControllerCreateTask: (
      data: CreateTaskDto,
      params: RequestParams = {}
    ) =>
      this.request<
        CreateTaskSuccessDto,
        | TaskFromCommentAlreadyExistsErrorDto
        | (
            | OrganizationLockedErrorDto
            | SubscriptionFeatureLimitExceededErrorDto
          )
      >({
        path: `/task/create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskControllerUpdateTask
     * @request PUT:/task/update
     * @secure
     */
    taskControllerUpdateTask: (
      data: UpdateTaskDto,
      params: RequestParams = {}
    ) =>
      this.request<
        UpdateTaskSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/task/update`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskControllerDeleteTask
     * @request DELETE:/task/delete
     * @secure
     */
    taskControllerDeleteTask: (
      query: TaskControllerDeleteTaskParams,
      params: RequestParams = {}
    ) =>
      this.request<
        DeleteTaskSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/task/delete`,
        method: 'DELETE',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskControllerRestoreDeletedTask
     * @request PUT:/task/deleted/restore
     * @secure
     */
    taskControllerRestoreDeletedTask: (
      data: RestoreTaskDto,
      params: RequestParams = {}
    ) =>
      this.request<
        RestoreTaskSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/task/deleted/restore`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskControllerGetTask
     * @request GET:/task
     * @secure
     */
    taskControllerGetTask: (
      query: TaskControllerGetTaskParams,
      params: RequestParams = {}
    ) =>
      this.request<
        GetTaskSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/task`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskControllerCountTasks
     * @request GET:/task/count
     * @secure
     */
    taskControllerCountTasks: (
      query: TaskControllerCountTasksParams,
      params: RequestParams = {}
    ) =>
      this.request<
        CountTasksSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/task/count`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskControllerListTasks
     * @request GET:/task/list
     * @secure
     */
    taskControllerListTasks: (
      query: TaskControllerListTasksParams,
      params: RequestParams = {}
    ) =>
      this.request<
        PageDto & {
          edges: {
            node: TaskItemDto;
            cursor: string;
          }[];
        },
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/task/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskControllerCreateTaskComment
     * @request POST:/task/comment/create
     * @secure
     */
    taskControllerCreateTaskComment: (
      data: CreateTaskCommentDto,
      params: RequestParams = {}
    ) =>
      this.request<
        CreateTaskCommentSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/task/comment/create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskControllerUpdateTaskComment
     * @request PUT:/task/comment/update
     * @secure
     */
    taskControllerUpdateTaskComment: (
      data: UpdateTaskCommentDto,
      params: RequestParams = {}
    ) =>
      this.request<
        UpdateTaskCommentSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/task/comment/update`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskControllerDeleteTaskComment
     * @request DELETE:/task/comment/delete
     * @secure
     */
    taskControllerDeleteTaskComment: (
      query: TaskControllerDeleteTaskCommentParams,
      params: RequestParams = {}
    ) =>
      this.request<
        DeleteTaskCommentSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/task/comment/delete`,
        method: 'DELETE',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskControllerGetTaskComment
     * @request GET:/task/comment
     * @secure
     */
    taskControllerGetTaskComment: (
      query: TaskControllerGetTaskCommentParams,
      params: RequestParams = {}
    ) =>
      this.request<
        GetTaskCommentSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/task/comment`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskControllerGetTaskEvent
     * @request GET:/task/activity
     * @secure
     */
    taskControllerGetTaskEvent: (
      query: TaskControllerGetTaskEventParams,
      params: RequestParams = {}
    ) =>
      this.request<
        GetTaskEventSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/task/activity`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskControllerGetTaskEvents
     * @request GET:/task/activity/list
     * @secure
     */
    taskControllerGetTaskEvents: (
      query: TaskControllerGetTaskEventsParams,
      params: RequestParams = {}
    ) =>
      this.request<
        PageDto & {
          edges: {
            node: TaskEventDto;
            cursor: string;
          }[];
        },
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/task/activity/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskControllerListPotentialAssignees
     * @request GET:/task/potential-assignees
     * @secure
     */
    taskControllerListPotentialAssignees: (
      query: TaskControllerListPotentialAssigneesParams,
      params: RequestParams = {}
    ) =>
      this.request<
        PageDto & {
          edges: {
            node: MemberDto;
            cursor: string;
          }[];
        },
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/task/potential-assignees`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskControllerListTaskBoardGroups
     * @request GET:/task/task-board-groups
     * @secure
     */
    taskControllerListTaskBoardGroups: (
      query: TaskControllerListTaskBoardGroupsParams,
      params: RequestParams = {}
    ) =>
      this.request<
        ListTaskBoardGroupsSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/task/task-board-groups`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags task
     * @name TaskControllerListCampaignGroups
     * @request GET:/task/campaign-groups
     * @secure
     */
    taskControllerListCampaignGroups: (
      query: TaskControllerListCampaignGroupsParams,
      params: RequestParams = {}
    ) =>
      this.request<
        ListCampaignGroupsSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/task/campaign-groups`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      })
  };
  assetReview = {
    /**
     * No description
     *
     * @tags asset-review
     * @name AssetReviewControllerCreateAssetReviewOld
     * @request POST:/asset-review/create
     * @deprecated
     * @secure
     */
    assetReviewControllerCreateAssetReviewOld: (
      data: CreateAssetReviewOldDto,
      params: RequestParams = {}
    ) =>
      this.request<
        CreateAssetReviewSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureNotAllowedErrorDto
      >({
        path: `/asset-review/create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset-review
     * @name AssetReviewControllerCreateAssetReview
     * @request POST:/asset-review
     * @secure
     */
    assetReviewControllerCreateAssetReview: (
      data: CreateAssetReviewDto,
      params: RequestParams = {}
    ) =>
      this.request<
        CreateAssetReviewSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureNotAllowedErrorDto
      >({
        path: `/asset-review`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset-review
     * @name AssetReviewControllerGetAssetReview
     * @request GET:/asset-review
     * @secure
     */
    assetReviewControllerGetAssetReview: (
      query: AssetReviewControllerGetAssetReviewParams,
      params: RequestParams = {}
    ) =>
      this.request<
        GetAssetReviewSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureNotAllowedErrorDto
      >({
        path: `/asset-review`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset-review
     * @name AssetReviewControllerCountAssetReviews
     * @request GET:/asset-review/count
     * @secure
     */
    assetReviewControllerCountAssetReviews: (
      query: AssetReviewControllerCountAssetReviewsParams,
      params: RequestParams = {}
    ) =>
      this.request<
        CountAssetReviewsSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureNotAllowedErrorDto
      >({
        path: `/asset-review/count`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset-review
     * @name AssetReviewControllerListAssetReviews
     * @request GET:/asset-review/list
     * @secure
     */
    assetReviewControllerListAssetReviews: (
      query: AssetReviewControllerListAssetReviewsParams,
      params: RequestParams = {}
    ) =>
      this.request<
        PageDto & {
          edges: {
            node: AssetReviewItemDto;
            cursor: string;
          }[];
        },
        OrganizationLockedErrorDto | SubscriptionFeatureNotAllowedErrorDto
      >({
        path: `/asset-review/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset-review
     * @name AssetReviewControllerAddAssetReviewVisit
     * @request POST:/asset-review/visit
     */
    assetReviewControllerAddAssetReviewVisit: (
      data: AddAssetReviewVisitDto,
      params: RequestParams = {}
    ) =>
      this.request<
        AddAssetReviewVisitSuccessDto,
        | (ErrorPasswordDoesNotMatchDto | AssetReviewErrorReviewDisabledDto)[]
        | (OrganizationLockedErrorDto | SubscriptionFeatureNotAllowedErrorDto)
      >({
        path: `/asset-review/visit`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset-review
     * @name AssetReviewControllerUpdateAssetReview
     * @request PUT:/asset-review/update
     * @secure
     */
    assetReviewControllerUpdateAssetReview: (
      data: UpdateAssetReviewDto,
      params: RequestParams = {}
    ) =>
      this.request<
        UpdateAssetReviewSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureNotAllowedErrorDto
      >({
        path: `/asset-review/update`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset-review
     * @name AssetReviewControllerDeleteAssetReview
     * @request DELETE:/asset-review/delete
     * @secure
     */
    assetReviewControllerDeleteAssetReview: (
      query: AssetReviewControllerDeleteAssetReviewParams,
      params: RequestParams = {}
    ) =>
      this.request<
        DeleteAssetReviewSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureNotAllowedErrorDto
      >({
        path: `/asset-review/delete`,
        method: 'DELETE',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset-review
     * @name AssetReviewControllerGetReviewContent
     * @request GET:/asset-review/content
     * @secure
     */
    assetReviewControllerGetReviewContent: (
      query: AssetReviewControllerGetReviewContentParams,
      params: RequestParams = {}
    ) =>
      this.request<
        GetAssetReviewContentSuccessDto,
        | (ErrorPasswordDoesNotMatchDto | AssetReviewErrorReviewDisabledDto)[]
        | (OrganizationLockedErrorDto | SubscriptionFeatureNotAllowedErrorDto)
      >({
        path: `/asset-review/content`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset-review
     * @name AssetReviewControllerGetAssetByReview
     * @request GET:/asset-review/asset/item
     * @secure
     */
    assetReviewControllerGetAssetByReview: (
      query: AssetReviewControllerGetAssetByReviewParams,
      params: RequestParams = {}
    ) =>
      this.request<
        GetAssetByReviewSuccessDto,
        | (ErrorPasswordDoesNotMatchDto | AssetReviewErrorReviewDisabledDto)[]
        | (OrganizationLockedErrorDto | SubscriptionFeatureNotAllowedErrorDto)
      >({
        path: `/asset-review/asset/item`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset-review
     * @name AssetReviewControllerShareAssetReviewLink
     * @request POST:/asset-review/share-link
     * @secure
     */
    assetReviewControllerShareAssetReviewLink: (
      data: ShareAssetReviewLinkDTO,
      params: RequestParams = {}
    ) =>
      this.request<
        ShareAssetReviewSuccessDTO,
        OrganizationLockedErrorDto | SubscriptionFeatureNotAllowedErrorDto
      >({
        path: `/asset-review/share-link`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset-review
     * @name AssetReviewControllerAddNotificationSubscription
     * @request PUT:/asset-review/notification/subscription
     * @secure
     */
    assetReviewControllerAddNotificationSubscription: (
      data: AddAssetReviewNotificationSubscriptionDto,
      params: RequestParams = {}
    ) =>
      this.request<
        AddAssetReviewNotificationSubscriptionSuccessDto,
        | (ErrorPasswordDoesNotMatchDto | AssetReviewErrorReviewDisabledDto)[]
        | (OrganizationLockedErrorDto | SubscriptionFeatureNotAllowedErrorDto)
      >({
        path: `/asset-review/notification/subscription`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset-review
     * @name AssetReviewCommentsControllerCreateAssetComment
     * @request POST:/asset-review/comment
     * @secure
     */
    assetReviewCommentsControllerCreateAssetComment: (
      data: CreateAssetReviewCommentDto,
      params: RequestParams = {}
    ) =>
      this.request<
        CreateAssetVersionCommentSuccessDto,
        | (ErrorPasswordDoesNotMatchDto | AssetReviewErrorReviewDisabledDto)[]
        | OrganizationLockedErrorDto
      >({
        path: `/asset-review/comment`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset-review
     * @name AssetReviewCommentsControllerUpdateAssetComment
     * @request PUT:/asset-review/comment
     * @secure
     */
    assetReviewCommentsControllerUpdateAssetComment: (
      data: UpdateAssetReviewCommentDto,
      params: RequestParams = {}
    ) =>
      this.request<
        UpdateAssetVersionCommentSuccessDto,
        | (ErrorPasswordDoesNotMatchDto | AssetReviewErrorReviewDisabledDto)[]
        | OrganizationLockedErrorDto
      >({
        path: `/asset-review/comment`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset-review
     * @name AssetReviewCommentsControllerDeleteAssetComment
     * @request DELETE:/asset-review/comment
     * @secure
     */
    assetReviewCommentsControllerDeleteAssetComment: (
      query: AssetReviewCommentsControllerDeleteAssetCommentParams,
      params: RequestParams = {}
    ) =>
      this.request<
        DeleteAssetVersionCommentSuccessDto,
        | (ErrorPasswordDoesNotMatchDto | AssetReviewErrorReviewDisabledDto)[]
        | OrganizationLockedErrorDto
      >({
        path: `/asset-review/comment`,
        method: 'DELETE',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset-review
     * @name AssetReviewCommentsControllerGetAssetComment
     * @request GET:/asset-review/comment
     * @secure
     */
    assetReviewCommentsControllerGetAssetComment: (
      query: AssetReviewCommentsControllerGetAssetCommentParams,
      params: RequestParams = {}
    ) =>
      this.request<
        GetAssetVersionCommentSuccesDto,
        | (ErrorPasswordDoesNotMatchDto | AssetReviewErrorReviewDisabledDto)[]
        | OrganizationLockedErrorDto
      >({
        path: `/asset-review/comment`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset-review
     * @name AssetReviewCommentsControllerSetAssetCommentLike
     * @request PUT:/asset-review/comment/set-like
     * @secure
     */
    assetReviewCommentsControllerSetAssetCommentLike: (
      data: SetAssetReviewCommentLikeDto,
      params: RequestParams = {}
    ) =>
      this.request<
        SetAssetVersionCommentLikeSuccessDto,
        | (ErrorPasswordDoesNotMatchDto | AssetReviewErrorReviewDisabledDto)[]
        | OrganizationLockedErrorDto
      >({
        path: `/asset-review/comment/set-like`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset-review
     * @name AssetReviewCommentsControllerReplyAssetComment
     * @request POST:/asset-review/comment/reply
     * @secure
     */
    assetReviewCommentsControllerReplyAssetComment: (
      data: ReplyAssetReviewCommentDto,
      params: RequestParams = {}
    ) =>
      this.request<
        ReplyAssetVersionCommentSuccessDto,
        | (ErrorPasswordDoesNotMatchDto | AssetReviewErrorReviewDisabledDto)[]
        | OrganizationLockedErrorDto
      >({
        path: `/asset-review/comment/reply`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset-review
     * @name AssetReviewCommentsControllerGetAssetComments
     * @request GET:/asset-review/comment/list
     * @secure
     */
    assetReviewCommentsControllerGetAssetComments: (
      query: AssetReviewCommentsControllerGetAssetCommentsParams,
      params: RequestParams = {}
    ) =>
      this.request<
        GetAssetVersionCommentsSuccesDto,
        | (ErrorPasswordDoesNotMatchDto | AssetReviewErrorReviewDisabledDto)[]
        | OrganizationLockedErrorDto
      >({
        path: `/asset-review/comment/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset-review
     * @name AssetReviewCommentsControllerListCommentPotentialMentions
     * @request GET:/asset-review/comment/potential-mentions
     * @secure
     */
    assetReviewCommentsControllerListCommentPotentialMentions: (
      query: AssetReviewCommentsControllerListCommentPotentialMentionsParams,
      params: RequestParams = {}
    ) =>
      this.request<
        PageDto & {
          edges: {
            node: AssetVersionCommentMentionDto;
            cursor: string;
          }[];
        },
        | (ErrorPasswordDoesNotMatchDto | AssetReviewErrorReviewDisabledDto)[]
        | OrganizationLockedErrorDto
      >({
        path: `/asset-review/comment/potential-mentions`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asset-review
     * @name AssetReviewCommentsControllerListCommentPotentialGroupMentions
     * @request GET:/asset-review/comment/potential-group-mentions
     * @secure
     */
    assetReviewCommentsControllerListCommentPotentialGroupMentions: (
      query: AssetReviewCommentsControllerListCommentPotentialGroupMentionsParams,
      params: RequestParams = {}
    ) =>
      this.request<
        PageDto & {
          edges: {
            node: AssetVersionCommentMentionDto;
            cursor: string;
          }[];
        },
        | (ErrorPasswordDoesNotMatchDto | AssetReviewErrorReviewDisabledDto)[]
        | OrganizationLockedErrorDto
      >({
        path: `/asset-review/comment/potential-group-mentions`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      })
  };
  externalReviewer = {
    /**
     * No description
     *
     * @tags external-reviewer
     * @name ExternalReviewerControllerSignInAsExternalReviewer
     * @request POST:/external-reviewer/sign-in
     */
    externalReviewerControllerSignInAsExternalReviewer: (
      data: SignInAsExternalReviewerDto,
      params: RequestParams = {}
    ) =>
      this.request<
        SignInAsExternalReviewerSuccessDto,
        | (
            | AssetReviewErrorReviewDisabledDto
            | SignInAsExternalReviewerErrorSignUpRequiredDto
          )[]
        | OrganizationLockedErrorDto
      >({
        path: `/external-reviewer/sign-in`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags external-reviewer
     * @name ExternalReviewerControllerSignUpAsExternalReviewer
     * @request POST:/external-reviewer/sign-up
     */
    externalReviewerControllerSignUpAsExternalReviewer: (
      data: SignUpAsExternalReviewerDto,
      params: RequestParams = {}
    ) =>
      this.request<
        SignUpAsExternalReviewerSuccessDto,
        AssetReviewErrorReviewDisabledDto | OrganizationLockedErrorDto
      >({
        path: `/external-reviewer/sign-up`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags external-reviewer
     * @name ExternalReviewerControllerSignInAsExternalAwReviewer
     * @request POST:/external-reviewer/approwal-workflow/sign-in
     */
    externalReviewerControllerSignInAsExternalAwReviewer: (
      data: SignInAsExternalAWReviewerDto,
      params: RequestParams = {}
    ) =>
      this.request<
        SignInAsExternalReviewerSuccessDto,
        | (
            | AssetReviewErrorReviewDisabledDto
            | SignInAsExternalReviewerErrorSignUpRequiredDto
            | NotAWReviewerErrorDto
          )[]
        | OrganizationLockedErrorDto
      >({
        path: `/external-reviewer/approwal-workflow/sign-in`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags external-reviewer
     * @name ExternalReviewerControllerSignUpAsExternalAwReviewer
     * @request POST:/external-reviewer/approwal-workflow/sign-up
     */
    externalReviewerControllerSignUpAsExternalAwReviewer: (
      data: SignUpAsExternalAWReviewerDto,
      params: RequestParams = {}
    ) =>
      this.request<
        SignUpAsExternalReviewerSuccessDto,
        | (AssetReviewErrorReviewDisabledDto | NotAWReviewerErrorDto)[]
        | OrganizationLockedErrorDto
      >({
        path: `/external-reviewer/approwal-workflow/sign-up`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags external-reviewer
     * @name ExternalReviewerControllerGetExternalReviewer
     * @request GET:/external-reviewer/me
     * @secure
     */
    externalReviewerControllerGetExternalReviewer: (
      params: RequestParams = {}
    ) =>
      this.request<GetExternalReviewerSuccessDto, OrganizationLockedErrorDto>({
        path: `/external-reviewer/me`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags external-reviewer
     * @name ExternalReviewerControllerUpdateExternalReviewer
     * @request PUT:/external-reviewer/me
     * @secure
     */
    externalReviewerControllerUpdateExternalReviewer: (
      data: UpdateExternalReviewerDto,
      params: RequestParams = {}
    ) =>
      this.request<
        UpdateExternalReviewerSuccessDto,
        OrganizationLockedErrorDto
      >({
        path: `/external-reviewer/me`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags external-reviewer
     * @name ExternalReviewerControllerGetReviewerPictureUploadParams
     * @request GET:/external-reviewer/me/picture/upload-params
     * @secure
     */
    externalReviewerControllerGetReviewerPictureUploadParams: (
      query: ExternalReviewerControllerGetReviewerPictureUploadParamsParams,
      params: RequestParams = {}
    ) =>
      this.request<
        GetExternalReviewerPictureUploadParamsSuccessDto,
        OrganizationLockedErrorDto
      >({
        path: `/external-reviewer/me/picture/upload-params`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags external-reviewer
     * @name ExternalReviewerControllerDeleteExternalReviewerPicture
     * @request DELETE:/external-reviewer/me/picture
     * @secure
     */
    externalReviewerControllerDeleteExternalReviewerPicture: (
      params: RequestParams = {}
    ) =>
      this.request<
        DeleteExternalReviewerPictureSuccessDto,
        OrganizationLockedErrorDto
      >({
        path: `/external-reviewer/me/picture`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      })
  };
  approvalWorkflow = {
    /**
     * No description
     *
     * @tags approval-workflow
     * @name ApprovalWorkflowControllerListPotentialReviewers
     * @request GET:/approval-workflow/potential-reviewers
     * @secure
     */
    approvalWorkflowControllerListPotentialReviewers: (
      query: ApprovalWorkflowControllerListPotentialReviewersParams,
      params: RequestParams = {}
    ) =>
      this.request<
        PageDto & {
          edges: {
            node: AWPotentialReviewerDto;
            cursor: string;
          }[];
        },
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/approval-workflow/potential-reviewers`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags approval-workflow
     * @name ApprovalWorkflowControllerCreate
     * @request POST:/approval-workflow/create
     * @secure
     */
    approvalWorkflowControllerCreate: (
      data: CreateAWDto,
      params: RequestParams = {}
    ) =>
      this.request<
        CreateAWSuccessDto,
        | (CreateAWAlreadyExistsErrorDto | NotCurrentAssetVersionErrorDto)[]
        | (
            | OrganizationLockedErrorDto
            | SubscriptionFeatureLimitExceededErrorDto
          )
      >({
        path: `/approval-workflow/create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags approval-workflow
     * @name ApprovalWorkflowControllerUpdate
     * @request PUT:/approval-workflow/update
     * @secure
     */
    approvalWorkflowControllerUpdate: (
      data: UpdateAWDto,
      params: RequestParams = {}
    ) =>
      this.request<
        UpdateAWSuccessDto,
        | (AWNotExistsErrorDto | NotCurrentAssetVersionErrorDto)[]
        | (
            | OrganizationLockedErrorDto
            | SubscriptionFeatureLimitExceededErrorDto
          )
      >({
        path: `/approval-workflow/update`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags approval-workflow
     * @name ApprovalWorkflowControllerDelete
     * @request DELETE:/approval-workflow/delete
     * @secure
     */
    approvalWorkflowControllerDelete: (
      query: ApprovalWorkflowControllerDeleteParams,
      params: RequestParams = {}
    ) =>
      this.request<
        DeleteAWSuccessDto,
        | NotCurrentAssetVersionErrorDto
        | (
            | OrganizationLockedErrorDto
            | SubscriptionFeatureLimitExceededErrorDto
          )
      >({
        path: `/approval-workflow/delete`,
        method: 'DELETE',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags approval-workflow
     * @name ApprovalWorkflowControllerRestart
     * @request PUT:/approval-workflow/restart
     * @secure
     */
    approvalWorkflowControllerRestart: (
      data: RestartAWDto,
      params: RequestParams = {}
    ) =>
      this.request<
        RestartAWSuccessDto,
        | (AWNotExistsErrorDto | NotCurrentAssetVersionErrorDto)[]
        | (
            | OrganizationLockedErrorDto
            | SubscriptionFeatureLimitExceededErrorDto
          )
      >({
        path: `/approval-workflow/restart`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags approval-workflow
     * @name ApprovalWorkflowControllerSendInvitations
     * @request POST:/approval-workflow/send-invitations
     * @secure
     */
    approvalWorkflowControllerSendInvitations: (
      data: SendAWInvitationsDto,
      params: RequestParams = {}
    ) =>
      this.request<
        SendAWInvitationsSuccessDto,
        | (
            | AWNotExistsErrorDto
            | ApprovalWorkflowPausedErrorDto
            | ApprovalWorkflowFinishedErrorDto
            | NotCurrentAssetVersionErrorDto
            | NotCurrentAWStageErrorDto
          )[]
        | (
            | OrganizationLockedErrorDto
            | SubscriptionFeatureLimitExceededErrorDto
          )
      >({
        path: `/approval-workflow/send-invitations`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags approval-workflow
     * @name ApprovalWorkflowControllerGetAssetByAw
     * @request GET:/approval-workflow/asset
     * @secure
     */
    approvalWorkflowControllerGetAssetByAw: (
      query: ApprovalWorkflowControllerGetAssetByAwParams,
      params: RequestParams = {}
    ) =>
      this.request<
        GetAssetByAWSuccessDto,
        | ErrorPasswordDoesNotMatchDto
        | (
            | OrganizationLockedErrorDto
            | SubscriptionFeatureLimitExceededErrorDto
          )
      >({
        path: `/approval-workflow/asset`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags approval-workflow
     * @name ApprovalWorkflowControllerCountAssetsWithApproval
     * @request GET:/approval-workflow/asset/count
     * @secure
     */
    approvalWorkflowControllerCountAssetsWithApproval: (
      query: ApprovalWorkflowControllerCountAssetsWithApprovalParams,
      params: RequestParams = {}
    ) =>
      this.request<
        CountAssetsWithApprovalSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/approval-workflow/asset/count`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags approval-workflow
     * @name ApprovalWorkflowControllerListAssetsWithApproval
     * @request GET:/approval-workflow/asset/list
     * @secure
     */
    approvalWorkflowControllerListAssetsWithApproval: (
      query: ApprovalWorkflowControllerListAssetsWithApprovalParams,
      params: RequestParams = {}
    ) =>
      this.request<
        PageDto & {
          edges: {
            node: AssetForApprovalDto;
            cursor: string;
          }[];
        },
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/approval-workflow/asset/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags approval-workflow
     * @name ApprovalWorkflowControllerSubmitDecision
     * @request PUT:/approval-workflow/decision
     * @secure
     */
    approvalWorkflowControllerSubmitDecision: (
      data: SubmitDecisionDto,
      params: RequestParams = {}
    ) =>
      this.request<
        SubmitDecisionSuccessDto,
        | (
            | AWNotExistsErrorDto
            | NotCurrentAssetVersionErrorDto
            | NotCurrentAWStageErrorDto
            | ErrorPasswordDoesNotMatchDto
            | ApprovalWorkflowPausedErrorDto
          )[]
        | (
            | OrganizationLockedErrorDto
            | SubscriptionFeatureLimitExceededErrorDto
          )
      >({
        path: `/approval-workflow/decision`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags approval-workflow
     * @name ApprovalWorkflowControllerAddNotificationSubscription
     * @request PUT:/approval-workflow/notification/subscription
     * @secure
     */
    approvalWorkflowControllerAddNotificationSubscription: (
      data: AddAWNotificationSubscriptionDto,
      params: RequestParams = {}
    ) =>
      this.request<
        AddAWNotificationSubscriptionSuccessDto,
        | (AWNotExistsErrorDto | ErrorPasswordDoesNotMatchDto)[]
        | (
            | OrganizationLockedErrorDto
            | SubscriptionFeatureLimitExceededErrorDto
          )
      >({
        path: `/approval-workflow/notification/subscription`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags approval-workflow
     * @name AssetAwCommentsControllerCreateAssetComment
     * @request POST:/approval-workflow/comment
     * @secure
     */
    assetAwCommentsControllerCreateAssetComment: (
      data: CreateAWCommentDto,
      params: RequestParams = {}
    ) =>
      this.request<
        CreateAssetVersionCommentSuccessDto,
        ErrorPasswordDoesNotMatchDto[] | OrganizationLockedErrorDto
      >({
        path: `/approval-workflow/comment`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags approval-workflow
     * @name AssetAwCommentsControllerUpdateAssetComment
     * @request PUT:/approval-workflow/comment
     * @secure
     */
    assetAwCommentsControllerUpdateAssetComment: (
      data: UpdateAWCommentDto,
      params: RequestParams = {}
    ) =>
      this.request<
        UpdateAssetVersionCommentSuccessDto,
        ErrorPasswordDoesNotMatchDto[] | OrganizationLockedErrorDto
      >({
        path: `/approval-workflow/comment`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags approval-workflow
     * @name AssetAwCommentsControllerDeleteAssetComment
     * @request DELETE:/approval-workflow/comment
     * @secure
     */
    assetAwCommentsControllerDeleteAssetComment: (
      query: AssetAwCommentsControllerDeleteAssetCommentParams,
      params: RequestParams = {}
    ) =>
      this.request<
        DeleteAssetVersionCommentSuccessDto,
        ErrorPasswordDoesNotMatchDto[] | OrganizationLockedErrorDto
      >({
        path: `/approval-workflow/comment`,
        method: 'DELETE',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags approval-workflow
     * @name AssetAwCommentsControllerGetAssetComment
     * @request GET:/approval-workflow/comment
     * @secure
     */
    assetAwCommentsControllerGetAssetComment: (
      query: AssetAwCommentsControllerGetAssetCommentParams,
      params: RequestParams = {}
    ) =>
      this.request<
        GetAssetVersionCommentSuccesDto,
        ErrorPasswordDoesNotMatchDto[] | OrganizationLockedErrorDto
      >({
        path: `/approval-workflow/comment`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags approval-workflow
     * @name AssetAwCommentsControllerSetAssetCommentLike
     * @request PUT:/approval-workflow/comment/set-like
     * @secure
     */
    assetAwCommentsControllerSetAssetCommentLike: (
      data: SetAWCommentLikeDto,
      params: RequestParams = {}
    ) =>
      this.request<
        SetAssetVersionCommentLikeSuccessDto,
        ErrorPasswordDoesNotMatchDto[] | OrganizationLockedErrorDto
      >({
        path: `/approval-workflow/comment/set-like`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags approval-workflow
     * @name AssetAwCommentsControllerReplyAssetComment
     * @request POST:/approval-workflow/comment/reply
     * @secure
     */
    assetAwCommentsControllerReplyAssetComment: (
      data: ReplyAWCommentDto,
      params: RequestParams = {}
    ) =>
      this.request<
        ReplyAssetVersionCommentSuccessDto,
        ErrorPasswordDoesNotMatchDto[] | OrganizationLockedErrorDto
      >({
        path: `/approval-workflow/comment/reply`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags approval-workflow
     * @name AssetAwCommentsControllerGetAssetComments
     * @request GET:/approval-workflow/comment/list
     * @secure
     */
    assetAwCommentsControllerGetAssetComments: (
      query: AssetAwCommentsControllerGetAssetCommentsParams,
      params: RequestParams = {}
    ) =>
      this.request<
        GetAssetVersionCommentsSuccesDto,
        ErrorPasswordDoesNotMatchDto[] | OrganizationLockedErrorDto
      >({
        path: `/approval-workflow/comment/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags approval-workflow
     * @name AssetAwCommentsControllerListCommentPotentialMentions
     * @request GET:/approval-workflow/comment/potential-mentions
     * @secure
     */
    assetAwCommentsControllerListCommentPotentialMentions: (
      query: AssetAwCommentsControllerListCommentPotentialMentionsParams,
      params: RequestParams = {}
    ) =>
      this.request<
        PageDto & {
          edges: {
            node: AssetVersionCommentMentionDto;
            cursor: string;
          }[];
        },
        ErrorPasswordDoesNotMatchDto[] | OrganizationLockedErrorDto
      >({
        path: `/approval-workflow/comment/potential-mentions`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags approval-workflow
     * @name AssetAwCommentsControllerListCommentPotentialGroupMentions
     * @request GET:/approval-workflow/comment/potential-group-mentions
     * @secure
     */
    assetAwCommentsControllerListCommentPotentialGroupMentions: (
      query: AssetAwCommentsControllerListCommentPotentialGroupMentionsParams,
      params: RequestParams = {}
    ) =>
      this.request<
        PageDto & {
          edges: {
            node: AssetVersionCommentMentionDto;
            cursor: string;
          }[];
        },
        ErrorPasswordDoesNotMatchDto[] | OrganizationLockedErrorDto
      >({
        path: `/approval-workflow/comment/potential-group-mentions`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      })
  };
  subscription = {
    /**
     * No description
     *
     * @tags subscription
     * @name SubscriptionControllerUpdateSubscription
     * @request PUT:/subscription
     * @secure
     */
    subscriptionControllerUpdateSubscription: (
      data: UpdateSubscriptionDto,
      params: RequestParams = {}
    ) =>
      this.request<
        UpdateSubscriptionSuccessDTO,
        | (SubscriptionUsersLimitErrorDto | AssetsSizeLimitExceededErrorDto)[]
        | OrganizationLockedErrorDto[]
      >({
        path: `/subscription`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags subscription
     * @name SubscriptionControllerGetSubscription
     * @request GET:/subscription
     * @secure
     */
    subscriptionControllerGetSubscription: (
      query: SubscriptionControllerGetSubscriptionParams,
      params: RequestParams = {}
    ) =>
      this.request<GetSubscriptionSuccessDTO, OrganizationLockedErrorDto[]>({
        path: `/subscription`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags subscription
     * @name SubscriptionControllerCancelSubscription
     * @request DELETE:/subscription
     * @secure
     */
    subscriptionControllerCancelSubscription: (
      query: SubscriptionControllerCancelSubscriptionParams,
      params: RequestParams = {}
    ) =>
      this.request<GetSubscriptionSuccessDTO, OrganizationLockedErrorDto[]>({
        path: `/subscription`,
        method: 'DELETE',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags subscription
     * @name SubscriptionControllerReplaceCard
     * @request PUT:/subscription/card/replace
     * @secure
     */
    subscriptionControllerReplaceCard: (
      data: ReplaceCardDto,
      params: RequestParams = {}
    ) =>
      this.request<ReplaceCardSuccessDto, OrganizationLockedErrorDto[]>({
        path: `/subscription/card/replace`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags subscription
     * @name SubscriptionControllerGetCard
     * @request GET:/subscription/card
     * @secure
     */
    subscriptionControllerGetCard: (
      query: SubscriptionControllerGetCardParams,
      params: RequestParams = {}
    ) =>
      this.request<GetCardSuccessDto, OrganizationLockedErrorDto[]>({
        path: `/subscription/card`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags subscription
     * @name SubscriptionControllerDeleteCard
     * @request DELETE:/subscription/card
     * @secure
     */
    subscriptionControllerDeleteCard: (
      query: SubscriptionControllerDeleteCardParams,
      params: RequestParams = {}
    ) =>
      this.request<DeleteCardSuccessDto, OrganizationLockedErrorDto[]>({
        path: `/subscription/card`,
        method: 'DELETE',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * @description The endpoint needs to execute after card was successfully created. Need to ensure that card was added to database
     *
     * @tags subscription
     * @name SubscriptionControllerSetCardCreated
     * @request POST:/subscription/card/created
     * @secure
     */
    subscriptionControllerSetCardCreated: (
      data: CardCreatedDto,
      params: RequestParams = {}
    ) =>
      this.request<CardCreatedSuccessDto, OrganizationLockedErrorDto[]>({
        path: `/subscription/card/created`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * @description The endpoint needs to execute after the subscription was successfully created. Need to ensure that subscription was added to database
     *
     * @tags subscription
     * @name SubscriptionControllerSetSubscriptionCreated
     * @request POST:/subscription/created
     * @secure
     */
    subscriptionControllerSetSubscriptionCreated: (
      data: SubscriptionCreatedDto,
      params: RequestParams = {}
    ) =>
      this.request<SubscriptionCreatedSuccessDto, OrganizationLockedErrorDto[]>(
        {
          path: `/subscription/created`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params
        }
      ),

    /**
     * No description
     *
     * @tags subscription
     * @name SubscriptionControllerGetInvoicesList
     * @request GET:/subscription/invoice/list
     * @secure
     */
    subscriptionControllerGetInvoicesList: (
      query: SubscriptionControllerGetInvoicesListParams,
      params: RequestParams = {}
    ) =>
      this.request<
        PageDto & {
          edges: {
            node: InvoiceItemDto;
            cursor: string;
          }[];
        },
        OrganizationLockedErrorDto[]
      >({
        path: `/subscription/invoice/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags subscription
     * @name SubscriptionControllerGetInvoiceDownloadUrl
     * @request GET:/subscription/invoice/download-url
     * @secure
     */
    subscriptionControllerGetInvoiceDownloadUrl: (
      query: SubscriptionControllerGetInvoiceDownloadUrlParams,
      params: RequestParams = {}
    ) =>
      this.request<
        GetInvoiceDownloadUrlSuccessDto,
        OrganizationLockedErrorDto[]
      >({
        path: `/subscription/invoice/download-url`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags subscription
     * @name SubscriptionControllerGetSeatsUsage
     * @request GET:/subscription/usage/seats
     * @secure
     */
    subscriptionControllerGetSeatsUsage: (
      query: SubscriptionControllerGetSeatsUsageParams,
      params: RequestParams = {}
    ) =>
      this.request<GetSeatsUsageSuccessDto, OrganizationLockedErrorDto>({
        path: `/subscription/usage/seats`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags subscription
     * @name SubscriptionControllerGetAssetsUsage
     * @request GET:/subscription/usage/assets
     * @secure
     */
    subscriptionControllerGetAssetsUsage: (
      query: SubscriptionControllerGetAssetsUsageParams,
      params: RequestParams = {}
    ) =>
      this.request<GetAssetsUsageSuccessDto, OrganizationLockedErrorDto>({
        path: `/subscription/usage/assets`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      })
  };
  attachment = {
    /**
     * No description
     *
     * @tags attachment
     * @name AttachmentControllerInitAttachmentUpload
     * @request POST:/attachment/init-upload
     * @secure
     */
    attachmentControllerInitAttachmentUpload: (
      data: InitAttachmentUploadDto,
      params: RequestParams = {}
    ) =>
      this.request<
        InitAttachmentUploadSuccessDto,
        | (
            | AssetsSizeLimitExceededErrorDto
            | AssetsSizeLimitWillExceededErrorDto
          )[]
        | (
            | OrganizationLockedErrorDto
            | SubscriptionFeatureLimitExceededErrorDto
          )
      >({
        path: `/attachment/init-upload`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags attachment
     * @name AttachmentControllerGetAttachment
     * @request GET:/attachment
     * @secure
     */
    attachmentControllerGetAttachment: (
      query: AttachmentControllerGetAttachmentParams,
      params: RequestParams = {}
    ) =>
      this.request<
        GetAttachmentSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/attachment`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags attachment
     * @name AttachmentControllerDeleteAttachment
     * @request DELETE:/attachment
     * @secure
     */
    attachmentControllerDeleteAttachment: (
      query: AttachmentControllerDeleteAttachmentParams,
      params: RequestParams = {}
    ) =>
      this.request<
        DeleteAttachmentSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/attachment`,
        method: 'DELETE',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags attachment
     * @name AttachmentControllerGetAttachments
     * @request GET:/attachment/list
     * @secure
     */
    attachmentControllerGetAttachments: (
      query: AttachmentControllerGetAttachmentsParams,
      params: RequestParams = {}
    ) =>
      this.request<
        ListAttachmentsSuccessDto,
        OrganizationLockedErrorDto | SubscriptionFeatureLimitExceededErrorDto
      >({
        path: `/attachment/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      })
  };
  slack = {
    /**
     * No description
     *
     * @tags slack
     * @name SlackControllerInstallSlack
     * @request GET:/slack/install
     * @secure
     */
    slackControllerInstallSlack: (
      query: SlackControllerInstallSlackParams,
      params: RequestParams = {}
    ) =>
      this.request<GetSlackConnectUrlSuccessDto, any>({
        path: `/slack/install`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags slack
     * @name SlackControllerUninstallSlack
     * @request DELETE:/slack/uninstall
     * @secure
     */
    slackControllerUninstallSlack: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/slack/uninstall`,
        method: 'DELETE',
        secure: true,
        ...params
      })
  };
  asana = {
    /**
     * No description
     *
     * @tags asana
     * @name AsanaControllerInstall
     * @request GET:/asana/install
     * @secure
     */
    asanaControllerInstall: (
      query: AsanaControllerInstallParams,
      params: RequestParams = {}
    ) =>
      this.request<GetAsanaConnectUrlSuccessDto, any>({
        path: `/asana/install`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asana
     * @name AsanaControllerConnect
     * @request POST:/asana/connect
     * @secure
     */
    asanaControllerConnect: (
      data: AsanaOauthVerifyDto,
      params: RequestParams = {}
    ) =>
      this.request<AsanaOauthVerifySuccessDto, void>({
        path: `/asana/connect`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags asana
     * @name AsanaControllerWidget
     * @request GET:/asana/widget
     */
    asanaControllerWidget: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/asana/widget`,
        method: 'GET',
        ...params
      })
  };
  monday = {
    /**
     * No description
     *
     * @tags monday
     * @name MondayControllerInstall
     * @request GET:/monday/install
     * @secure
     */
    mondayControllerInstall: (
      query: MondayControllerInstallParams,
      params: RequestParams = {}
    ) =>
      this.request<GetMondayConnectUrlSuccessDto, any>({
        path: `/monday/install`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags monday
     * @name MondayControllerConnect
     * @request POST:/monday/connect
     * @secure
     */
    mondayControllerConnect: (
      data: MondayOauthVerifyDto,
      params: RequestParams = {}
    ) =>
      this.request<MondayOauthVerifySuccessDto, void>({
        path: `/monday/connect`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  };
}
